import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import './agency-users.component.scss';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import User from 'model/user';
import {UserTypeEnum} from 'model/user-type.enum';
// import icons from 'sprite/sprite.svg';
// import {HIDE_MODAL_ACTION} from 'store/modal/actions';
// import EditUserModalComponent from 'components/modal/edit-user/edit-user-modal.component';
// import EditPasswordModalComponent from 'components/modal/edit-password/edit-password-modal.component';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getAgencyUsers} from './services/agency-users.service';
import {SET_CURRENT_AGENCY_USERS_ACTION} from 'store/agency/actions';
// import DeleteModalComponent from 'components/modal/delete/delete-modal.component';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
// import AddUserModalComponent from 'components/modal/add-user/add-user-modal.component';
// import {openModal} from 'services/utils.service';

const AgencyUsersComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	// const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentAgency } = useSelector((state: MainState) => state.agency);
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentAgency.id) {
			dispatch(SHOW_LOADING_ACTION);

			getAgencyUsers(currentAgency.id)
				.then((users: User[]) => {
					const setCurrentAgencyUsersAction = {...SET_CURRENT_AGENCY_USERS_ACTION};
					setCurrentAgencyUsersAction.users = users;
					dispatch(setCurrentAgencyUsersAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('agency_users_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	}, [currentAgency.id, dispatch]);

	/*
	const getDeleteModal = (index: number): ReactElement => {
		return <DeleteModalComponent
			title="delete_user"
			fieldValue={currentAgency.users[index].email}
			label="email"
			repeatLabel="repeat_email"
			onDelete={() => {
				dispatch(SHOW_LOADING_ACTION);

				deleteUser(currentAgency.users[index], currentAgency.id)
					.then(() => {
						currentAgency.users.splice(index, 1);
						const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
						setCurrentAgencyAction.agency = currentAgency;
						dispatch(setCurrentAgencyAction);
						dispatch(HIDE_MODAL_ACTION);
						showGrowlMessage(new GrowlMessageImp('user_deleted', true), dispatch);
					})
					.catch(() => showGrowlMessage(new GrowlMessageImp('user_delete_error'), dispatch))
					.finally(() => dispatch(HIDE_LOADING_ACTION));
			}}
		/>;
	};
	*/

	return <div className="agency-users-component">
		<div className="users-title">
			<h4><FormattedMessage id="users"/></h4>
			{/*
			{ !user.permissions.includes(UserTypeEnum.AGENCY) && <button className="btn btn--sm btn--secondary btn--outline" onClick={() => openModal(<AddUserModalComponent/>, dispatch)} data-testid="add-user">
				<FormattedMessage id="add"/>
			</button> }
			*/}
		</div>
		<table>
			<thead>
				<tr>
					<th><FormattedMessage id="name"/></th>
					<th><FormattedMessage id="email"/></th>
					<th><FormattedMessage id="role"/></th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{ currentAgency.users.map((agencyUser: User, index: number) => {
					return <tr key={index}>
						<td>{ agencyUser.name }</td>
						<td className="email">{ agencyUser.email }</td>
						<td className="role-icons">
							<span className={`role ${agencyUser.permissions.includes(UserTypeEnum.AGENCY) ? 'user' : 'admin'}`}>
								<FormattedMessage id={agencyUser.permissions.find(p => p.includes('borgan')) || 'not_set_role'}/>
							</span>
						</td>
						{/*
						<td className="icons">
						{ !user.permissions.includes(UserTypeEnum.AGENCY) &&
							<>
								<svg className="icon icon--24 clickable" onClick={() => openModal(<EditUserModalComponent userToEdit={agencyUser}/>, dispatch)} data-testid="edit-user">
									<use xlinkHref={`${icons}#icon-pencil`}/>
								</svg>
								<svg className="icon icon--24 clickable" onClick={() => openModal(<EditPasswordModalComponent user={agencyUser}/>, dispatch)} data-testid="change-password">
									<use xlinkHref={`${icons}#icon-lock-closed`}/>
								</svg>
								<svg className="icon icon--24 clickable" onClick={() => openModal(getDeleteModal(index), dispatch)} data-testid="delete-user">
									<use xlinkHref={`${icons}#icon-trash`}/>
								</svg>
							</>
						}
						</td>
						*/}
					</tr>;
				}) }
			</tbody>
		</table>
		{ !currentAgency.users.length && <p><FormattedMessage id="no_users"/></p> }
	</div>;
};

export default AgencyUsersComponent;
