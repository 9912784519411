import React, {FunctionComponent, useState, ChangeEvent} from 'react';
import {FormattedMessage} from 'react-intl';
import {Controller, FieldError, Control} from 'react-hook-form';
import icons from 'sprite/sprite.svg';
import './drag-drop.component.scss';
import {DeepMap} from 'react-hook-form/dist/types/utils';
import {parseFileNameFromUrl} from 'services/utils.service';

export interface DragAndDropFormData {
	file: any;
}

interface DragAndDropComponentProps {
	fileType: string;
	label: string;
	errors: DeepMap<DragAndDropFormData, FieldError>;
	control: Control<DragAndDropFormData>;
	defaultFile?: string;
}

const DragAndDropComponent: FunctionComponent<DragAndDropComponentProps> = ({fileType, label, errors, control, defaultFile}) => {
	const [valueFile, setValueFile] = useState<File | null>(defaultFile ? new File([], defaultFile) : null);

	const onChangeAttachment = (e: ChangeEvent<HTMLInputElement>, onChange: Function): void => {
		// istanbul ignore else
		if (e.target.files) {
			setValueFile(e.target.files[0]);
			onChange(e.target.files[0]);
		}
	};

	const removeFile = (): void => {
		setValueFile(null);
		control.setValue('file', undefined);
	};

	return <div className="form-group">
		<label className="form-group__label"><FormattedMessage id={label}/></label>
		<div className={`drag-drop-component ${valueFile ? 'height__addon' : ''}`}>
			<Controller
				control={control}
				name="file"
				rules={{required: 'field_required'}}
				render={({onChange}) => (
					<input
						type="file"
						className={valueFile ? 'height__0' : ''}
						accept={fileType}
						onChange={e => onChangeAttachment(e, onChange)}
						data-testid="file-input"
					/>
				)}
			/>
			<div className="drag-drop-component-content">
				{ valueFile ? <div className="drag-drop-component-content--value u-display--none">
					<div>
						<svg className="icon icon--16 icon--secondary icon--left">
							<use xlinkHref={`${icons}#icon-file-outlined`}/>
						</svg>
						<span className="attachment__file u-text--secondary">
							{parseFileNameFromUrl(valueFile?.name)}
						</span>
					</div>
					<svg className="icon icon--16 icon--danger icon--right close__btn" onClick={removeFile} data-testid="remove-file-button">
						<use xlinkHref={`${icons}#icon-cross`}/>
					</svg>
				</div> : <div className="drag-drop-component-content--wrapper">
					<svg className="icon icon--32">
						<use xlinkHref={`${icons}#icon-cloud-loading`}/>
					</svg>
					<br/>
					<span><FormattedMessage id="drag_your_document_or"/></span>
					<br/>
					<span className="u-text--secondary"><FormattedMessage id="upload_it_here"/></span>
				</div> }
			</div>
		</div>
		{ !valueFile && errors.file && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.file.message}/></small> }
	</div>;
};

export default DragAndDropComponent;
