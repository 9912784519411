import React, {CSSProperties, FunctionComponent, ReactElement, useRef} from 'react';
import './overlay.component.scss';
import {useOutsideAlerter} from 'hooks/click-outside.hook';

interface OverlayComponentProps {
	onHide: Function;
	style: CSSProperties;
}

const OverlayComponent: FunctionComponent<OverlayComponentProps> = ({onHide, children, style}): ReactElement => {
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, onHide);

	return <div ref={wrapperRef} className="overlay-component" style={style}>
		{ children }
	</div>;
};

export default OverlayComponent;
