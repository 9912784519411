import ImportLog from 'model/import-logs';
import ImportLogs from 'model/import-logs';
import ImportLogImp from 'model/import-logs.imp';
import ImportLogState from './import-log.state';

export default class ImportLogStateImp implements ImportLogState {
    importLogs: ImportLogs[];
    currentImportLog: ImportLog;

    constructor(importLogs: ImportLogs[] = [], currentImportLog: ImportLog = new ImportLogImp()) {
        this.importLogs = importLogs;
        this.currentImportLog = currentImportLog;
    }
}
