import React, {FunctionComponent, ReactElement} from 'react';
import './confirmation-modal.component.scss';
import {FormattedMessage} from 'react-intl';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import {useDispatch} from 'react-redux';

interface ModalConfirmationComponentProps {
	title: ReactElement;
	onConfirm: Function;
}

const ModalConfirmationComponent: FunctionComponent<ModalConfirmationComponentProps> = ({ title, onConfirm }): ReactElement => {
	const dispatch = useDispatch();

	const confirm = (): void => {
		onConfirm();
		dispatch(HIDE_MODAL_ACTION);
	};

	return <div className="modal-confirmation-component">
		<h5>{ title }</h5>
		<div className="buttons">
			<button className="btn btn--md btn--secondary" onClick={() => dispatch(HIDE_MODAL_ACTION)} data-testid="hide-modal">
				<FormattedMessage id="cancel" />
			</button>
			<button className="btn btn--md btn--danger" onClick={confirm} data-testid="confirm-modal">
				<FormattedMessage id="confirm" />
			</button>
		</div>
	</div>;
};

export default ModalConfirmationComponent;
