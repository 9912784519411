import React, {FunctionComponent, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {UserTypeEnum} from 'model/user-type.enum';
import AgencyDetailComponent from './components/agency-detail/agency-detail.component';
import AgenciesListComponent from './components/agencies-list/agencies-list.component';

const AgenciesComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	const showList = user.permissions.includes(UserTypeEnum.SELECTRA);

	return <div className="header-padding container">
		<div className="row">
			{ showList && <div className="col-md-3 no-padding-right scroll-list agencies">
				<AgenciesListComponent/>
			</div> }
			<div className={ showList ? 'col-md-9 no-padding-left scroll-list agencies' : 'col-md-12' }>
				<AgencyDetailComponent/>
			</div>
		</div>
	</div>;
};

export default AgenciesComponent;
