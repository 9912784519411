import axios from 'axios';
import { APP_CONSTANTS } from 'config/app.config';
import { JsonConvert } from 'json2typescript';
import ImportLog from 'model/import-logs';
import ImportLogImp from 'model/import-logs.imp';
import { SET_IMPORT_LOGS_ACTION } from 'store/import-log/actions';

const jsonConvert: JsonConvert = new JsonConvert();

export const readImportLogs = () : Promise<ImportLog[]> => {
    return axios.get(`${APP_CONSTANTS.ENDPOINTS.IMPORT_LOGS}`)
        .then(res => jsonConvert.deserializeArray(res.data.data.map((importLog: any) => ({...importLog, ...importLog.attributes})), ImportLogImp));
}

export const getImportLog = (importLogId: string) : Promise<ImportLog> => {
    return axios.get(`${APP_CONSTANTS.ENDPOINTS.IMPORT_LOGS}/${importLogId}`)
    .then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, ImportLogImp))
}

export const setImportLogs = (dispatch: Function, importLogs: ImportLog[]): void => {
    const setImportLogsAction = {...SET_IMPORT_LOGS_ACTION};
    setImportLogsAction.importLogs = importLogs;
    dispatch(setImportLogsAction);
}
