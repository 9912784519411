import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {Controller, FieldError, useForm} from 'react-hook-form';
import SelectOption from 'model/select-option';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import {
	getFieldsFromSupplyStatus,
	getPotencyOptions,
	getStatusChangeFromSupply,
	sendRequest,
	newSupplyRequest, getAllAgencyCompanies
} from './services/open-request.service';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {FORM_CONSTANTS} from 'config/form.config';
import {validateBankAccount, validateNationalId, validatePhone} from 'services/validation.service';
import {OPEN_REQUEST_CONSTANTS} from './config/open-request.config';
import {SupplyTypeEnum} from 'model/supply-type.enum';
import {SupplyStatusChangeEnum} from 'model/supply-status-change.enum';
import {HolderTypeEnum} from 'model/holder-type.enum';
import SelectOptionImp from 'model/select-option.imp';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import BorganAction from 'model/borgan-action';
import SupplyImp from 'model/supply.imp';
import Supply from 'model/supply';
import AgencyCompany from 'model/agency-company';
import {SET_ALL_COMPANIES_ACTION} from 'store/agency/actions';
import './open-request-modal.component.scss';

export interface OpenRequestFormData {
	holderType: SelectOption;
	supply: SelectOption;
	status: SelectOption | null;
	company_id: SelectOption;
	newHolder?: string;
	newHolderNationalId?: string;
	newHolderPhone?: string;
	newHolderEmail?: string;
	newHolderBankAccount?: string;
	hiredPower?: SelectOption;
	hiredGas?: SelectOption;
	fiberSpeed?: SelectOption;
	mobileDesiredData?: string;
}

interface OpenRequestModalComponent {
	selectedSupply: Supply;
}

const OpenRequestModalComponent: FunctionComponent<OpenRequestModalComponent> = ({ selectedSupply }): ReactElement => {
	/* istanbul ignore next */
	const { country, agencyId } = useSelector((state: MainState) => state.auth.user);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	/* istanbul ignore next */
	const { allAgencyCompanies } = useSelector((state: MainState) => state.agency);
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, control, reset } = useForm<OpenRequestFormData>({
		defaultValues: {
			supply: new SelectOptionImp(<FormattedMessage id={selectedSupply.type}/>, selectedSupply.type),
			holderType: new SelectOptionImp(<FormattedMessage id={HolderTypeEnum.AGENCY}/>, HolderTypeEnum.AGENCY)
		}
	});
	const [ holderType, setHolderType ] = useState<HolderTypeEnum>(HolderTypeEnum.AGENCY);
	const [ supplyType, setSupplyType ] = useState<SupplyTypeEnum>(selectedSupply.type);
	const [ supplyStatusChange, setSupplyStatusChange ] = useState<SupplyStatusChangeEnum>();
	const [ statusOptions, setStatusOptions ] = useState<SelectOption[]>([]);
	const [ fields, setFields ] = useState<string[]>([]);
	const [ requiredFields, setRequiredFields ] = useState<string[]>([]);

	useEffect(() => {
		if (supplyType && supplyStatusChange) {
			const [ newFields, newRequiredFields ] = getFieldsFromSupplyStatus(country, supplyType, supplyStatusChange);
			setFields(newFields);
			setRequiredFields(newRequiredFields);
		} else {
			setFields([]);
			setRequiredFields([]);
		}
	}, [country, supplyType, supplyStatusChange]);

	useEffect(() => {
		setStatusOptions(getStatusChangeFromSupply(country, HolderTypeEnum.AGENCY, selectedSupply.type));
	}, [country, selectedSupply.type]);

	useEffect(() => {
		/* istanbul ignore else */
		if (!allAgencyCompanies.length) {
			dispatch(SHOW_LOADING_ACTION);

			getAllAgencyCompanies(agencyId)
				.then((companies: AgencyCompany[]) => {
					const setAllCompaniesAction = {...SET_ALL_COMPANIES_ACTION};
					setAllCompaniesAction.companies = companies;
					dispatch(setAllCompaniesAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('companies_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	},[allAgencyCompanies.length, agencyId, dispatch]);

	const onHolderTypeChange = (newHolderType: SelectOption, onChange: Function): void => {
		onChange(newHolderType);
		setHolderType(newHolderType.value);

		/* istanbul ignore else */
		if (supplyType) {
			reset({ status: null });
			setSupplyStatusChange(undefined);
			setStatusOptions(getStatusChangeFromSupply(country, newHolderType.value, supplyType));
		}
	};

	const onSupplyChange = (newSupply: SelectOption, onChange: Function): void => {
		onChange(newSupply);
		reset({ status: null });
		setSupplyStatusChange(undefined);
		setStatusOptions(getStatusChangeFromSupply(country, holderType, newSupply.value));
		setSupplyType(newSupply.value);
	};

	const onSupplyStatusChange = (newSupplyStatus: SelectOption, onChange: Function): void => {
		onChange(newSupplyStatus);
		setSupplyStatusChange(newSupplyStatus.value);
	};

	const openRequest = (formData: OpenRequestFormData): void => {
		const supplyId = currentProperty.supplies.find(s => s.type === formData.supply.value)?.id;
		dispatch(SHOW_LOADING_ACTION);

		if (supplyId) {
			sendRequest(formData, currentProperty, supplyId)
				.then((action: BorganAction) => {
					const index = currentProperty.supplies.findIndex(s => s.id === supplyId);

					currentProperty.supplies[index].incidences++;
					currentProperty.supplies[index].actions.push(action);
					const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
					setCurrentPropertyAction.property = currentProperty;
					dispatch(setCurrentPropertyAction);

					showGrowlMessage(new GrowlMessageImp('request_sent', true), dispatch);
					dispatch(HIDE_MODAL_ACTION);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('request_sent_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		} else {
			newSupplyRequest(formData, currentProperty)
				.then((newSupplyRequestAction: BorganAction) => {
					const newSupply = new SupplyImp(newSupplyRequestAction.supplyId, formData.supply.value);

					newSupply.actions.push(newSupplyRequestAction);
					currentProperty.supplies.push(newSupply);
					const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
					setCurrentPropertyAction.property = currentProperty;
					dispatch(setCurrentPropertyAction);

					showGrowlMessage(new GrowlMessageImp('request_sent', true), dispatch);
					dispatch(HIDE_MODAL_ACTION);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('request_sent_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	};

	return <div className="open-request-modal-component">
		<h3 className="title u-text--secondary"><FormattedMessage id="open_request"/></h3>
		<form onSubmit={handleSubmit(openRequest)} data-testid="form">
			<Controller
				control={control}
				name="holderType"
				rules={{required: 'field_required'}}
				render={({ onChange, value }) => (
					<div className="form-group">
						<label className="form-group__label"><FormattedMessage id="holder_type"/></label>
						<Select
							options={OPEN_REQUEST_CONSTANTS.HOLDER_TYPES}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onHolderTypeChange(e, onChange)}
							isMulti={false}
						/>
					</div>
				)}
			/>
			<Controller
				control={control}
				name="supply"
				rules={{required: 'field_required'}}
				render={({ onChange, value }) => (
					<div className="form-group">
						<label className="form-group__label"><FormattedMessage id="supply"/></label>
						<Select
							options={OPEN_REQUEST_CONSTANTS.SUPPLIES}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onSupplyChange(e, onChange)}
							isMulti={false}
						/>
					</div>
				)}
			/>
			<Controller
				control={control}
				name="status"
				rules={{required: 'field_required'}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.status ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="type"/></label>
						<Select
							options={statusOptions}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onSupplyStatusChange(e, onChange)}
							isMulti={false}
						/>
						{ errors.status && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.status as FieldError).message}/></small> }
					</div>
				)}
			/>
			{ fields.includes('newHolder') && <div className={`form-group ${errors.newHolder ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="holder"/></label>
                <input type="text" name="newHolder" className="form-group__item" data-testid="new-holder-input" ref={register({
					required: requiredFields.includes('newHolder') ? 'field_required' : ''
				})}/>
				{ errors.newHolder && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.newHolder.message}/></small> }
            </div> }
			{ fields.includes('newHolderPhone') && <div className={`form-group ${errors.newHolderPhone ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="phone"/></label>
				<input type="phone" name="newHolderPhone" className="form-group__item" data-testid="new-holder-phone-input" ref={register({
					required: requiredFields.includes('newHolderPhone') ? 'field_required' : '',
					validate: phone => validatePhone(country, phone)
				})}/>
				{ errors.newHolderPhone && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.newHolderPhone.message}/></small> }
			</div> }
			{ fields.includes('newHolderNationalId') && <div className={`form-group ${errors.newHolderNationalId ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="national_id"/></label>
				<input type="text" name="newHolderNationalId" className="form-group__item" data-testid="new-holder-national-id-input" ref={register({
					required: requiredFields.includes('newHolderNationalId') ? 'field_required' : '',
					validate: nationalId => validateNationalId(country, nationalId)
				})}/>
				{ errors.newHolderNationalId && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.newHolderNationalId.message}/></small> }
			</div> }
			{ fields.includes('newHolderEmail') && <div className={`form-group ${errors.newHolderEmail ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="email"/></label>
				<input type="email" name="newHolderEmail" className="form-group__item" data-testid="new-holder-email-input" ref={register({
					required: requiredFields.includes('newHolderEmail') ? 'field_required' : '',
					pattern: {
						value: FORM_CONSTANTS.REGEX.EMAIL,
						message: 'invalid_email'
					}
				})}/>
				{ errors.newHolderEmail && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.newHolderEmail.message}/></small> }
			</div> }
			{ fields.includes('company_id') && <Controller
                control={control}
                name="company_id"
                rules={{required: 'field_required'}}
                render={({ onChange, value }) => (
					<div className={`form-group ${errors.company_id ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="company"/></label>
						<Select
							options={allAgencyCompanies.map(c => new SelectOptionImp(c.name, c.id))}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.company_id && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.company_id as FieldError).message}/></small> }
					</div>
				)}
            /> }
			{ fields.includes('newHolderBankAccount') && <div className={`form-group ${errors.newHolderBankAccount ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="bank_account"/></label>
				<input type="text" name="newHolderBankAccount" className="form-group__item" data-testid="new-holder-bank-account-input" ref={register({
					required: requiredFields.includes('newHolderBankAccount') ? 'field_required' : '',
					validate: nationalId => validateBankAccount(country, nationalId)
				})}/>
				{ errors.newHolderBankAccount && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.newHolderBankAccount.message}/></small> }
			</div> }
			{ fields.includes('hiredPower') && <Controller
				control={control}
				name="hiredPower"
				rules={{required: requiredFields.includes('hiredPower') ? 'field_required' : ''}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.hiredPower ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="potency"/></label>
						<Select
							options={getPotencyOptions()}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.hiredPower && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.hiredPower as FieldError).message}/></small> }
					</div>
				)}
			/> }
			{ fields.includes('hiredGas') && <Controller
				control={control}
				name="hiredGas"
				rules={{required: requiredFields.includes('hiredGas') ? 'field_required' : ''}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.hiredGas ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="Gas"/></label>
						<Select
							options={OPEN_REQUEST_CONSTANTS.GAS_RATES}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.hiredGas && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.hiredGas as FieldError).message}/></small> }
					</div>
				)}
			/> }
			{ fields.includes('fiberSpeed') && <Controller
				control={control}
				name="fiberSpeed"
				rules={{required: requiredFields.includes('fiberSpeed') ? 'field_required' : ''}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.fiberSpeed ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="telecom"/></label>
						<Select
							options={OPEN_REQUEST_CONSTANTS.FIBER_SPEED}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.fiberSpeed && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.fiberSpeed as FieldError).message}/></small> }
					</div>
				)}
			/> }
			{ fields.includes('mobileDesiredData') && <div className={`form-group ${errors.mobileDesiredData ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="mobile_data"/></label>
				<input type="number" name="mobileDesiredData" className="form-group__item" data-testid="mobile-desired-data-input" ref={register({
					required: requiredFields.includes('mobileDesiredData') ? 'field_required' : '',
					min: { value: 0, message: 'min_0' }
				})}/>
				{ errors.mobileDesiredData && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.mobileDesiredData.message}/></small> }
			</div> }
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="send"/>
			</button>
		</form>
	</div>;
};

export default OpenRequestModalComponent;
