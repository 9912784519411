import Property from 'model/property';
import PropertyImp from 'model/property.imp';
import {JsonConvert} from 'json2typescript';
import Supply from 'model/supply';
import SupplyImp from 'model/supply.imp';
import {APP_CONSTANTS} from 'config/app.config';
import axios from 'axios';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';
import {
	AddNewPropertiesFormData, AddNewPropertyFormData
} from 'components/modal/add-properties/add-property-modal.component';
import BorganAction from 'model/borgan-action';
import BorganActionImp from 'model/borgan-action.imp';
import {PropertySupplyEditComponentFormData} from '../components/property-supply-edit/property-supply-edit.component';

const jsonConvert: JsonConvert = new JsonConvert();

export const loadSupplyDetail = (property: Property, supplyId: string): Promise<Supply> => {
	return axios.get(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_INFO, property.agencyId, property.id)}/${supplyId}`)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, SupplyImp));
};

export const updateSupplyHolder = (formData: PropertySupplyEditComponentFormData, property: Property, supplyId: string): Promise<Supply> => {
	const data: any = { ...formData };
	data.company_id = data.company.value;
	delete data.owner;
	delete data.sale_state;
	delete data.atr_status;
	delete data.atr_date;
	delete data.company;

	const body = { data: { type: 'supply', attributes: { owner: formData.owner, sale_state: formData.sale_state.value, atr_status: formData.atr_status, atr_date: formData.atr_date, data }}};

	return axios.put(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_INFO, property.agencyId, property.id)}/${supplyId}`, body);
};

export const getPropertyDetail = (property: Property): Promise<Property> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.PROPERTY, property.agencyId);

	return axios.get(`${endpoint}/${property.id}`)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, PropertyImp));
};

export const updatePropertyStreet = (property: Property): Promise<Property> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.PROPERTY, property.agencyId);
	const body = { data: { type: 'Property', attributes: { address: property.address }}};

	return axios.put(`${endpoint}/${property.id}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, PropertyImp));
};

export const updatePropertyInformation = (property: Property): Promise<Property> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.PROPERTY, property.agencyId);
	const body = { data: { type: 'Property', attributes: { code: property.code, energy_cups: property.energyCups, gas_cups: property.gasCups, cadastral_reference: property.cadastralReference, province: property.province,
		city: property.city, postal_code: property.postalCode, management_date: property.managementDate }}};

	return axios.put(`${endpoint}/${property.id}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, PropertyImp));
};

export const sendPropertyIncidence = (desc: string, property: Property, supplyId: string): Promise<BorganAction> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_ACTIONS, property.agencyId, property.id, supplyId);
	const body = {
		data: {
			type: 'action',
			attributes: { desc }
		}
	};

	return axios.post(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, BorganActionImp));
};

export const addProperties = (data: AddNewPropertiesFormData): Promise<Property[]> => {
	const formData = new FormData();
	formData.append('import[properties_csv]', data.file);

	return axios.post(
		replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_IMPORT_PROPERTIES, data.agency.value),
		formData
	).then(res => jsonConvert.deserializeArray(res.data.data.map((property: any) => ({ ...property, ...property.attributes })), PropertyImp));
};

export const addProperty = (data: AddNewPropertyFormData, agencyId: string): Promise<Property> => {
	return axios.post(
		replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.PROPERTY, agencyId),
		{ data: { type: 'Property', attributes: { address: data.address, code: data.code } } }
	).then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, PropertyImp));
};

export const resolveAction = (property: Property, supplyId: string, actionId: string): Promise<void> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_ACTIONS, property.agencyId, property.id, supplyId);
	const body = {
		data: {
			type: 'action',
			attributes: { solved: true }
		}
	};

	return axios.put(`${endpoint}/${actionId}`, body);
};

export const deletePropertyStreet = (property: Property): Promise<Property> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.PROPERTY, property.agencyId);
	return axios.delete(`${endpoint}/${property.id}`);
};

export const getFieldIcon = (field: string): string => {
	switch (field) {
		case 'owner_phone':
			return 'icon-phone-horn';
		case 'owner_identification':
			return 'icon-credit-card';
		case 'owner_email':
			return 'icon-email';
		case 'owner_iban':
			return 'icon-bank-check';
		case 'company':
		case 'company_name':
			return 'icon-briefcase';
		case 'contract_number':
			return 'icon-file-outlined';
		case 'sale_state':
			return 'icon-arrows-bottom-top';
		case 'atr_date':
			return 'icon-calendar';
		default:
			return '';
	}
};
