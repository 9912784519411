/* istanbul ignore file */
const FIREBASE_CONFIG: Object = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const FIREBASE_CONFIG_ADMIN: Object = {
	apiKey: process.env.REACT_APP_FIREBASE_ADMIN_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_ADMIN_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_ADMIN_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_ADMIN_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_ADMIN_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_ADMIN_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_ADMIN_APP_ID
};

export {FIREBASE_CONFIG, FIREBASE_CONFIG_ADMIN};
