import React, {FunctionComponent, ReactElement, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useForm} from 'react-hook-form';

export interface DeleteForm {
	field: string;
	repeatField: string;
}

interface DeleteModalComponentProps {
	fieldValue: string;
	subtitle?: string;
	label: string;
	repeatLabel: string;
	title: string;
	onDelete: Function;
}

const DeleteModalComponent: FunctionComponent<DeleteModalComponentProps> = ({ fieldValue, subtitle, label, repeatLabel, title, onDelete }): ReactElement => {
	const { register, handleSubmit, errors } = useForm<DeleteForm>();
	const [ errorMsg, setErrorMsg ] = useState<string>('');

	const submit = (form: DeleteForm): void => {
		if (form.field !== form.repeatField) {
			setErrorMsg('fields_not_match');
		} else if (form.field !== fieldValue) {
			setErrorMsg('fields_not_match_value');
		} else {
			onDelete();
		}
	};

	return <div>
		<h3 className="title u-text--secondary"><FormattedMessage id={title}/></h3>
		{subtitle && <p className="subtitle-alert u-text--secondary"><FormattedMessage id={subtitle}/></p>}
		<form onSubmit={handleSubmit(submit)} data-testid="form">
			<div className={`form-group ${errors.field ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id={label}/></label>
				<input onChange={() => setErrorMsg('')} name="field" className="form-group__item" ref={register({required: 'field_required'})} data-testid="field-2"/>
				{ errors.field && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.field.message}/></small> }
			</div>
			<div className={`form-group ${errors.repeatField ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id={repeatLabel}/></label>
				<input onChange={() => setErrorMsg('')} name="repeatField" className="form-group__item" ref={register({required: 'field_required'})} data-testid="field-1"/>
				{ errors.repeatField && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.repeatField.message}/></small> }
				{ errorMsg && <small className="form-group__msg form-group__msg--danger" data-testid="error-message"><FormattedMessage id={errorMsg}/></small> }
			</div>
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="delete"/>
			</button>
		</form>
	</div>;
};

export default DeleteModalComponent;
