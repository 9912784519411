/* istanbul ignore file */
import Notification from './notification';
import {JsonObject, JsonProperty} from 'json2typescript';
import moment, {Moment} from 'moment';
import {MomentDeserializer} from './deserializers/moment.deserializer';
import {NotificationPropertyDeserializer} from './deserializers/notification-property.deserializer';
import Property from 'model/property';
import PropertyImp from 'model/property.imp';
import {NotificationTypeDeserializer} from './deserializers/notification-type.deserializer';
import { NotificationSupplyDeserializer } from './deserializers/notification-supply.desearializer';
import { SupplyTypeEnum } from './supply-type.enum';

@JsonObject('NotificationImp')
export default class NotificationImp implements Notification {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('attrs', NotificationTypeDeserializer, true)
	type: string;

	@JsonProperty('attrs', NotificationPropertyDeserializer, true)
	property: Property;

	@JsonProperty('attrs', NotificationSupplyDeserializer, true)
	supply: SupplyTypeEnum;

	@JsonProperty('created_at', MomentDeserializer, true)
	createdAt: Moment;

	@JsonProperty('read_at', MomentDeserializer, true)
	readAt?: Moment;

	constructor(id: string = '', type: string = '', property: Property = new PropertyImp(), 
	supply: SupplyTypeEnum = SupplyTypeEnum.POWER, createdAt: Moment = moment(), readAt?: Moment) {
		this.id = id;
		this.type = type;
		this.property = property;
		this.supply = supply;
		this.createdAt = createdAt;
		this.readAt = readAt;
	}
}
