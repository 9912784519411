import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './property-supply.component.scss';
import Supply from 'model/supply';
import {FormattedMessage} from 'react-intl';
import Collapsible from 'react-collapsible';
import icons from 'sprite/sprite.svg';
import {getSupplyStatusIcon, getSupplyTypeIcon} from './services/property-supply.service';
import {SupplyStatusEnum} from 'model/supply-status.enum';
import ChangeSupplyStatusModalComponent
	from 'components/modal/change-supply-status/change-supply-status-modal.component';
import {useDispatch, useSelector} from 'react-redux';
import PropertySupplyInfoComponent from '../property-supply-info/property-supply-info.component';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {loadSupplyDetail} from '../../services/property-detail.service';
import MainState from 'store/model/main.state';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {UserTypeEnum} from 'model/user-type.enum';
import {openModal} from 'services/utils.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import OpenRequestModalComponent from 'components/modal/open-request/open-request-modal.component';

export interface PropertySupplyComponentProps {
	supply: Supply;
}

const PropertySupplyComponent: FunctionComponent<PropertySupplyComponentProps> = ({ supply }): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();
	const [ opened, setOpened ] = useState<boolean>(false);

	useEffect(() => {
		setOpened(false);
	}, [currentProperty.id]);

	const openCollapsible = (): void => {
		if (opened) {
			setOpened(false);
		} else {
			dispatch(SHOW_LOADING_ACTION);

			loadSupplyDetail(currentProperty, supply.id)
				.then((supplyDetail: Supply) => {
					const index = currentProperty.supplies.findIndex(s => s.id === supply.id);

					/* istanbul ignore else */
					if (index > -1) {
						currentProperty.supplies[index] = supplyDetail;
						const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
						setCurrentPropertyAction.property = currentProperty;
						dispatch(setCurrentPropertyAction);
						setOpened(true);
					}
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('property_supply_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	};

	const getTrigger = (): ReactElement => {
		return <div className={`property-supply-component ${supply.status}`}>
			<div className="icon-container">
				<span>
					{ !!(supply.incidences > 0 || supply.actions.length) && <div className="has-incidences"/> }
					{ !!(supply.invoicesAmount > 0 || supply.invoices.length) && <svg className="icon icon--12 has-invoices">
                        <use xlinkHref={`${icons}#icon-file-outlined`}/>
                    </svg> }
					<svg className="icon icon--24">
						<use xlinkHref={`${icons}#${getSupplyTypeIcon(supply.type)}`}/>
					</svg>
				</span>
				<b><FormattedMessage id={supply.type}/></b>
			</div>
			<div className="status">
				<span className={!user.permissions.includes(UserTypeEnum.SELECTRA) ? 'not-allowed' : ''}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getSupplyStatusIcon(supply.status)}`}/>
					</svg>
					<FormattedMessage id={supply.status}/>
				</span>
				{ user.permissions.includes(UserTypeEnum.SELECTRA) && <div onClick={() => openModal(<ChangeSupplyStatusModalComponent supply={supply}/>, dispatch)} data-testid="change-status-button">
                    <svg className="icon icon--16">
                        <use xlinkHref={`${icons}#icon-chevron-right`}/>
                    </svg>
                </div> }
			</div>
			<div className="holder">
				<span>{ supply.owner }</span>
				{ (supply.status === SupplyStatusEnum.NOT_REGISTERED || supply.status === SupplyStatusEnum.CANCELLED)
				? (!user.permissions.includes(UserTypeEnum.SELECTRA) && <button className="register-button" onClick={() => openModal(<OpenRequestModalComponent selectedSupply={supply}/>, dispatch)} data-testid="register-button">
					<FormattedMessage id="open_request"/>
				</button>) : <svg className={`icon--16 icon--right ${opened && 'opened'}`} onClick={openCollapsible} data-testid="open-collapsible">
					<use xlinkHref={`${icons}#icon-chevron-right`}/>
				</svg> }
			</div>
		</div>;
	};

	return <Collapsible trigger={getTrigger()} open={opened} triggerDisabled={true}>
		{ opened && <PropertySupplyInfoComponent supply={supply}/> }
	</Collapsible>;
};

export default PropertySupplyComponent;
