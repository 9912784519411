/* istanbul ignore file */
import User from './user';
import {JsonObject, JsonProperty} from 'json2typescript';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';

@JsonObject('UserImp')
export default class UserImp implements User {

	@JsonProperty('id', NumberToStringDeserializer, true)
	id: string;

	@JsonProperty('email', String, true)
	email: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('country', String, true)
	country: string;

	@JsonProperty('permissions', [String], true)
	permissions: string[];

	@JsonProperty('agency_id', NumberToStringDeserializer, true)
	agencyId: string;

	@JsonProperty('company_id', NumberToStringDeserializer, true)
	companyId: string;

	@JsonProperty('locale', String, true)
	locale: string;

	constructor(id: string = '', email: string = '', name: string = '', country: string = '', permissions: string[] = [], agencyId: string = '', companyId: string = '', locale: string = '') {
		this.id = id;
		this.email = email;
		this.name = name;
		this.country = country;
		this.permissions = permissions;
		this.agencyId = agencyId;
		this.companyId = companyId;
		this.locale = locale;
	}
}
