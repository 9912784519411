import React, {FunctionComponent, ReactElement, useState, useEffect} from 'react';
import './recover-password.component.scss';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import icons from 'sprite/sprite.svg';
import queryString from 'query-string';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Redirect } from 'react-router-dom';
import { APP_CONSTANTS } from 'config/app.config';
import { useDispatch } from 'react-redux';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';

interface FormData {
	password: string,
	repeatPassword: string
}

const RecoverPasswordComponent: FunctionComponent = (): ReactElement => {
	const { handleSubmit, errors, register } = useForm<FormData>();
	const [ passwordShown, setPasswordShown ] = useState<boolean>(false);
	const [ passwordRepeatShown, setPasswordRepeatShown ] = useState<boolean>(false);
	const [ redirectToLogin, setRedirectToLogin ] = useState<boolean>(false);
	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ success, setSuccess ] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { oobCode } = queryString.parse(window.location.search) as Record<string, string | undefined>;

	useEffect(() => {
		dispatch(SHOW_LOADING_ACTION);

		app.auth().verifyPasswordResetCode(String(oobCode))
			.catch((err) => {
				setErrorMsg(err.code);
				setRedirectToLogin(true);
			})
			.finally(() => dispatch(HIDE_LOADING_ACTION));	
	}, [oobCode, dispatch, setRedirectToLogin]);

	const recoverPassword = (form: FormData): void => {
		const equalPassword: boolean = form.password === form.repeatPassword;

		if (equalPassword) {
			dispatch(SHOW_LOADING_ACTION);

			app.auth().confirmPasswordReset(String(oobCode), form.password)
				.then(() => {
					setErrorMsg('password-change-worked');
					setSuccess(true);
					setRedirectToLogin(true);
				})
				.catch((err) => setErrorMsg(err.code))
				.finally(() => dispatch(HIDE_LOADING_ACTION));	
		} else {
			setErrorMsg('form_pwd_not_match');
		}
	};

	if (redirectToLogin) {
		return <Redirect to={`${APP_CONSTANTS.ROUTES.LOGIN}?error=${errorMsg}&success=${success}`}/>;
	}

	return <div className="recover-password">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 img-responsive recover-password__logo">
					<img src={process.env.PUBLIC_URL + '/selectra.svg'} alt="selectra" />
				</div>
			</div>
			<div className="row row--center">
				<div className="col-lg-4 card recover-password__card">
					<h1 className="card__title u-text--secondary">
						<FormattedMessage id="change_password" />
					</h1>
					<form onSubmit={handleSubmit(recoverPassword)} data-testid="form">
						<div className="form-group">
							<label className="form-group__label-addon"><FormattedMessage id="password" /></label>
							<div className="form-group__item-addon">
								<input className="form-group__item" type={passwordShown ? 'text' : 'password'} name='password' ref={register({ minLength: { message: 'min_length_6', value: 6 }, required: 'required_field' })} data-testid="pass-input-1"/>
								<span className="form-group__addon" title="Show/Hide" onClick={() => setPasswordShown(!passwordShown)} data-testid="pass-toggle-1">
									<svg className="icon icon--24 icon--gray clickable">
										<use xlinkHref={`${icons}#icon-eye`}/>
									</svg>
								</span>
							</div>
							{ errors.password && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id="invalid_password"/></small> }
						</div>
						<div className="form-group">
							<label className="form-group__label-addon"><FormattedMessage id="repeat_password" /></label>
							<div className="form-group__item-addon">
								<input className="form-group__item" type={passwordRepeatShown ? 'text' : 'password'} name='repeatPassword' ref={register({ minLength: { message: 'min_length_6', value: 6 }, required: 'required_field' })} data-testid="pass-input-2"/>
								<span className="form-group__addon" title="Show/Hide" onClick={() => setPasswordRepeatShown(!passwordRepeatShown)} data-testid="pass-toggle-2">
									<svg className="icon icon--24 icon--gray clickable">
										<use xlinkHref={`${icons}#icon-eye`}/>
									</svg>
								</span>
							</div>
							{ errors.repeatPassword && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id="invalid_password"/></small> }
						</div>
						<button type="submit" className="btn btn--lg btn--secondary btn--block recover-password__card--btn">
							<FormattedMessage id="update_password" />
						</button>
					</form>
				</div>
			</div>
			{ errorMsg && <div className="row row--center">
				<div className="col-lg-4 card alerts alerts__danger recover-password__alert">
					<svg className="icon icon--24 icon--danger icon--left">
						<use xlinkHref={`${icons}#icon-circle-cross-mark-filled`}/>
					</svg>
					<FormattedMessage id={errorMsg} />
				</div>
			</div> }
		</div>
	</div>;
};

export default RecoverPasswordComponent;
