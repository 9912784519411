import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import './property-supply-invoices.component.scss';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Document from 'model/document';
import {deleteSupplyInvoice, getInvoices} from './services/property-supply-invoices.service';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {openModal} from 'services/utils.service';
import icons from 'sprite/sprite.svg';
import {UserTypeEnum} from 'model/user-type.enum';
import MainState from 'store/model/main.state';
import {SET_SUPPLY_INVOICES_ACTION} from 'store/property/actions';
import UploadInvoiceModalComponent from 'components/modal/upload-invoice/upload-invoice-modal.component';

interface PropertySupplyInvoicesComponentProps {
	supplyId: string;
}

const PropertySupplyInvoicesComponent: FunctionComponent<PropertySupplyInvoicesComponentProps> = ({ supplyId }): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();
	const invoices = currentProperty.supplies.find(s => s.id === supplyId)?.invoices || [];

	useEffect(() => {
		if (supplyId) {
			dispatch(SHOW_LOADING_ACTION);

			getInvoices(currentProperty.agencyId, currentProperty.id, supplyId)
				.then(newInvoices => {
					const setSupplyInvoicesAction = {...SET_SUPPLY_INVOICES_ACTION};
					setSupplyInvoicesAction.supplyId = supplyId;
					setSupplyInvoicesAction.invoices = newInvoices;
					dispatch(setSupplyInvoicesAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('invoices_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));			
		}
	}, [currentProperty.agencyId, currentProperty.id, dispatch, supplyId]);

	const deleteInvoice = (index: number): void => {
		dispatch(SHOW_LOADING_ACTION);

		deleteSupplyInvoice(currentProperty.agencyId, currentProperty.id, supplyId, invoices[index].id)
			.then(() => {
				const newInvoices = [...invoices];
				newInvoices.splice(index, 1);
				const setSupplyInvoicesAction = {...SET_SUPPLY_INVOICES_ACTION};
				setSupplyInvoicesAction.supplyId = supplyId;
				setSupplyInvoicesAction.invoices = newInvoices;
				dispatch(setSupplyInvoicesAction);
				showGrowlMessage(new GrowlMessageImp('invoice_deleted', true), dispatch);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('invoice_delete_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="property-supply-invoices-component">
		<h5 className="title">
			<FormattedMessage id="invoices"/>
			<button className="btn btn--sm btn--secondary" onClick={() => openModal(<UploadInvoiceModalComponent supplyId={supplyId}/>, dispatch)} data-testid="add-invoice-button">
				<FormattedMessage id="add_invoice"/>
			</button>
		</h5>
		<div className="invoices">
			{ invoices.length ? invoices.map((invoice: Document, index: number) => {
				return <div className="invoice-item" key={index}>
					<div>
						<svg className="icon icon--16 icon--left icon--neutral">
							<use xlinkHref={`${icons}#icon-file-outlined`}/>
						</svg>
						<a target="_blank" rel="noopener noreferrer" href={invoice.file.url}>
							{ invoice.name }
						</a>						
					</div>
					<div>
						{ user.permissions.includes(UserTypeEnum.SELECTRA) && <svg className="icon icon--16 icon--right delete_icon" onClick={() => deleteInvoice(index)} data-testid="delete-invoice-button">
							<use xlinkHref={`${icons}#icon-trash`}/>
						</svg> }
					</div>
				</div>
			}) : <p className="no-invoices">
				<FormattedMessage id="no_invoices"/>
			</p> }
		</div>
	</div>;
};

export default PropertySupplyInvoicesComponent;
