/* istanbul ignore file */
import Supply from './supply';
import Document from './document';
import Message from './message';
import Property from './property';
import {JsonObject, JsonProperty} from 'json2typescript';
import SupplyImp from './supply.imp';
import DocumentImp from './document.imp';
import MessageImp from './message.imp';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';
import { Moment } from 'moment';
import { MomentDeserializer } from './deserializers/moment.deserializer';
@JsonObject('PropertyImp')
export default class PropertyImp implements Property {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('agency_id', NumberToStringDeserializer, true)
	agencyId: string;

	@JsonProperty('address', String, true)
	address: string;

	@JsonProperty('code', String, true)
	code: string;

	@JsonProperty('cadastral_reference', String, true)
	cadastralReference: string;

	@JsonProperty('energy_cups', String, true)
	energyCups: string;

	@JsonProperty('gas_cups', String, true)
	gasCups: string;

	@JsonProperty('supplies', [SupplyImp], true)
	supplies: Supply[];

	@JsonProperty('documents', [DocumentImp], true)
	documents: Document[];

	@JsonProperty('conversation', [MessageImp], true)
	conversation: Message[];

	@JsonProperty('agency_name', String, true)
	agencyName: string;

	@JsonProperty('province', String, true)
	province: string;

	@JsonProperty('city', String, true)
	city: string;

	@JsonProperty('postal_code', String, true)
	postalCode: string;

	@JsonProperty('management_date', MomentDeserializer, true)
	managementDate?: Moment;

	checked: boolean;

	constructor(id: string = '', agencyId: string = '', address: string = '', code: string = '', cadastralReference: string = '',
				energyCups: string = '', gasCups: string = '', supplies: Supply[] = [], documents: Document[] = [],
				conversation: Message[] = [], agencyName: string = '', province: string = '', city: string = '', postalCode: string = '', 
				managementDate?: Moment ,checked: boolean = false) {
		this.id = id;
		this.agencyId = agencyId;
		this.address = address;
		this.code = code;
		this.cadastralReference = cadastralReference;
		this.energyCups = energyCups;
		this.gasCups = gasCups;
		this.supplies = supplies;
		this.documents = documents;
		this.conversation = conversation;
		this.agencyName = agencyName;
		this.province = province;
		this.city = city;
		this.postalCode = postalCode;
		this.managementDate = managementDate;
		this.checked = checked;
	}
}
