import axios from 'axios';
import {ReactElement} from 'react';
import {SHOW_MODAL_ACTION} from 'store/modal/actions';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FIREBASE_CONFIG_ADMIN } from 'config/firebase.config';

const appAdmin = app.initializeApp(FIREBASE_CONFIG_ADMIN, 'admin');

export const parseFileNameFromUrl = (url: string): string => {
	return (url.split('/').pop() || '').split('?')[0].replace(/%20/g, ' ');
};

export const replaceAgencyPropertyEndpoint = (endpoint: string, agencyId: string = '', propertyId: string = '', supplyId: string = ''): string => {
	return endpoint.replace('agency_id', String(agencyId))
		.replace('property_id', propertyId)
		.replace('supply_id', supplyId);
};

export const toBase64 = (file: File | string) => new Promise<string>((resolve: Function, reject: Function) => {
	if (typeof file === 'string') {
		resolve('');
	} else {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	}
});

export const openModal = (content: ReactElement, dispatch: Function): void => {
	const showModalAction = {...SHOW_MODAL_ACTION};
	showModalAction.content = content;
	dispatch(showModalAction);
};

export const setAxiosHeaders = /* istanbul ignore next */ (): void => {
	axios.interceptors.request.use(
		async config => {
			if (config.headers === undefined) {
				config.headers = {};
			}
			config.headers.Authorization = await getAuthToken();
			return config;
		}
	);
};

const getAuthToken = /* istanbul ignore next */ (): Promise<string> | string => {
	try {
		return appAdmin.auth().currentUser?.getIdToken() || '';
	} catch (e) {
		return '';
	}
};
