/* istanbul ignore file */
import {SupplyTypeEnum} from './supply-type.enum';
import {SupplyStatusEnum} from './supply-status.enum';
import Supply from './supply';
import {Any, JsonObject, JsonProperty} from 'json2typescript';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';
import BorganAction from './borgan-action';
import BorganActionImp from './borgan-action.imp';
import {SaleStateStatusEnum} from './sale-state-status.enum';
import {Moment} from 'moment';
import {MomentDeserializer} from './deserializers/moment.deserializer';
import AgencyCompany from './agency-company';
import AgencyCompanyImp from './agency-company.imp';
import Document from './document';

@JsonObject('SupplyImp')
export default class SupplyImp implements Supply {

	@JsonProperty('id', NumberToStringDeserializer, true)
	id: string;

	@JsonProperty('supply_type', String, true)
	type: SupplyTypeEnum;

	@JsonProperty('status', String, true)
	status: SupplyStatusEnum;

	@JsonProperty('owner', String, true)
	owner: string;

	@JsonProperty('data', Any, true)
	data: Record<string, any>;

	@JsonProperty('incidences', Number, true)
	incidences: number;

	@JsonProperty('invoices_amount', Number, true)
	invoicesAmount: number;

	@JsonProperty('actions', [BorganActionImp], true)
	actions: BorganAction[];

	invoices: Document[];

	@JsonProperty('sale_state', String, true)
	saleState: SaleStateStatusEnum;

	@JsonProperty('agency_company', AgencyCompanyImp, true)
	company: AgencyCompany;

	@JsonProperty('atr_status', String, true)
	atrStatus: string;

	@JsonProperty('atr_date', MomentDeserializer, true)
	atrDate?: Moment;

	constructor(id: string = '', type: SupplyTypeEnum = SupplyTypeEnum.POWER, status: SupplyStatusEnum = SupplyStatusEnum.NOT_REGISTERED,
				owner: string = '', data: Record<string, any> = {}, incidences: number = 0, invoicesAmount: number = 0,
				actions: BorganAction[] = [], invoices: Document[] = [], saleState: SaleStateStatusEnum = SaleStateStatusEnum.NO_SALE,
				company: AgencyCompany = new AgencyCompanyImp(), atrStatus: string = '', atrDate?: Moment) {
		this.id = id;
		this.type = type;
		this.status = status;
		this.owner = owner;
		this.data = data;
		this.incidences = incidences;
		this.invoicesAmount = invoicesAmount;
		this.invoices = invoices;
		this.actions = actions;
		this.saleState = saleState;
		this.company = company;
		this.atrStatus = atrStatus;
		this.atrDate = atrDate;
	}
}
