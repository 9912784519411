import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './add-property-modal.component.scss';
import {FormattedMessage} from 'react-intl';
import {Controller, FieldError, useForm} from 'react-hook-form';
import DragAndDropComponent from 'components/shared/drag-drop/drag-drop.component';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {useDispatch, useSelector} from 'react-redux';
import {
	addProperties, addProperty
} from 'components/properties/components/property-detail/services/property-detail.service';
import SelectOption from 'model/select-option';
import Select from 'react-select';
import {readAllAgencies} from 'components/agencies/services/agencies.service';
import MainState from 'store/model/main.state';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import Agency from 'model/agency';
import SelectOptionImp from 'model/select-option.imp';
import Property from 'model/property';
import {
	selectCurrentProperty,
	setPropertiesList
} from '../../properties/components/properties-list/services/properties-list.service';
import PropertyImp from 'model/property.imp';
import {SET_ALL_AGENCIES_ACTION} from 'store/agency/actions';
import {UserTypeEnum} from 'model/user-type.enum';
import { readImportLogs, setImportLogs } from 'components/import-logs/services/import-logs.service';
import ImportLog from 'model/import-logs';
import { APP_CONSTANTS } from 'config/app.config';
import { useLocation } from 'react-router-dom';

export interface AddNewPropertiesFormData {
	agency: SelectOption;
	file: any;
}

export interface AddNewPropertyFormData {
	agency?: SelectOption;
	address: string;
	code: string;
}

const AddPropertyModalComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { properties } = useSelector((state: MainState) => state.property);
	const { pathname } = useLocation();
	/* istanbul ignore next */
	const { allAgencies } = useSelector((state: MainState) => state.agency);
	const allAgenciesSort = [...allAgencies].sort((a, b) => a.name.localeCompare(b.name));
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	const dispatch = useDispatch();
	const { handleSubmit, control, errors } = useForm<AddNewPropertiesFormData>();
	const newPropertyForm = useForm<AddNewPropertyFormData>();
	const [ singleCreation, setSingleCreation ] = useState<boolean>(true);

	useEffect(() => {
		if (user.permissions.includes(UserTypeEnum.SELECTRA)) {
			dispatch(SHOW_LOADING_ACTION);

			readAllAgencies()
				.then((agencies: Agency[]) => {
					const setAllAgenciesAction = {...SET_ALL_AGENCIES_ACTION};
					setAllAgenciesAction.agencies = agencies;
					dispatch(setAllAgenciesAction)
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('agencies_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	}, [dispatch, user.permissions]);

	const addNewProperties = (data: AddNewPropertiesFormData) => {
		dispatch(SHOW_LOADING_ACTION);

		addProperties(data)
			.then((newProperties: Property[]) => {
				setPropertiesList(dispatch, newProperties);
				selectCurrentProperty(dispatch, newProperties[0] || new PropertyImp());

				if (pathname === APP_CONSTANTS.ROUTES.IMPORT_LOGS) {
					readImportLogs()
						.then((importLogs: ImportLog[]) => {
							setImportLogs(dispatch, importLogs);
						})
						.catch(() => {
							showGrowlMessage(new GrowlMessageImp('import_logs_read_error'), dispatch)
						})
				}

				if (newProperties.length > 0) {
					showGrowlMessage(new GrowlMessageImp('properties_created', true), dispatch);
				} else {
					showGrowlMessage(new GrowlMessageImp('import_logs_upload_error'), dispatch);
				}
				
				dispatch(HIDE_MODAL_ACTION);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('properties_create_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	const addNewProperty = (data: AddNewPropertyFormData) => {
		dispatch(SHOW_LOADING_ACTION);

		addProperty(data, data.agency?.value || user.agencyId)
			.then((newProperty: Property) => {
				properties.unshift(newProperty);
				setPropertiesList(dispatch, properties);
				selectCurrentProperty(dispatch, newProperty);
				showGrowlMessage(new GrowlMessageImp('property_created', true), dispatch);
				dispatch(HIDE_MODAL_ACTION);
			})
			.catch((err) => {
				console.log(err.response);

				if (err.response.data !== '') {
					if (err.response.data.address && err.response.data.address[0] === 'duplicated_property_name') {
						showGrowlMessage(new GrowlMessageImp('property_address_name_duplicated_error'), dispatch);
					}
					if (err.response.data.code && err.response.data.code[0] === 'duplicated_property_code') {
						showGrowlMessage(new GrowlMessageImp('property_code_duplicated_error'), dispatch);
					}
				} else {
					showGrowlMessage(new GrowlMessageImp('property_create_error'), dispatch);
				}
			})
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="add-property-modal-component">
		<h3 className="title u-text--secondary"><FormattedMessage id="add_properties"/></h3>
		{ user.permissions.includes(UserTypeEnum.SELECTRA) && <>
			<button className={`btn btn--md tab ${singleCreation && 'btn--secondary'}`} onClick={() => setSingleCreation(true)} data-testid="single-creation">
				<FormattedMessage id="form" />
			</button>
			<button className={`btn btn--md tab ${!singleCreation && 'btn--secondary'}`} onClick={() => setSingleCreation(false)} data-testid="tsv-creation">
				<FormattedMessage id="tsv" />
			</button>
		</> }
		{ singleCreation ? <form onSubmit={newPropertyForm.handleSubmit(addNewProperty)} data-testid="single-creation-form">
			{ user.permissions.includes(UserTypeEnum.SELECTRA) && <Controller
				control={newPropertyForm.control}
				name="agency"
				rules={{required: 'field_required'}}
				render={({onChange, value}) => (
					<div className={`form-group ${newPropertyForm.errors.agency ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="agency"/></label>
						<Select
							options={allAgenciesSort.map(agency => new SelectOptionImp(agency.name, agency.id))}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ newPropertyForm.errors.agency && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(newPropertyForm.errors.agency as FieldError).message}/></small> }
					</div>
				)}
			/> }
			<div className={`form-group ${newPropertyForm.errors.address ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="address"/></label>
				<input type="text" name="address" className="form-group__item" data-testid="address-input" ref={newPropertyForm.register({
					required: 'field_required'
				})}/>
				{ newPropertyForm.errors.address && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={newPropertyForm.errors.address.message}/></small> }
			</div>
			<div className={`form-group ${newPropertyForm.errors.code ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="code"/></label>
				<input type="text" name="code" className="form-group__item" data-testid="code-input" ref={newPropertyForm.register({
					required: 'field_required'
				})}/>
				{ newPropertyForm.errors.code && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={newPropertyForm.errors.code.message}/></small> }
			</div>
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="upload"/>
			</button>
		</form> : <form onSubmit={handleSubmit(addNewProperties)} data-testid="tsv-creation-form">
			<Controller
				control={control}
				name="agency"
				rules={{required: 'field_required'}}
				render={({onChange, value}) => (
					<div className={`form-group ${errors.agency ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="agency"/></label>
						<Select
							options={allAgenciesSort.map(agency => new SelectOptionImp(agency.name, agency.id))}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{errors.agency && <small className="form-group__msg form-group__msg--danger"><FormattedMessage
                            id={(errors.agency as FieldError).message}/></small>}
					</div>
				)}
			/>
			<DragAndDropComponent label="attached" fileType=".tsv" control={control} errors={errors}/>
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="upload"/>
			</button>
		</form> }
	</div>;
};

export default AddPropertyModalComponent;
