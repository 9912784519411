/* istanbul ignore file */
interface PropertySupplyInfoConfig {
	EXCLUDED_DATA_FIELDS: string[];
}

export const PROPERTY_SUPPLY_INFO_CONSTANTS: PropertySupplyInfoConfig = {
	EXCLUDED_DATA_FIELDS: [
		'company_id'
	]
};
