import React, {FunctionComponent, ReactElement} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import './edit-agency-modal.component.scss';
import Agency from 'model/agency';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import {updateAgencyInformation, deleteAgency} from '../../agencies/services/agencies.service';
import { SET_CURRENT_AGENCY_ACTION } from 'store/agency/actions';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import DeleteModalComponent from 'components/modal/delete/delete-modal.component';
import { SET_AGENCIES_ACTION } from 'store/agency/actions';
import AgencyImp from 'model/agency.imp';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import DragAndDropComponent from 'components/shared/drag-drop/drag-drop.component';
import {openModal} from 'services/utils.service';
import { UserTypeEnum } from 'model/user-type.enum';

export interface EditAgencyFormData {
    name: string;
    file: any;
}

const EditAgencyModalComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    const { currentAgency, agencies } = useSelector((state: MainState) => state.agency);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, control } = useForm<EditAgencyFormData>({
        defaultValues: {
            name: currentAgency.name,
            file: currentAgency.logo.url
        }
    });

    const editCurrentAgency = (data: EditAgencyFormData) => {
        dispatch(SHOW_LOADING_ACTION);

        updateAgencyInformation(data, currentAgency.id)
            .then((newDocument: Agency) => {
                currentAgency.logo = newDocument.logo;
                currentAgency.name = newDocument.name;
                
                const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
                setCurrentAgencyAction.agency = currentAgency;
                dispatch(setCurrentAgencyAction);
                dispatch(HIDE_MODAL_ACTION);
                showGrowlMessage(new GrowlMessageImp('agency_updated', true), dispatch);
            })
            .catch((err) => {
                if (err.response.data.name && err.response.data.name[0] === 'duplicated_agency_name') {
                    showGrowlMessage(new GrowlMessageImp('agency_name_duplicated_error'), dispatch);
                } else {
                    showGrowlMessage(new GrowlMessageImp('agency_update_error'), dispatch);
                }
            })
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

    const getDeleteModal = (): void => {
        openModal(<DeleteModalComponent
            title="delete_agency"
            subtitle="delete_agency_subtitle"
            fieldValue={currentAgency.name}
            label="agency_name"
            repeatLabel="repeat_agency_name"
            onDelete={() => {
                dispatch(SHOW_LOADING_ACTION);

                deleteAgency(currentAgency.id)
                    .then(() => {
                        const currentIndex = agencies.indexOf(currentAgency);
                        agencies.splice(currentIndex, 1);

                        const setAgenciesAction = {...SET_AGENCIES_ACTION};
                        setAgenciesAction.agencies = agencies;
                        dispatch(setAgenciesAction);

                        const setCurrentAgency = {...SET_CURRENT_AGENCY_ACTION};
                        setCurrentAgency.agency = agencies[0] || new AgencyImp();
                        dispatch(setCurrentAgency);
                        dispatch(HIDE_MODAL_ACTION);
                        showGrowlMessage(new GrowlMessageImp('agency_deleted', true), dispatch);
                    })
                    .catch(() => showGrowlMessage(new GrowlMessageImp('agency_delete_error'), dispatch))
                    .finally(() => dispatch(HIDE_LOADING_ACTION));
            }}
        />, dispatch);
    };

	return <div className="edit-agency-modal">
        <h3 className="title u-text--secondary"><FormattedMessage id="agency_information"/></h3>
        <form onSubmit={handleSubmit(editCurrentAgency)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="name"/></label>
                <input type="text" name="name" className="form-group__item" ref={register({required: 'field_required'})} data-testid="agency-name"/>
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
            <DragAndDropComponent label="logo" fileType="image/*" defaultFile={currentAgency.logo.url} control={control} errors={errors} />
            <button className="btn btn--block btn--lg btn--secondary edit-property-modal__btn" type="submit"><FormattedMessage id="update"/></button>
            { user.permissions.includes(UserTypeEnum.DELETE_AGENCY) && <button className="btn btn--white btn--block btn--md edit-agency-modal__delete-btn" onClick={getDeleteModal} data-testid="delete-agency">
                    <FormattedMessage id="delete_agency"/>
                </button>                
            }
        </form>
    </div>;
};

export default EditAgencyModalComponent;
