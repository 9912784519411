import AddAgentModalComponent from 'components/modal/add-agent/add-agent-modal.component';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import AgencyAgent from 'model/agency-agent';
import GrowlMessageImp from 'model/growl-message.imp';
import { UserTypeEnum } from 'model/user-type.enum';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'services/utils.service';
import { SET_CURRENT_AGENCY_ACTION, SET_CURRENT_AGENCY_AGENTS_ACTION } from 'store/agency/actions';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';
import MainState from 'store/model/main.state';
import './agency-agents.component.scss';
import { deleteAgencyAgent, getAgencyAgents } from './services/agency-agents.service';
import icons from 'sprite/sprite.svg';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import DeleteModalComponent from 'components/modal/delete/delete-modal.component';
import EditAgencyAgentModalComponent from 'components/modal/edit-agent/edit-agent-modal.component';

const AgencyAgentsComponent: FunctionComponent = (): ReactElement => {
    const { user } = useSelector((state: MainState) => state.auth);
    const { currentAgency } = useSelector((state: MainState) => state.agency); 
    const dispatch = useDispatch();

    useEffect(() => {
		if (currentAgency.id) {
			dispatch(SHOW_LOADING_ACTION);

			getAgencyAgents(currentAgency.id)
				.then((agents: AgencyAgent[]) => {
					const setCurrentAgencyAgentsAction = {...SET_CURRENT_AGENCY_AGENTS_ACTION};
					setCurrentAgencyAgentsAction.agents = agents;
					dispatch(setCurrentAgencyAgentsAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('agency_agents_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	}, [currentAgency.id, dispatch]);

	const getDeleteModal = (index: number): ReactElement => {
		return <DeleteModalComponent
			title="delete_agent"
			fieldValue={currentAgency.agents[index].email}
			label="email"
			repeatLabel="repeat_email"
			onDelete={() => {
				dispatch(SHOW_LOADING_ACTION);

				deleteAgencyAgent(currentAgency.agents[index], currentAgency.id)
					.then(() => {
						currentAgency.agents.splice(index, 1);
						const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
						setCurrentAgencyAction.agency = currentAgency;
						dispatch(setCurrentAgencyAction);
						dispatch(HIDE_MODAL_ACTION);
						showGrowlMessage(new GrowlMessageImp('agent_deleted', true), dispatch);
					})
					.catch(() => showGrowlMessage(new GrowlMessageImp('agent_delete_error'), dispatch))
					.finally(() => dispatch(HIDE_LOADING_ACTION));
			}}
		/>;
	};
    
    return <div className="agency-agents-component">
        <div className="agents-title">
            <h4><FormattedMessage id="agency_agents"/></h4>
            { user.permissions.includes(UserTypeEnum.CREATE_SELECTRA_AGENT) && <button className="btn btn--sm btn--secondary btn--outline" onClick={() => openModal(<AddAgentModalComponent/>, dispatch)} data-testid="add-agent">
                <FormattedMessage id="add"/>
                </button> 
            }
        </div>
        <table>
			<thead>
				<tr>
					<th><FormattedMessage id="name"/></th>
					<th><FormattedMessage id="email"/></th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{ currentAgency.agents.map((agencyAgent: AgencyAgent, index: number) => {
					return <tr key={index}>
						<td>{ agencyAgent.name }</td>
						<td className="email">{ agencyAgent.email }</td>
						<td className="icons">
						{ user.permissions.includes(UserTypeEnum.CREATE_SELECTRA_AGENT) &&
							<>
								<svg className="icon icon--24 clickable" onClick={() => openModal(<EditAgencyAgentModalComponent agencyAgentToEdit={agencyAgent}/>, dispatch)} data-testid="edit-agent">
									<use xlinkHref={`${icons}#icon-pencil`}/>
								</svg>
							</>
						}
						{ user.permissions.includes(UserTypeEnum.DELETE_SELECTRA_AGENT) &&
							<>
								<svg className="icon icon--24 clickable" onClick={() => openModal(getDeleteModal(index), dispatch)} data-testid="delete-user">
									<use xlinkHref={`${icons}#icon-trash`}/>
								</svg>
							</>
						}
						</td>
					</tr>;
				}) }
			</tbody>
		</table>
        { !currentAgency.agents.length && <p><FormattedMessage id="no_agents"/></p> }
    </div>
}

export default AgencyAgentsComponent;
