import AuthState from './model/auth.state';
import AuthStateImp from './model/auth.state.imp';
import MainAction from 'store/model/main.action';
import { AuthActionTypesEnum } from './types';
import {LogInAction} from './actions';
import UserImp from 'model/user.imp';
import {ReduxTypesEnum} from '../model/types';

const initialState: AuthState = new AuthStateImp();

const authReducer = (state = initialState, action: MainAction) => {
	switch (action.type) {
		case ReduxTypesEnum.RESET: {
			return new AuthStateImp();
		}
		case AuthActionTypesEnum.LOG_IN: {
			return Object.assign({}, state, {
				user: (action as LogInAction).user,
				isLogged: true
			});
        }
		case AuthActionTypesEnum.LOG_OUT: {
			return Object.assign({}, state, {
				isLogged: false,
				user: new UserImp()
			});
        }
        default:
			return state;
	}
};

export default authReducer;
