/* istanbul ignore file */
export enum MessageTypeEnum {
	SUPPLY = 'supply',
	UPDATE_SUPPLY = 'update_supply',
	CREATE_SUPPLY = 'create_supply',
	UPDATE_PROPERTY = 'update_property',
	INVOICE = 'invoice',
	DOCUMENT = 'document',
	REQUEST = 'request',
	INCIDENCE = 'incidence',
	COMMENT = 'comment'
}
