import { updateAgencyAgent } from 'components/agencies/components/agency-agents/services/agency-agents.service';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import { FORM_CONSTANTS } from 'config/form.config';
import AgencyAgent from 'model/agency-agent';
import GrowlMessageImp from 'model/growl-message.imp';
import React, { FunctionComponent, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_AGENCY_AGENTS_ACTION } from 'store/agency/actions';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import MainState from 'store/model/main.state';

export interface EditAgentForm {
	name: string;
	email: string;
}

interface EditAgencyAgentModalComponentProps {
	agencyAgentToEdit: AgencyAgent;
}

const EditAgencyAgentModalComponent: FunctionComponent<EditAgencyAgentModalComponentProps> = ({ agencyAgentToEdit }): ReactElement => {
	/* istanbul ignore next */
	const { currentAgency } = useSelector((state: MainState) => state.agency);
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm<EditAgentForm>({
		defaultValues: {
			name: agencyAgentToEdit.name,
			email: agencyAgentToEdit.email,
		}
	});

    const editAgent = (form: EditAgentForm): void => {
        dispatch(SHOW_LOADING_ACTION);

        updateAgencyAgent(agencyAgentToEdit, currentAgency.id, form)
            .then((updatedAgent: AgencyAgent | void) => {
				if (updatedAgent) {
					const index = currentAgency.agents.findIndex(u => u.id === updatedAgent.id);
					currentAgency.agents[index] = updatedAgent;

					const setCurrentAgencyAgentsAction = {...SET_CURRENT_AGENCY_AGENTS_ACTION};
					setCurrentAgencyAgentsAction.agents = currentAgency.agents;
					dispatch(setCurrentAgencyAgentsAction);
					dispatch(HIDE_MODAL_ACTION);
					showGrowlMessage(new GrowlMessageImp('agent_updated', true), dispatch);
				}
            }).catch((err) => {
				if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_name') {
					showGrowlMessage(new GrowlMessageImp('agent_name_duplicated_error'), dispatch);
				} else if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_email') {
					showGrowlMessage(new GrowlMessageImp('agent_mail_duplicated_error'), dispatch);
				} else if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_name_or_email') {
					showGrowlMessage(new GrowlMessageImp('agent_name_or_email_duplicated_error'), dispatch);
				}
				else {
					showGrowlMessage(new GrowlMessageImp('agent_edited_error'), dispatch);
				}
            }).finally(() => dispatch(HIDE_LOADING_ACTION));
    }
    
    return <div>
        <h3 className="title u-text--secondary"><FormattedMessage id="agent_data"/></h3>
        <form onSubmit={handleSubmit(editAgent)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="agency_agent_name"/></label>
				<input type="text" name="name" className="form-group__item" data-testid="name-input" ref={register({
					required: 'field_required'
				})}/>
				{ errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
			</div>
            <div className={`form-group ${errors.email ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="email"/></label>
				<input type="email" name="email" className="form-group__item" data-testid="email-input" ref={register({
					required: 'field_required',
					pattern: {
						value: FORM_CONSTANTS.REGEX.EMAIL,
						message: 'invalid_email'
					}
				})}/>
				{ errors.email && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.email.message}/></small> }
			</div>
            <button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="update"/>
			</button>
        </form>
    </div>
}

export default EditAgencyAgentModalComponent;
