import axios from 'axios';
import { AddAgentFormData } from 'components/modal/add-agent/add-agent-modal.component';
import { EditAgentForm } from 'components/modal/edit-agent/edit-agent-modal.component';
import { APP_CONSTANTS } from 'config/app.config';
import { JsonConvert } from 'json2typescript';
import AgencyAgent from 'model/agency-agent';
import AgencyAgentImp from 'model/agency-agent.imp';
import { replaceAgencyPropertyEndpoint } from 'services/utils.service';

const jsonConvert: JsonConvert = new JsonConvert();

export const getAgencyAgents = (agencyId: string): Promise<AgencyAgent[]> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_AGENTS, agencyId);

	return axios.get(url)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((agent: any) => ({ ...agent, ...agent.attributes })), AgencyAgentImp));
};

export const addAgencyAgent = (agencyId: string, data: AddAgentFormData): Promise<AgencyAgent> => {
	const body = { data: { type: 'Agent', attributes: data }};

	return axios.post(replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_AGENTS, agencyId), body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyAgentImp));
};

export const updateAgencyAgent = (agent: AgencyAgent, agencyId: string, data: EditAgentForm): Promise<AgencyAgent>=> {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_AGENTS, agencyId);
	const body = { data: { type: 'Agent', attributes: { name: data.name, email: data.email }}};

	return axios.put(`${url}/${agent.id}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyAgentImp));
}

export const deleteAgencyAgent = (agent: AgencyAgent, agencyId: string): Promise<void> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_AGENTS, agencyId);

	return axios.delete(`${url}/${agent.id}`);
};

export const createAgencyAddAgent = (agencyId: string, agentName: string, agentEmail: string): Promise<AgencyAgent> => {
	const body = { data: { type: 'Agent', attributes: { name: agentName, email: agentEmail }}};

	return axios.post(replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_AGENTS, agencyId), body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyAgentImp));
};

