/* istanbul ignore file */
import { AgencyActionTypesEnum } from './types';
import MainAction from 'store/model/main.action';
import Agency from 'model/agency';
import AgencyImp from 'model/agency.imp';
import User from 'model/user';
import AgencyCompany from 'model/agency-company';
import AgencyAgent from 'model/agency-agent';

export interface SetAgenciesAction extends MainAction {
	agencies: Agency[];
}

export interface SetCurrentAgencyAction extends MainAction {
	agency: Agency;
}

export interface SetCurrentAgencyUsersAction extends MainAction {
	users: User[];
}

export interface SetCurrentAgencyCompaniesAction extends MainAction {
	companies: AgencyCompany[];
}

export interface SetCurrentAgencyAgentsAction extends MainAction {
	agents: AgencyAgent[];
}

export const SET_AGENCIES_ACTION: SetAgenciesAction = {
	type: AgencyActionTypesEnum.SET_AGENCIES,
	agencies: []
};

export const SET_ALL_AGENCIES_ACTION: SetAgenciesAction = {
	type: AgencyActionTypesEnum.SET_ALL_AGENCIES,
	agencies: []
};

export const SET_CURRENT_AGENCY_ACTION: SetCurrentAgencyAction = {
	type: AgencyActionTypesEnum.SET_CURRENT_AGENCY,
	agency: new AgencyImp()
};

export const SET_CURRENT_AGENCY_USERS_ACTION: SetCurrentAgencyUsersAction = {
	type: AgencyActionTypesEnum.SET_CURRENT_AGENCY_USERS,
	users: []
};

export const SET_CURRENT_AGENCY_COMPANIES_ACTION: SetCurrentAgencyCompaniesAction = {
	type: AgencyActionTypesEnum.SET_CURRENT_AGENCY_COMPANIES,
	companies: []
};

export const SET_ALL_COMPANIES_ACTION: SetCurrentAgencyCompaniesAction = {
	type: AgencyActionTypesEnum.SET_ALL_COMPANIES,
	companies: []
};

export const SET_CURRENT_AGENCY_AGENTS_ACTION: SetCurrentAgencyAgentsAction = {
	type: AgencyActionTypesEnum.SET_CURRENT_AGENCY_AGENTS,
	agents: []
}

export const SET_ALL_AGENTS_ACTION: SetCurrentAgencyAgentsAction = {
	type: AgencyActionTypesEnum.SET_ALL_AGENTS,
	agents: []
}
