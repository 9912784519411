import {replaceAgencyPropertyEndpoint, toBase64} from 'services/utils.service';
import {APP_CONSTANTS} from 'config/app.config';
import axios from 'axios';
import Document from 'model/document';
import {JsonConvert} from 'json2typescript';
import DocumentImp from 'model/document.imp';
import {UploadDocumentFormData} from 'components/modal/upload-document/upload-document-modal.component';

const jsonConvert: JsonConvert = new JsonConvert();

export const loadDocuments = (propertyId: string, agencyId: string): Promise<Document[]> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.DOCUMENTS, agencyId, propertyId);

	return axios.get(endpoint)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((document: any) => ({ ...document, ...document.attributes })), DocumentImp));
};

export const uploadPropertyDocument = async(data: UploadDocumentFormData, propertyId: string, agencyId: string): Promise<Document> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.DOCUMENTS, agencyId, propertyId);
	const body = {
		data: {
			type: 'Document',
			attributes: { name: data.name, file: await toBase64(data.file) }
		}
	};

	return axios.post(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, DocumentImp));
};

export const deletePropertyDocument = (propertyId: string, agencyId: string, documentId: string): Promise<void> => {
	return axios.delete(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.DOCUMENTS, agencyId, propertyId)}/${documentId}`);
};
