import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class NotificationSupplyDeserializer implements JsonCustomConvert<String> {
	deserialize = (attrs: string): string => {
		try {
            return JSON.parse(JSON.parse(attrs).object).supply_type;
		} catch (e) {
			return '';
		}
	};

	serialize = (supply: string): string => supply;
}
