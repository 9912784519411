/* istanbul ignore file */
export enum SupplyStatusEnum {
	UNSUBSCRIBED = 'unsubscribed',
	PROCESSING_UNSUBSCRIPTION = 'processing_unsubscription',
	PROCESSING_SUBSCRIPTION = 'processing_subscription',
	PROCESSING_HOLDER_CHANGE = 'processing_holder_change',
	SUBSCRIBED = 'active',
	NOT_REGISTERED = 'not_registered',
	NOT_APPLY = 'not_apply',
	CANCELLED = 'cancelled',
	INCIDENCE = 'incidence'
}
