/* istanbul ignore file */
import {FilterFieldType} from './filter-field-type.enum';
import FilterField from './filter-field';

export default class FilterFieldImp implements FilterField {
	field: string;
	type: FilterFieldType;

	constructor(field: string = '', type: FilterFieldType = FilterFieldType.STRING) {
		this.field = field;
		this.type = type;
	}
}
