import ImportLog from 'model/import-logs';
import ImportLogDetail from 'model/import-logs-details';
import ImportLogImp from 'model/import-logs.imp';
import MainAction from 'store/model/main.action';
import { ImportLogActionTypesEnum } from './types';

export interface SetImportLogsAction extends MainAction {
    importLogs: ImportLog[];
}

export interface SetCurrentImportLogAction extends MainAction {
    importLog: ImportLog;
}

export interface SetCurrentImportlogDetailAction extends MainAction {
    importLogsDetails: ImportLogDetail[];
}

export const SET_IMPORT_LOGS_ACTION: SetImportLogsAction = {
    type: ImportLogActionTypesEnum.SET_IMPORT_LOGS,
    importLogs:[]
}

export const SET_CURRENT_IMPORT_LOG_ACTION: SetCurrentImportLogAction = {
    type: ImportLogActionTypesEnum.SET_CURRENT_IMPORT_LOG,
    importLog: new ImportLogImp()
}

export const SET_CURRENT_IMPORT_LOG_DETAILS_ACTION: SetCurrentImportlogDetailAction = {
    type: ImportLogActionTypesEnum.SET_CURRENT_IMPORT_LOG_DETAIL,
    importLogsDetails: []
}

