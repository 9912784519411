import React, {FunctionComponent, ReactElement} from 'react';
import './notifications.component.scss';
import Notification from 'model/notification';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import Property from 'model/property';
import {SET_CURRENT_PROPERTY_ACTION, SET_PROPERTIES_ACTION} from 'store/property/actions';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getPropertyDetail} from 'components/properties/components/property-detail/services/property-detail.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import icons from 'sprite/sprite.svg';
import { getSupplyTypeIcon } from 'components/properties/components/property-detail/components/property-supply/services/property-supply.service';

export interface NotificationsComponentProps {
	notifications: Notification[];
	readNotification: Function;
}

const NotificationsComponent: FunctionComponent<NotificationsComponentProps> = ({ notifications, readNotification }): ReactElement => {
	/* istanbul ignore next */
	const { formatDate } = useSelector((state: MainState) => state.language.language);
	/* istanbul ignore next */
	const { currentProperty, properties } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();

	const loadPropertyInfo = (property: Property): void => {
		if (currentProperty.id !== property.id) {
			dispatch(SHOW_LOADING_ACTION);

			getPropertyDetail(property)
				.then((newProperty: Property) => {
					const index = properties.findIndex(p => p.id === property.id);

					/* istanbul ignore else */
					if (index === -1) {
						properties.unshift(newProperty);
						const setPropertiesAction = {...SET_PROPERTIES_ACTION};
						setPropertiesAction.properties = properties;
						dispatch(setPropertiesAction);
					}

					const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
					setCurrentPropertyAction.property = newProperty;
					dispatch(setCurrentPropertyAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('property_load_error'), dispatch))
				.then(() => dispatch(HIDE_LOADING_ACTION));
		}
	};

	return <div className="notifications-component">
		<p className="notifications">
			<FormattedMessage id="notifications"/>
		</p>
		{ notifications.map((notification, index) => {
			return <div key={index} className="notification">
				<div className="notification-info" onClick={() => loadPropertyInfo(notification.property)} data-testid="select-notification">
					<p className="title" title={notification.property.address}>{ notification.property.address }</p>
					<p><FormattedMessage id={`notification_${notification.type}`}/></p>
					{notification.supply && 
						<div className="notification-supply">
							<svg className="icon icon--left icon--12 icon--neutral">
								<use xlinkHref={`${icons}#${getSupplyTypeIcon(notification.supply)}`}/>
							</svg>
							<FormattedMessage id={`${notification.supply}`}/>
						</div>
					}
					<span>{ notification.createdAt.format(formatDate) }</span>
				</div>
				<span onClick={() => !notification.readAt && readNotification(notification)} className={`circle ${!!notification.readAt && 'active'}`} data-testid="read-notification"/>
			</div>;
		}) }
		{ !notifications.length && <p className="no-notifications">
			<FormattedMessage id="no_notifications"/>
		</p> }
	</div>;
};

export default NotificationsComponent;
