/* istanbul ignore file */
import AgencyState from './agency.state';
import Agency from 'model/agency';
import AgencyImp from 'model/agency.imp';
import AgencyCompany from '../../../model/agency-company';
import AgencyAgent from 'model/agency-agent';

export default class AgencyStateImp implements AgencyState {
	agencies: Agency[];
	allAgencies: Agency[];
	currentAgency: Agency;
	allAgencyCompanies: AgencyCompany[];
	allAgencyAgents: AgencyAgent[];

	constructor(agencies: Agency[] = [], allAgencies: Agency[] = [], currentAgency: Agency = new AgencyImp(), allAgencyCompanies: AgencyCompany[] = [],
		allAgencyAgents: AgencyAgent[] = []) {
		this.agencies = agencies;
		this.allAgencies = allAgencies;
		this.currentAgency = currentAgency;
		this.allAgencyCompanies = allAgencyCompanies;
		this.allAgencyAgents = allAgencyAgents;
	}
}
