import React, {FunctionComponent, ReactElement} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import MainState from 'store/model/main.state';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import { SET_CURRENT_PROPERTY_ACTION } from 'store/property/actions';
import Document from 'model/document';
import DragAndDropComponent from 'components/shared/drag-drop/drag-drop.component';
import {uploadPropertyDocument} from 'components/properties/components/property-detail/components/property-documents/services/property-documents.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';

export interface UploadDocumentFormData {
    name: string;
    file: any;
}

const UploadDocumentModalComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    const dispatch = useDispatch();
    const { register, handleSubmit, control, errors } = useForm<UploadDocumentFormData>();

    const uploadNewDocument = (data: UploadDocumentFormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        uploadPropertyDocument(data, currentProperty.id, currentProperty.agencyId)
            .then((newDocument: Document) => {
                currentProperty.documents.push(newDocument);
                
                const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
                setCurrentPropertyAction.property = currentProperty;
                dispatch(setCurrentPropertyAction);
                dispatch(HIDE_MODAL_ACTION);
            })
            .catch(() => showGrowlMessage(new GrowlMessageImp('documents_upload_error'), dispatch))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };
    
	return <div className="upload-document-modal">
        <h3 className="title u-text--secondary"><FormattedMessage id="upload_document"/></h3>
        <form onSubmit={handleSubmit(uploadNewDocument)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="document_name"/></label>
                <input type="text" name="name" className="form-group__item" ref={register({required: 'field_required'})} data-testid="document-name-input"/>
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
            <DragAndDropComponent label="attached" fileType="image/*, .pdf" control={control} errors={errors} />
            <button className="btn btn--block btn--lg btn--secondary" type="submit"><FormattedMessage id="upload"/></button>
        </form>
    </div>;
};

export default UploadDocumentModalComponent;
