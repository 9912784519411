import React, {FunctionComponent, ReactElement} from 'react';
import './spinner.component.scss'
import {useSelector} from 'react-redux';
import MainState from 'store/model/main.state';

const SpinnerComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { loading } = useSelector((state: MainState) => state.loading);

    return <div className={`${loading ? 'spinner-container centered' : 'hidden'}`}>
        <img src={process.env.PUBLIC_URL + '/loading.gif'} alt='Loading' />
    </div>
};

export default SpinnerComponent;
