import {ChangeSupplyStatusForm} from '../change-supply-status-modal.component';
import Property from 'model/property';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import { SupplyTypeEnum } from 'model/supply-type.enum';
import Supply from 'model/supply';
import { JsonConvert } from 'json2typescript';
import SupplyImp from 'model/supply.imp';

const jsonConvert: JsonConvert = new JsonConvert();

export const changeSupplyStatus = (data: ChangeSupplyStatusForm, property: Property, supplyId: string): Promise<void> => {
	const body = { data: { type: 'supply', attributes: { status: data.status.value }}};

	return axios.put(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_INFO, property.agencyId, property.id)}/${supplyId}`, body);
};

export const createSupply = (data: ChangeSupplyStatusForm, property: Property, supplyType: SupplyTypeEnum): Promise<Supply> => {
	const body = { data: { type: 'supply', attributes: { supply_type: supplyType, status: data.status.value }}};

	return axios.post(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_INFO, property.agencyId, property.id)}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, SupplyImp));
};
