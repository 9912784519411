/* istanbul ignore file */
import LanguageOption from './language-option';

export default class LanguageOptionImp implements LanguageOption {
	label: any;
	value: any;
	formatDate: string;
	formatDateTime: string;
	calendarFormatDate: string;
	locale: any;

	constructor(label: any = '', value: any = '', formatDate: string = '', formatDateTime: string = '', calendarFormatDate: string = '', locale: any = {}) {
		this.label = label;
		this.value = value;
		this.formatDate = formatDate;
		this.formatDateTime = formatDateTime;
		this.calendarFormatDate = calendarFormatDate;
		this.locale = locale;
	}
}
