import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './agency-companies.component.scss';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import AgencyCompany from 'model/agency-company';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {SET_CURRENT_AGENCY_COMPANIES_ACTION} from 'store/agency/actions';
import AgencyCompanyComponent from '../agency-company/agency-company.component';
import { UserTypeEnum } from 'model/user-type.enum';
import { openModal } from 'services/utils.service';
import AddCompanyModalComponent from 'components/modal/add-company/add-company-modal.component';
import { getAgencyCompanies } from './services/agency-companies.service';
import ListResults from 'model/list-results';
 
const AgencyCompaniesComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    /* istanbul ignore next */
    const { currentAgency } = useSelector((state: MainState) => state.agency);
    const dispatch = useDispatch();
    const [ page, setPage ] = useState<number>(1);
    const [ totalPages, setTotalPages ] = useState<number>(1);

    useEffect(() => {
        dispatch(SHOW_LOADING_ACTION);

        getAgencyCompanies(currentAgency.id, page)
            .then((listResults: ListResults<AgencyCompany>) => {
                setTotalPages(listResults.totalPages);
                const setCurrentAgencyCompaniesAction = {...SET_CURRENT_AGENCY_COMPANIES_ACTION};
                setCurrentAgencyCompaniesAction.companies = listResults.results;
                dispatch(setCurrentAgencyCompaniesAction);
            })
            .catch(() => showGrowlMessage(new GrowlMessageImp('companies_read_error'), dispatch))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    }, [dispatch, currentAgency.id, page]);

    return <div className="agency-companies">
        <div className="agency-companies__title">
            <h4 className="u-text--secondary"><FormattedMessage id="agency_companies"/></h4>
            { (user.permissions.includes(UserTypeEnum.SELECTRA) || user.permissions.includes(UserTypeEnum.AGENCY_ADMIN)) &&
                <button className="btn btn--secondary btn--sm btn--outline" onClick={() => openModal(<AddCompanyModalComponent/>, dispatch)} data-testid="add-agency-button">
                    <FormattedMessage id="add"/>
                </button>
            }
        </div>
        { currentAgency.companies.length > 0 ? <div className="row agency-companies__wrapper">
            { currentAgency.companies.map((company, index) => <AgencyCompanyComponent key={index} company={company} />) }
            <div className="paginator col-md-12">
                <button className="btn btn--sm btn--secondary" onClick={() => page > 1 && setPage(page - 1)} disabled={page === 1} data-testid="prev-page-button">
                    <FormattedMessage id="previous"/>
                </button>
                <button className="btn btn--sm btn--secondary" onClick={() => page < totalPages && setPage(page + 1)} disabled={page >= totalPages} data-testid="next-page-button">
                    <FormattedMessage id="next"/>
                </button>
            </div>
        </div> : <p><FormattedMessage id="no_companies"/></p> }
    </div>;
};
 
export default AgencyCompaniesComponent;
