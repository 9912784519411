import {EditPasswordForm} from 'components/modal/edit-password/edit-password-modal.component';
import {EditUserForm} from 'components/modal/edit-user/edit-user-modal.component';
import axios from 'axios';
import User from 'model/user';
import {APP_CONSTANTS} from 'config/app.config';
import {AddUserForm} from 'components/modal/add-user/add-user-modal.component';
import UserImp from 'model/user.imp';
import {JsonConvert} from 'json2typescript';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';

const jsonConvert: JsonConvert = new JsonConvert();

export const getAgencyUsers = (agencyId: string): Promise<User[]> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_USERS, agencyId);

	return axios.get(url)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((user: any) => ({ ...user, ...user.attributes })), UserImp));
};

export const updateUserPassword = (user: User, data: EditPasswordForm): Promise<User> => {
	return axios.post(APP_CONSTANTS.CLOUD_FUNCTIONS_ENDPOINTS.CHANGE_PASSWORD, { email: user.email, password: data.password })
		.then(() => user);
};

export const createUser = (agencyId: string, data: AddUserForm): Promise<User> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_USERS, agencyId);
	const body = {
		data: {
			attributes: {
				name: data.name,
				email: data.email,
				role: data.role?.value
			}
		}
	};

	return axios.post(APP_CONSTANTS.CLOUD_FUNCTIONS_ENDPOINTS.CREATE_USER, { email: data.email, password: data.password })
		.then(() => axios.post(url, body))
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, UserImp));
};

export const updateUser = (user: User, agencyId: string, data: EditUserForm): Promise<User> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_USERS, agencyId);
	const body = {
		data: {
			attributes: {
				name: data.name,
				email: data.email,
				role: data.role?.value
			}
		}
	};

	return axios.post(APP_CONSTANTS.CLOUD_FUNCTIONS_ENDPOINTS.CHANGE_EMAIL, { email: user.email, newEmail: data.email })
		.then(() => axios.put(`${url}/${user.id}`, body))
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, UserImp));
};

export const deleteUser = (user: User, agencyId: string): Promise<void> => {
	const url = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_USERS, agencyId);

	return axios.post(APP_CONSTANTS.CLOUD_FUNCTIONS_ENDPOINTS.DELETE_USER, { email: user.email })
		.then(() => axios.delete(`${url}/${user.id}`));
};
