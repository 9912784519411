/* istanbul ignore file */
import LanguageOption from '../model/language-option';
import LanguageOptionImp from '../model/language-option.imp';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

interface LanguageConfig {
	LANGUAGES: LanguageOption[]
}

export const LANGUAGE_CONSTANTS: LanguageConfig = {
	LANGUAGES: [
		new LanguageOptionImp('Español', 'ES', 'DD/MM/YYYY', 'DD/MM/YYYY HH:mm', 'dd/MM/yyyy', es),
		new LanguageOptionImp('Português', 'PT', 'DD/MM/YYYY', 'DD/MM/YYYY HH:mm', 'dd/MM/yyyy', pt)
	]
};
