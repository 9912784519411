import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import {LOG_IN_ACTION, LOG_OUT_ACTION} from 'store/auth/actions';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {IntlProvider} from 'react-intl';
import {i18n} from 'i18n/i18n';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import SpinnerComponent from 'components/shared/spinner/spinner.component';
import {APP_CONSTANTS} from 'config/app.config';
import ModalComponent from 'components/shared/modal/modal.component';
import GrowlComponent from 'components/shared/growl/growl.component';
import LogInComponent from '../log-in/log-in.component';
import PropertiesComponent from '../properties/properties.component';
import ForgotPasswordComponent from '../forgot-password/forgot-password.component';
import RecoverPasswordComponent from '../recover-password/recover-password.component';
import AgenciesComponent from '../agencies/agencies.component';
import HeaderComponent from '../header/header.component';
import {getUserInfo} from 'services/auth.service';
import {RESET_ACTION} from 'store/model/main.action';
import {CHANGE_LANGUAGE_ACTION} from 'store/language/actions';
import {getLocale, setLocale} from 'services/language.service';
import { FIREBASE_CONFIG_ADMIN } from 'config/firebase.config';
import ImportLogsComponent from 'components/import-logs/import-logs.component';

const MainComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { language } = useSelector((state: MainState) => state.language);
	/* istanbul ignore next */
	const { isLogged } = useSelector((state: MainState) => state.auth);
	const dispatch = useDispatch();
	const [ dataLoaded, setDataLoaded ] = useState<boolean>(false);

	useEffect(() => {
		const appAdmin = app.initializeApp(FIREBASE_CONFIG_ADMIN, 'admin');
		appAdmin.auth().onAuthStateChanged(user => {
			dispatch(SHOW_LOADING_ACTION);

			if (user && user.email) {
				getUserInfo(user.email)
					.then(user => {
						const logInAction = {...LOG_IN_ACTION}, changeLanguageAction = {...CHANGE_LANGUAGE_ACTION};
						logInAction.user = user;
						changeLanguageAction.language = getLocale(user.locale);
						dispatch(logInAction);
						dispatch(changeLanguageAction);
						setLocale(changeLanguageAction.language);
					})
					.catch(() => appAdmin.auth().signOut())
					.finally(() => {
						setDataLoaded(true);
						dispatch(HIDE_LOADING_ACTION);
					});
			} else {
				setDataLoaded(true);
				dispatch(RESET_ACTION);
				dispatch(LOG_OUT_ACTION);
				dispatch(HIDE_LOADING_ACTION);
			}
		});
	}, [dispatch]);

	return dataLoaded ? <IntlProvider locale={language.value} messages={Object.assign(i18n)[language.value]} onError={() => {}}>
		<BrowserRouter data-testid="router">
			<SpinnerComponent/>
			<ModalComponent/>
			<GrowlComponent/>
			{ isLogged && <HeaderComponent/> }
			<Switch>
				<Route path={APP_CONSTANTS.ROUTES.LOGIN} render={/* istanbul ignore next */ () => (!isLogged ? <LogInComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.PROPERTIES}/>)}/>
				<Route path={APP_CONSTANTS.ROUTES.FORGOT_PASSWORD} render={/* istanbul ignore next */ () => (!isLogged ? <ForgotPasswordComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.PROPERTIES}/>)}/>
				<Route path={APP_CONSTANTS.ROUTES.RECOVER_PASSWORD} render={/* istanbul ignore next */ () => (!isLogged ? <RecoverPasswordComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.PROPERTIES}/>)}/>
				<Route path={APP_CONSTANTS.ROUTES.PROPERTIES} render={/* istanbul ignore next */ () => (isLogged ? <PropertiesComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.LOGIN}/>)}/>
				<Route path={APP_CONSTANTS.ROUTES.AGENCIES} render={/* istanbul ignore next */ () => (isLogged ? <AgenciesComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.LOGIN}/>)}/>
				<Route path={APP_CONSTANTS.ROUTES.IMPORT_LOGS} render={/* istanbul ignore next */ () => (isLogged ? <ImportLogsComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.LOGIN}/>)}/>
				<Redirect to={APP_CONSTANTS.ROUTES.PROPERTIES}/>
			</Switch>
		</BrowserRouter>
	</IntlProvider> : <SpinnerComponent/>;
};

export default MainComponent;
