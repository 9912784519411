import React, {FunctionComponent, ReactElement, useState} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import './log-in.component.scss';
// import { Link } from 'react-router-dom';
// import { APP_CONSTANTS } from 'config/app.config';
import icons from 'sprite/sprite.svg';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  SHOW_LOADING_ACTION,
  HIDE_LOADING_ACTION
} from 'store/loading/actions';
import { useDispatch } from 'react-redux';
import { FORM_CONSTANTS } from 'config/form.config';
import queryString from 'query-string';
import { FIREBASE_CONFIG_ADMIN } from 'config/firebase.config';

interface FormData {
	email: string,
	password: string,
}

const LogInComponent: FunctionComponent = (): ReactElement => {
	const [ passwordShown, setPasswordShown ] = useState<boolean>(false);
	const { error, success } = queryString.parse(window.location.search) as Record<string, string | undefined>;
	const [ errorMsg, setErrorMsg ] = useState<string>(error ? String(error) : '');
	const { register, handleSubmit, errors } = useForm<FormData>();
	const dispatch = useDispatch();
	const appAdmin = app.initializeApp(FIREBASE_CONFIG_ADMIN, 'admin');
	const provider = new app.auth.GoogleAuthProvider();
	provider.setCustomParameters({ prompt: 'select_account' });

	const logInSubmit = (form: FormData): void => {
        dispatch(SHOW_LOADING_ACTION);

		appAdmin.auth().signInWithEmailAndPassword(form.email, form.password)
            .catch(err => setErrorMsg(err.code))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	const logInSubmitGoogle = (): void => {
        dispatch(SHOW_LOADING_ACTION);

		appAdmin.auth().signInWithPopup(provider)
            .catch(err => setErrorMsg(err.code))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="log-in">
		<div className="container">
			<div className="row">
				<div className="col-xs-12 img-responsive log-in__logo">
					<img src={process.env.PUBLIC_URL + '/selectra.svg'} alt='selectra'/>
				</div>
			</div>
			<div className="row row--center">
				<div className="card log-in__card">
					<h1 className="card__title u-text--secondary log-in__title"><FormattedMessage id="log_in"/></h1>
					<form className="log-in__form" onSubmit={handleSubmit(logInSubmit)} data-testid="form">
						<div className={`form-group log-in__email-input  ${errors.email ? 'form-group--has-danger' : ''}`}>
							<label className="form-group__label"><FormattedMessage id="email"/></label>
							<input className="form-group__item"
							   	type='text'
							   	name='email'
							   	ref={register({ required: 'field_required',
								pattern: {
									value: FORM_CONSTANTS.REGEX.EMAIL,
									message: 'invalid_email'
								}})}
							   data-testid="email-input"
							/>
							{errors.email && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.email.message}/></small>}
						</div>
						<div className="form-group log-in__password-input">
							<label className="form-group__label"><FormattedMessage id="password"/></label>
							<div className="form-group__item-addon">
								<input className="form-group__item"
									   type={passwordShown ? 'text' : 'password'}
									   name='password'
									   ref={register({ minLength: { message: 'min_length_6', value: 6 }, required: true})}
									   data-testid="password-input"
								/>
								<span className="form-group__addon" title="Show/Hide" onClick={() => setPasswordShown(!passwordShown)} data-testid="password-toggle">
									<svg className="icon icon--24 icon--gray clickable">
										<use xlinkHref={`${icons}#icon-eye`}/>
									</svg>
								</span>
							</div>
							{errors.password && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id="invalid_password"/></small>}
						</div>
						{ /*
						<Link to={APP_CONSTANTS.ROUTES.FORGOT_PASSWORD}><p><FormattedMessage id="forgot_password_question"/></p></Link>
						*/ }
						<button type="submit" className="btn btn--block btn--lg btn--secondary"><FormattedMessage id="access"/></button>
					</form>
					<br/>
					<button className="btn btn--block btn--lg btn--white signin-google__btn" onClick={logInSubmitGoogle} data-testid="signin-google"><img src="/GoogleLogo.png" alt="Sign in with Google"/><FormattedMessage id="access_with_google"/></button>
				</div>
			</div>
			{ errorMsg && <div className="row row--center">
				<div className={`col-lg-4 card alerts forgot-password__alert alerts__${success === 'true' ? 'success' : 'danger'}`}>
					<svg className={`icon icon--24 icon--${success === 'true' ? 'success' : 'danger'} icon--left`}>
						<use xlinkHref={`${icons}#icon-circle-${success === 'true' ? 'check' : 'cross'}-mark-filled`}/>
					</svg>
					<FormattedMessage id={errorMsg} />
				</div>
			</div> }
		</div>
	</div>;
};

export default LogInComponent;
