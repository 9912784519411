import AgencyState from './model/agency.state';
import AgencyStateImp from './model/agency.state.imp';
import MainAction from 'store/model/main.action';
import { AgencyActionTypesEnum } from './types';
import {
	SetCurrentAgencyAction,
	SetAgenciesAction,
	SetCurrentAgencyUsersAction,
	SetCurrentAgencyCompaniesAction,
	SetCurrentAgencyAgentsAction
} from './actions';
import {ReduxTypesEnum} from '../model/types';

const initialState: AgencyState = new AgencyStateImp();

const agencyReducer = (state = initialState, action: MainAction) => {
	switch (action.type) {
		case ReduxTypesEnum.RESET: {
			return new AgencyStateImp();
		}
		case AgencyActionTypesEnum.SET_AGENCIES: {
			return Object.assign({}, state, {
				agencies: (action as SetAgenciesAction).agencies
			});
        }
		case AgencyActionTypesEnum.SET_ALL_AGENCIES: {
			return Object.assign({}, state, {
				allAgencies: (action as SetAgenciesAction).agencies
			});
        }
		case AgencyActionTypesEnum.SET_CURRENT_AGENCY: {
			const newAgency = (action as SetCurrentAgencyAction).agency;
			const agencies = [...state.agencies];
			const index = agencies.findIndex(p => p.id === newAgency.id);

			// istanbul ignore else
			if (index > -1) {
				agencies[index] = newAgency;
			}

			return Object.assign({}, state, {
				currentAgency: newAgency,
				agencies
			});
        }
		case AgencyActionTypesEnum.SET_CURRENT_AGENCY_USERS: {
			const currentAgency = state.currentAgency;
			currentAgency.users = (action as SetCurrentAgencyUsersAction).users;

			return Object.assign({}, state, {
				currentAgency
			});
		}
		case AgencyActionTypesEnum.SET_CURRENT_AGENCY_COMPANIES: {
			const currentAgency = state.currentAgency;
			currentAgency.companies = (action as SetCurrentAgencyCompaniesAction).companies;

			return Object.assign({}, state, {
				currentAgency
			});
		}
		case AgencyActionTypesEnum.SET_ALL_COMPANIES: {
			return Object.assign({}, state, {
				allAgencyCompanies: (action as SetCurrentAgencyCompaniesAction).companies
			});
		}
		case AgencyActionTypesEnum.SET_CURRENT_AGENCY_AGENTS: {
			const currentAgency = state.currentAgency;
			currentAgency.agents = (action as SetCurrentAgencyAgentsAction).agents;

			return Object.assign({}, state, {
				currentAgency
			});
		}
        default:
			return state;
	}
};

export default agencyReducer;
