/* istanbul ignore file */
import moment, {Moment} from 'moment';
import Message from './message';
import {MessageTypeEnum} from './message-type.enum';
import {JsonObject, JsonProperty} from 'json2typescript';
import {MomentDeserializer} from './deserializers/moment.deserializer';
import {SupplyTypeEnum} from './supply-type.enum';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';
import {MessageActionEnum} from './message-action.enum';

@JsonObject('MessageImp')
export default class MessageImp implements Message {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('description', String, true)
	description: string;

	@JsonProperty('user_id', NumberToStringDeserializer, true)
	senderId: string;

	@JsonProperty('user_name', String, true)
	senderName: string;

	@JsonProperty('created_at', MomentDeserializer, true)
	sent: Moment;

	@JsonProperty('name', String, true)
	messageType: MessageTypeEnum;

	@JsonProperty('supply_type', String, true)
	supplyType: SupplyTypeEnum;

	@JsonProperty('action', String, true)
	action: MessageActionEnum;

	constructor(id: string = '', description: string = '', senderId: string = '', senderName: string = '', sent: moment.Moment = moment(),
				messageType: MessageTypeEnum = MessageTypeEnum.COMMENT, supplyType: SupplyTypeEnum = SupplyTypeEnum.POWER,
				action: MessageActionEnum = MessageActionEnum.CREATE) {
		this.id = id;
		this.description = description;
		this.senderId = senderId;
		this.senderName = senderName;
		this.sent = sent;
		this.messageType = messageType;
		this.supplyType = supplyType;
		this.action = action;
	}
}
