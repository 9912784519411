import { addAgencyAgent } from 'components/agencies/components/agency-agents/services/agency-agents.service';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import { FORM_CONSTANTS } from 'config/form.config';
import AgencyAgent from 'model/agency-agent';
import GrowlMessageImp from 'model/growl-message.imp';
import React, { FunctionComponent, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_AGENCY_ACTION } from 'store/agency/actions';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import MainState from 'store/model/main.state';

export interface AddAgentFormData {
	name: string;
	email: string;
}

const AddAgentModalComponent: FunctionComponent = (): ReactElement => {
    const { currentAgency } = useSelector((state: MainState) => state.agency);
    const {register, handleSubmit, errors} = useForm<AddAgentFormData>();
    const dispatch = useDispatch();

    const addAgent = (form: AddAgentFormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        addAgencyAgent(currentAgency.id, form)
        .then((newAgent: AgencyAgent) => {
            currentAgency.agents.push(newAgent);
            const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
            setCurrentAgencyAction.agency = currentAgency;
            dispatch(setCurrentAgencyAction);
            dispatch(HIDE_MODAL_ACTION);
            showGrowlMessage(new GrowlMessageImp('agent_created', true), dispatch);
        })
        .catch((err) => {
            if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_name') {
                showGrowlMessage(new GrowlMessageImp('agent_name_duplicated_error'), dispatch);
            } else if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_email') {
                showGrowlMessage(new GrowlMessageImp('agent_mail_duplicated_error'), dispatch);
            } else if (err.response.data.name && err.response.data.name[0] === 'duplicated_agent_name_or_email') {
                showGrowlMessage(new GrowlMessageImp('agent_name_or_email_duplicated_error'), dispatch);
            }
            else {
                showGrowlMessage(new GrowlMessageImp('agent_edited_error'), dispatch);
            }
        })
        .finally(() => dispatch(HIDE_LOADING_ACTION));
    }

    return <div>
        <h3 className="title u-text--secondary"><FormattedMessage id="add_agent"/></h3>
        <form onSubmit={handleSubmit(addAgent)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="agency_agent_name"/></label>
                <input type="text" name="name" className="form-group__item" ref={register({ 
                    required: 'field_required' 
                    })} data-testid="agent-name-input" />
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
            <div className={`form-group ${errors.email ? 'form-group--has-danger' : ''}`}>
				<label className="form-group__label"><FormattedMessage id="email"/></label>
				<input type="email" name="email" className="form-group__item" data-testid="email-input" ref={register({
					required: 'field_required',
					pattern: {
						value: FORM_CONSTANTS.REGEX.EMAIL,
						message: 'invalid_email'
					}
				})}/>
				{ errors.email && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.email.message}/></small> }
			</div>
            <button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="add"/>
			</button>
        </form>
    </div>
}

export default AddAgentModalComponent;
