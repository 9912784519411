import { JsonObject, JsonProperty } from 'json2typescript';
import moment from 'moment';
import { Moment } from 'moment';
import BorganFile from './borgan-file';
import BorganFileImp from './borgan-file.imp';
import { MomentDeserializer } from './deserializers/moment.deserializer';
import { NumberToStringDeserializer } from './deserializers/number-to-string.deserializer';
import ImportLog from './import-logs';
import ImportLogDetail from './import-logs-details';
import ImportLogDetailImp from './import-logs-details.imp';
import ImportLogResult from './import-logs-result';
import ImportLogResultImp from './import-logs-result.imp';

@JsonObject ('ImportLogsImp') 
export default class ImportLogImp implements ImportLog {

    @JsonProperty ('id', NumberToStringDeserializer, true)
    id: string;

    @JsonProperty ('created_at', MomentDeserializer, true)
    createdAt: Moment;

    @JsonProperty ('file', BorganFileImp, true)
    file: BorganFile;

    @JsonProperty ('import_logs', [ImportLogDetailImp], true)
    importLogsDetails: ImportLogDetail[];

    @JsonProperty ('result', ImportLogResultImp, true)
    result: ImportLogResult;

    constructor (id: string = '', createdAt: Moment = moment(), file: BorganFile = new BorganFileImp(), importLogsDetails: ImportLogDetail[] = [], result: ImportLogResult = new ImportLogResultImp()) {
        this.id = id;
        this.createdAt = createdAt;
        this.file = file;
        this.importLogsDetails = importLogsDetails;
        this.result = result;
    }
}
