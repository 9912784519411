import PropertyState from './model/property.state';
import PropertyStateImp from './model/property.state.imp';
import MainAction from 'store/model/main.action';
import { PropertyActionTypesEnum } from './types';
import {
	AddSupplyInvoiceAction,
	SetCurrentPropertyAction,
	SetCurrentPropertyConversationAction,
	SetCurrentPropertyDocumentsAction,
	SetPropertiesAction, SetSupplyInvoicesAction
} from './actions';
import {ReduxTypesEnum} from '../model/types';

const initialState: PropertyState = new PropertyStateImp();

const propertyReducer = (state = initialState, action: MainAction) => {
	switch (action.type) {
		case ReduxTypesEnum.RESET: {
			return new PropertyStateImp();
		}
		case PropertyActionTypesEnum.SET_PROPERTIES: {
			return Object.assign({}, state, {
				properties: (action as SetPropertiesAction).properties
			});
        }
		case PropertyActionTypesEnum.SET_CURRENT_PROPERTY: {
			const newProperty = (action as SetCurrentPropertyAction).property;
			const properties = [...state.properties];
			const index = properties.findIndex(p => p.id === newProperty.id);

			// istanbul ignore else
			if (index > -1) {
				properties[index] = newProperty;
			}

			return Object.assign({}, state, {
				currentProperty: newProperty,
				properties
			});
        }
		case PropertyActionTypesEnum.SET_CURRENT_PROPERTY_DOCUMENTS: {
			const currentProperty = state.currentProperty;
			currentProperty.documents = (action as SetCurrentPropertyDocumentsAction).documents;

			return Object.assign({}, state, {
				currentProperty
			});
        }
		case PropertyActionTypesEnum.SET_CURRENT_PROPERTY_CONVERSATION: {
			const currentProperty = state.currentProperty;
			currentProperty.conversation = (action as SetCurrentPropertyConversationAction).conversation;

			return Object.assign({}, state, {
				currentProperty
			});
        }
		case PropertyActionTypesEnum.SET_SUPPLY_INVOICES: {
			const currentProperty = state.currentProperty;
			const index = currentProperty.supplies.findIndex(s => s.id === (action as SetSupplyInvoicesAction).supplyId);

			// istanbul ignore else
			if (index > -1) {
				currentProperty.supplies[index].invoices = (action as SetSupplyInvoicesAction).invoices;
				currentProperty.supplies[index].invoicesAmount = (action as SetSupplyInvoicesAction).invoices.length;
			}

			return Object.assign({}, state, {
				currentProperty
			});
        }
		case PropertyActionTypesEnum.ADD_SUPPLY_INVOICE: {
			const currentProperty = state.currentProperty;
			const index = currentProperty.supplies.findIndex(s => s.id === (action as SetSupplyInvoicesAction).supplyId);

			// istanbul ignore else
			if (index > -1) {
				currentProperty.supplies[index].invoices.push((action as AddSupplyInvoiceAction).invoice);
				currentProperty.supplies[index].invoicesAmount = currentProperty.supplies[index].invoices.length;
			}

			return Object.assign({}, state, {
				currentProperty
			});
        }
        default:
			return state;
	}
};

export default propertyReducer;
