import Property from 'model/property';
import {JsonConvert} from 'json2typescript';
import PropertyImp from 'model/property.imp';
import {SET_CURRENT_PROPERTY_ACTION, SET_PROPERTIES_ACTION} from 'store/property/actions';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import ListResults from 'model/list-results';
import ListResultsImp from 'model/list-results.imp';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getPropertyDetail} from '../../property-detail/services/property-detail.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';

const jsonConvert: JsonConvert = new JsonConvert();

export const readProperties = (page: number, filters: string): Promise<ListResults<Property>> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.PROPERTIES}?page=${page}${filters}`)
		.then(res => new ListResultsImp(res.data.meta.total_pages, res.data.meta.total_properties, jsonConvert.deserializeArray(res.data.data.map((property: any) => ({ ...property, ...property.attributes })), PropertyImp)));
};

export const getExportDocument = (exportPropertyList: string, exportAll: boolean): Promise<string> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.AGENCY_EXPORT_PROPERTIES}?export=true${exportAll && exportPropertyList !== '' ? exportPropertyList : exportAll && exportPropertyList === '' ? '&filter[all]=true' : '&filter[ids]=' + exportPropertyList}`)
		.then(res => res.data);
};

export const setPropertiesList = (dispatch: Function, properties: Property[]): void => {
	const setPropertiesAction = {...SET_PROPERTIES_ACTION};
	setPropertiesAction.properties = properties;
	dispatch(setPropertiesAction);
};

export const loadProperty = (dispatch: Function, property: Property): void => {
	dispatch(SHOW_LOADING_ACTION);

	getPropertyDetail(property)
		.then((newProperty: Property) => selectCurrentProperty(dispatch, newProperty))
		.catch(() => showGrowlMessage(new GrowlMessageImp('property_load_error'), dispatch))
		.then(() => dispatch(HIDE_LOADING_ACTION));
};

export const selectCurrentProperty = (dispatch: Function, property: Property): void => {
	const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
	setCurrentPropertyAction.property = property;
	dispatch(setCurrentPropertyAction);
};
