/* istanbul ignore file */
export enum SupplyStatusChangeEnum {
	SUBSCRIBE = 'subscribe',
	DEFINITIVE_UNSUBSCRIBE = 'definitive_unsubscribe',
	TEMPORARY_UNSUBSCRIBE = 'temporary_unsubscribe',
	HOLDER_TO_TENANT = 'holder_to_tenant',
	HOLDER_TO_PROPERTY = 'holder_to_property',
	POWER_INCREASE = 'power_increase',
	POWER_DECREASE = 'power_decrease',
}
