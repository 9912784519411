import { JsonObject, JsonProperty } from 'json2typescript';
import { NumberToStringDeserializer } from './deserializers/number-to-string.deserializer';
import ImportLogDetail from './import-logs-details';

@JsonObject('ImportLogDetailImp')
export default class ImportLogDetailImp implements ImportLogDetail {

    @JsonProperty ('id', NumberToStringDeserializer, true)
    id: string;

    @JsonProperty ('created_at', String, true)
    createdAt: string;

    @JsonProperty ('import_id', NumberToStringDeserializer, true)
    importId: string;

    @JsonProperty ('object_id', NumberToStringDeserializer, true)
    objectId: string;

    @JsonProperty ('object_type', String, true)
    objectType: string;

    @JsonProperty ('sucess', Boolean, true)
    success: boolean;

    @JsonProperty ('params', String, true)
    params: string;

    @JsonProperty ('error', String, true)
    error: string;

    constructor(id: string = '', createdAt: string = '', importId: string = '', objectId: string = '', objectType: string = '',
    success: boolean = false, params: string = '', error: string = '') {
        this.id = id;
        this.createdAt = createdAt;
        this.importId = importId;
        this.objectId = objectId;
        this.objectType = objectType;
        this.success = success;
        this.params = params;
        this.error = error;
    }
}
