/* istanbul ignore file */
import Document from './document';
import {JsonObject, JsonProperty} from 'json2typescript';
import BorganFile from './borgan-file';
import BorganFileImp from './borgan-file.imp';

@JsonObject('DocumentImp')
export default class DocumentImp implements Document {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('file', BorganFileImp, true)
	file: BorganFile;

	constructor(id: string = '', name: string = '', file: BorganFile = new BorganFileImp()) {
		this.id = id;
		this.name = name;
		this.file = file;
	}
}
