import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class NotificationTypeDeserializer implements JsonCustomConvert<string> {
	deserialize = (attrs: string): string => {
		try {
			return String(JSON.parse(attrs).type).toLowerCase();
		} catch (e) {
			return '';
		}
	};

	serialize = (type: string): string => type;
}
