import {MessageTypeEnum} from 'model/message-type.enum';
import Message from 'model/message';
import {JsonConvert} from 'json2typescript';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';
import Property from 'model/property';
import MessageImp from 'model/message.imp';
import ListResults from 'model/list-results';
import ListResultsImp from 'model/list-results.imp';
import {FORM_CONSTANTS} from 'config/form.config';
import moment from 'moment';

const jsonConvert: JsonConvert = new JsonConvert();

export const getMessageTypeIcon = (type: MessageTypeEnum): string => {
	switch (type) {
		case MessageTypeEnum.CREATE_SUPPLY:
			return 'icon-plus';
		case MessageTypeEnum.SUPPLY:
			return 'icon-arrows-bottom-top';
		case MessageTypeEnum.UPDATE_SUPPLY:
		case MessageTypeEnum.UPDATE_PROPERTY:
			return 'icon-arrows-bottom-top';
		case MessageTypeEnum.REQUEST:
			return 'icon-interrogation';
		case MessageTypeEnum.DOCUMENT:
		case MessageTypeEnum.INVOICE:
			return 'icon-file-outlined';
		case MessageTypeEnum.INCIDENCE:
			return 'icon-triangle-warning';
		case MessageTypeEnum.COMMENT:
			return 'icon-speech-bubble-dots';
	}
};

export const loadConversation = (propertyId: string, agencyId: string, page: number): Promise<ListResults<Message>> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.CONVERSATION, agencyId, propertyId);

	return axios.get(`${endpoint}?page=${page}`)
		.then(res => new ListResultsImp(res.data.meta.total_pages, res.data.meta.total_properties, jsonConvert.deserializeArray(res.data.data.map((message: any) => ({ ...message, ...message.attributes })), MessageImp)));
};

export const sendMessage = (description: string, property: Property): Promise<Message> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.CONVERSATION, property.agencyId, property.id);
	const body = { data: { type: 'comment', attributes: { description }}};

	return axios.post(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, MessageImp));
};

export const parseDescriptionData = (description: string, formatDate: string): Record<string, [string, string]> => {
	try {
		const obj = JSON.parse(description);

		Object.keys(obj).forEach(key => {
			obj[key][0] = FORM_CONSTANTS.REGEX.ISO_DATE.test(obj[key][0]) ? moment(obj[key][0]).format(formatDate) : obj[key][0];
			obj[key][1] = FORM_CONSTANTS.REGEX.ISO_DATE.test(obj[key][1]) ? moment(obj[key][1]).format(formatDate) : obj[key][1];
		});

		delete obj.id;
		delete obj.action_type;
		delete obj.solved;
		delete obj.user_id;
		delete obj.supply_id;
		delete obj.agency_id;
		return obj;
	} catch (e) {
		return {};
	}
};
