/* istanbul ignore file */
export enum AgencyActionTypesEnum {
    SET_AGENCIES = 'agency/set-agencies',
    SET_ALL_AGENCIES = 'agency/set-all-agencies',
    SET_CURRENT_AGENCY = 'agency/set-current',
    SET_CURRENT_AGENCY_USERS = 'agency/set-current-users',
    SET_CURRENT_AGENCY_COMPANIES = 'agency/set-current-companies',
    SET_ALL_COMPANIES = 'agency/set-all-companies',
    SET_CURRENT_AGENCY_AGENTS = 'agency/set-current-agents',
    SET_ALL_AGENTS = 'agency/set-all-agents'
}
