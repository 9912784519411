import React, {FunctionComponent, ReactElement} from 'react';
import './property-title.component.scss';
import icons from 'sprite/sprite.svg';
import { useDispatch, useSelector } from 'react-redux';
import EditTitlePropertyModalComponent from 'components/modal/edit-title-property/edit-title-property-modal.component';
import MainState from 'store/model/main.state';
import { UserTypeEnum } from 'model/user-type.enum';
import {openModal} from 'services/utils.service';
import DeleteModalComponent from 'components/modal/delete/delete-modal.component';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {deletePropertyStreet} from '../../services/property-detail.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import PropertyImp from 'model/property.imp';
import {selectCurrentProperty, setPropertiesList} from '../../../properties-list/services/properties-list.service';
import { FormattedMessage } from 'react-intl';

const PropertyTitleComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { currentProperty, properties } = useSelector((state: MainState) => state.property);
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	const dispatch = useDispatch();

	const onDeleteProperty = (): void => {
		dispatch(SHOW_LOADING_ACTION);

		deletePropertyStreet(currentProperty)
			.then(() => {
				const index = properties.findIndex(p => p.id === currentProperty.id);
				properties.splice(index, 1);

				selectCurrentProperty(dispatch, properties[0] || new PropertyImp());
				setPropertiesList(dispatch, properties);
				dispatch(HIDE_MODAL_ACTION);
				showGrowlMessage(new GrowlMessageImp('property_deleted', true), dispatch)
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('property_delete_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="property-title-component">
		<h3 className="property-title-component__title">{ currentProperty.address }</h3>
		<div>
			{ user.permissions.includes(UserTypeEnum.SELECTRA) &&
				<p className="btn btn--white btn--md" data-testid="edit-button" onClick={() => openModal(<EditTitlePropertyModalComponent/>, dispatch)}>
					<FormattedMessage id="edit_property" />
				</p>
			}
			{ user.permissions.includes(UserTypeEnum.DELETE_PROPERTY) &&
				<svg className="icon icon--24 icon--neutral icon--right property-title-component__icon" data-testid="delete-button" onClick={() => openModal(<DeleteModalComponent label="property_name" title="delete_property" onDelete={onDeleteProperty} repeatLabel="repeat_property_name" fieldValue={currentProperty.address}/>, dispatch)}>
					<use xlinkHref={`${icons}#icon-trash`}/>
				</svg>
			}
		</div>
	</div>;
};

export default PropertyTitleComponent;
