import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import Document from 'model/document';
import { FormattedMessage } from 'react-intl';
import './property-documents.component.scss';
import {openModal} from 'services/utils.service';
import icons from 'sprite/sprite.svg';
import { UserTypeEnum } from 'model/user-type.enum';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import UploadDocumentModalComponent from 'components/modal/upload-document/upload-document-modal.component';
import ModalConfirmationComponent from 'components/modal/confirmation/confirmation-modal.component';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import {
    SET_CURRENT_PROPERTY_ACTION,
    SET_CURRENT_PROPERTY_DOCUMENTS_ACTION
} from 'store/property/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {deletePropertyDocument, loadDocuments} from './services/property-documents.service';

const PropertyDocumentsComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState<boolean>(false);

    useEffect(() => {
        if (currentProperty.id && currentProperty.address) {
            setLoading(true);

            loadDocuments(currentProperty.id, currentProperty.agencyId)
                .then((documents: Document[]) => {
                    const setCurrentPropertyDocumentsAction = {...SET_CURRENT_PROPERTY_DOCUMENTS_ACTION};
                    setCurrentPropertyDocumentsAction.documents = documents;
                    dispatch(setCurrentPropertyDocumentsAction);
                })
                .catch(() => showGrowlMessage(new GrowlMessageImp('documents_read_error'), dispatch))
                .finally(() => setLoading(false));
        }
    }, [currentProperty.agencyId, currentProperty.id, currentProperty.address, dispatch]);

    const deleteDocument = (index: number): void => {
        openModal(<ModalConfirmationComponent
            title={<FormattedMessage id="remove_document_confirmation"/>}
            onConfirm={() => {
                dispatch(SHOW_LOADING_ACTION);

                deletePropertyDocument(currentProperty.id, currentProperty.agencyId, currentProperty.documents[index].id)
                    .then(() => {
                        currentProperty.documents.splice(index, 1);

                        const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
                        setCurrentPropertyAction.property = currentProperty;
                        dispatch(setCurrentPropertyAction);
                        showGrowlMessage(new GrowlMessageImp('documents_deleted', true), dispatch)
                    })
                    .catch(() => showGrowlMessage(new GrowlMessageImp('documents_delete_error'), dispatch))
                    .finally(() => dispatch(HIDE_LOADING_ACTION));
            }}
        />, dispatch);
    };

    return <div className="property-documents-component">
        <h4 className="u-text--secondary property-documents-component__title"><FormattedMessage id="documents"/></h4>
        <div className="property-documents-component__wrapper">
            { loading && <FormattedMessage id="loading_documents"/> }
            { currentProperty.documents.length ? currentProperty.documents.map((document: Document, index: number) => {
                return <div className="property-documents-component__wrapper-item" key={index}>
                            <p className="u-text--secondary">{ document.name }</p>
                            <div className="property-documents-component__wrapper-item__doc">
                                <svg className="icon icon--16 icon--left icon--neutral">
                                    <use xlinkHref={`${icons}#icon-file-outlined`}/>
                                </svg>
                                <a target="_blank" rel="noopener noreferrer" href={document.file.url}>
                                    { document.name }
                                </a>
                                { user.permissions.includes(UserTypeEnum.SELECTRA) &&
                                    <svg className="icon icon--16 icon--right delete_icon" onClick={() => deleteDocument(index)} data-testid="delete-document">
                                        <use xlinkHref={`${icons}#icon-trash`}/>
                                    </svg>
                                }
                            </div>
                        </div>
            }) : <p className="no-documents">
                <FormattedMessage id="no_documents"/>
            </p> }
            <div className="property-documents-component__wrapper__btn">
                <button className="btn btn--sm btn--secondary" onClick={() => openModal(<UploadDocumentModalComponent/>, dispatch)} data-testid="upload-document">
                    <FormattedMessage id="upload_document"/>
                </button>
            </div>
        </div>
    </div>;
};
 
export default PropertyDocumentsComponent;
