/* istanbul ignore file */
import {SupplyTypeEnum} from 'model/supply-type.enum';

interface PropertyDetailConfig {
	SUPPLIES: SupplyTypeEnum[];
	OWNER_EDIT_FIELDS: [string, string][];
}

export const PROPERTY_DETAIL_CONSTANTS: PropertyDetailConfig = {
	SUPPLIES: [
		SupplyTypeEnum.POWER,
		SupplyTypeEnum.GAS,
		SupplyTypeEnum.WATER,
		SupplyTypeEnum.ALARMS,
		SupplyTypeEnum.TELECOM
	],
	OWNER_EDIT_FIELDS: [
		['company_name', 'marketer'],
		['owner_phone', 'phone'],
		['owner_identification', 'newHolderNationalId'],
		['owner_email', 'email'],
		['owner_iban', 'bank_account'],
		['contract_number', 'contract_number']
	]
};
