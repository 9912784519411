/* istanbul ignore file */

interface FormConfig {
    REGEX: {
        EMAIL: RegExp;
        PHONE: {
            SPANISH: RegExp;
            PORTUGUESE: RegExp;
        };
        NATIONAL_IDS: {
            SPANISH: {
                DNI_REG_EXP: RegExp;
                CIF_REG_EXP: RegExp;
                NIE_REG_EXP: RegExp;
                PASSPORT_REG_EXP: RegExp;
            };
        };
        ZIP_CODE: RegExp;
        ISO_DATE: RegExp;
    };
}

export const FORM_CONSTANTS: FormConfig = {
    REGEX: {
        EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        PHONE: {
            SPANISH: /^(34|\+34|0034)?[\s|\-|.]?[6-9][\s|\-|.]?([0-9][\s|\-|.]?){8}$/,
            PORTUGUESE: /^(\+351|00351|351)?[\s|\-|.]?[2,9][\s|\-|.]?([0-9][\s|\-|.]?){8}$/
        },
        NATIONAL_IDS: {
            SPANISH: {
                DNI_REG_EXP: /^(\d{8})([A-Z])$/,
                CIF_REG_EXP: /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/,
                NIE_REG_EXP: /^[XYZ]\d{7,8}[A-Z]$/,
                PASSPORT_REG_EXP: /^[a-z]{3}[0-9]{6}[a-z]?$/i
            }
        },
        ZIP_CODE: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
        ISO_DATE: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/
    }
};
