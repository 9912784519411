import MainAction from 'store/model/main.action';
import { ReduxTypesEnum } from 'store/model/types';
import { SetCurrentImportLogAction, SetCurrentImportlogDetailAction, SetImportLogsAction } from './actions';
import ImportLogState from './model/import-log.state';
import ImportLogStateImp from './model/import-log.state.imp';
import { ImportLogActionTypesEnum } from './types';

const initialState: ImportLogState = new ImportLogStateImp();

const importLogReducer = (state = initialState, action: MainAction) => {
    switch (action.type) {
        case ReduxTypesEnum.RESET: {
            return new ImportLogStateImp();
        }
        case ImportLogActionTypesEnum.SET_IMPORT_LOGS: {
            return Object.assign({}, state, {
                importLogs: (action as SetImportLogsAction).importLogs
            });
        }
        case ImportLogActionTypesEnum.SET_CURRENT_IMPORT_LOG: {
            const newImportLog = (action as SetCurrentImportLogAction).importLog;
            const importLogs = [...state.importLogs];
            const index = importLogs.findIndex(l => l.id === newImportLog.id);

            if (index > -1) {
                importLogs[index] = newImportLog;
            }

            return Object.assign({}, state, {
                currentImportLog: newImportLog,
                importLogs
            });
        }
        case ImportLogActionTypesEnum.SET_CURRENT_IMPORT_LOG_DETAIL: {
            const currentImportLog = state.currentImportLog;
            currentImportLog.importLogsDetails = (action as SetCurrentImportlogDetailAction).importLogsDetails;

            return Object.assign({}, state, {
                currentImportLog
            });
        }
        default:
            return state;
    }
}

export default importLogReducer;
