/* istanbul ignore file */
import BorganFile from './borgan-file';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('BorganFileImp')
export default class BorganFileImp implements BorganFile {

	@JsonProperty('url', String, true)
	url: string;

	constructor(url: string = '') {
		this.url = url;
	}
}
