import Notification from 'model/notification';
import {JsonConvert} from 'json2typescript';
import NotificationImp from 'model/notification.imp';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';

const jsonConvert: JsonConvert = new JsonConvert();

export const getNotifications = (): Promise<Notification[]> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.NOTIFICATIONS}`)
		.then(res => (res.data && res.data.data) ? jsonConvert.deserializeArray(res.data.data.map((notification: any) => ({ ...notification, ...notification.attributes })), NotificationImp) : []);
};

export const markNotificationRead = (id: string): Promise<void> => {
	return axios.put(`${APP_CONSTANTS.ENDPOINTS.NOTIFICATIONS}/${id}`, {});
};
