import {JsonConvert} from 'json2typescript';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';
import AgencyCompanyImp from 'model/agency-company.imp';
import AgencyCompany from 'model/agency-company';
import { AddCompanyFormData } from 'components/modal/add-company/add-company-modal.component';
import {AgencyCompanyEditFormData} from '../../agency-company-edit/agency-company-edit.component';
import ListResults from 'model/list-results';
import ListResultsImp from 'model/list-results.imp';

const jsonConvert: JsonConvert = new JsonConvert();

export const getAgencyCompanies = (agencyId: string, page: number): Promise<ListResults<AgencyCompany>> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_COMPANIES, agencyId);
	return axios.get(`${endpoint}?page=${page}`)
		.then(res => new ListResultsImp(res.data.meta.total_pages, undefined, jsonConvert.deserializeArray(res.data.data.map((company: any) => ({ ...company, ...company.attributes })), AgencyCompanyImp)));
};

export const updateAgencyCompany = (company: AgencyCompanyEditFormData, companyId: string, agencyId: string): Promise<AgencyCompany> => {
	const endpoint = `${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_COMPANIES, agencyId)}/${companyId}`;
	const body = { data: { type: 'Company', attributes: company}};

	return axios.put(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyCompanyImp));
};

export const deleteAgencyCompany = (companyId: string, agencyId:string): Promise<void> => {
	return axios.delete(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_COMPANIES, agencyId)}/${companyId}`);
};

export const addAgencyCompany = (data: AddCompanyFormData, agencyId:string): Promise<AgencyCompany> => {
	const body = { data: { type: 'Company', attributes: data }};

	return axios.post(replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_COMPANIES, agencyId), body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyCompanyImp));
};
