import React, {FunctionComponent, ReactElement} from 'react';
import './property-detail.component.scss';
import {useSelector} from 'react-redux';
import PropertyTitleComponent from './components/property-title/property-title.component';
import PropertySuppliesComponent from './components/property-supplies/property-supplies.component';
import {FormattedMessage} from 'react-intl';
import PropertyDocumentsComponent from './components/property-documents/property-documents.component';
import MainState from 'store/model/main.state';
import PropertyConversationComponent from './components/property-conversation/property-conversation.component';
import PropertyInformationComponent from './components/property-information/property-information.component';

const PropertyDetailComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);

	return <div className="property-detail-component">
		{ currentProperty.id ? <>
			<PropertyTitleComponent/>
			<PropertyInformationComponent />
			<PropertySuppliesComponent/>
			<PropertyDocumentsComponent/>
			<PropertyConversationComponent/>
		</> : <h3 className="no-properties">
			<FormattedMessage id="no_property_selected"/>
		</h3> }
	</div>;
};

export default PropertyDetailComponent;
