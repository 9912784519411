import React, {FunctionComponent, ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {useForm, Controller, FieldError} from 'react-hook-form';
import SelectOption from 'model/select-option';
import Select from 'react-select';
import Supply from 'model/supply';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {changeSupplyStatus, createSupply} from './services/change-supply-status.service';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import {APP_CONSTANTS} from 'config/app.config';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';

export interface ChangeSupplyStatusForm {
	status: SelectOption;
}

interface ChangeSupplyStatusModalComponentProps {
	supply: Supply;
}

const ChangeSupplyStatusModalComponent: FunctionComponent<ChangeSupplyStatusModalComponentProps> = ({ supply }): ReactElement => {
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();
	const { handleSubmit, errors, control } = useForm<ChangeSupplyStatusForm>();

	const editPropertyStatus = (form: ChangeSupplyStatusForm): void => {
		dispatch(SHOW_LOADING_ACTION);

		if (supply.id) {
			changeSupplyStatus(form, currentProperty, supply.id)
				.then(() => {
					const index = currentProperty.supplies.findIndex(s => s.id === supply.id);

					/* istanbul ignore else */
					if (index > -1) {
						currentProperty.supplies[index].status = form.status.value;
						const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
						setCurrentPropertyAction.property = currentProperty;
						dispatch(setCurrentPropertyAction);
						dispatch(HIDE_MODAL_ACTION);
					}
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('supply_status_update_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		} else {
			createSupply(form, currentProperty, supply.type)
				.then((newSupply: Supply) => {
					currentProperty.supplies.push(newSupply);
					const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
					setCurrentPropertyAction.property = currentProperty;
					dispatch(setCurrentPropertyAction);
					dispatch(HIDE_MODAL_ACTION);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('supply_status_update_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	};

	return <div>
		<h3 className="title u-text--secondary"><FormattedMessage id="status_change"/></h3>
		<form onSubmit={handleSubmit(editPropertyStatus)} data-testid="form">
			<Controller
				control={control}
				name="status"
				rules={{required: 'field_required'}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.status ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="status"/></label>
						<Select
							options={APP_CONSTANTS.SUPPLY_STATUSES.filter(o => o.value !== supply.status)}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.status && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.status as FieldError).message}/></small> }
					</div>
				)}
			/>
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="update_status"/>
			</button>
		</form>
	</div>;
};

export default ChangeSupplyStatusModalComponent;
