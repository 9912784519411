/* istanbul ignore file */
import { PropertyActionTypesEnum } from './types';
import MainAction from 'store/model/main.action';
import Property from 'model/property';
import PropertyImp from 'model/property.imp';
import Message from 'model/message';
import Document from 'model/document';
import DocumentImp from 'model/document.imp';

export interface SetPropertiesAction extends MainAction {
	properties: Property[];
}

export interface SetCurrentPropertyAction extends MainAction {
	property: Property;
}

export interface SetCurrentPropertyDocumentsAction extends MainAction {
	documents: Document[];
}

export interface SetCurrentPropertyConversationAction extends MainAction {
	conversation: Message[];
}

export interface SetSupplyInvoicesAction extends MainAction {
	supplyId: string;
	invoices: Document[];
}

export interface AddSupplyInvoiceAction extends MainAction {
	supplyId: string;
	invoice: Document;
}

export const SET_PROPERTIES_ACTION: SetPropertiesAction = {
	type: PropertyActionTypesEnum.SET_PROPERTIES,
	properties: []
};

export const SET_CURRENT_PROPERTY_ACTION: SetCurrentPropertyAction = {
	type: PropertyActionTypesEnum.SET_CURRENT_PROPERTY,
	property: new PropertyImp()
};

export const SET_CURRENT_PROPERTY_DOCUMENTS_ACTION: SetCurrentPropertyDocumentsAction = {
	type: PropertyActionTypesEnum.SET_CURRENT_PROPERTY_DOCUMENTS,
	documents: []
};

export const SET_CURRENT_PROPERTY_CONVERSATION_ACTION: SetCurrentPropertyConversationAction = {
	type: PropertyActionTypesEnum.SET_CURRENT_PROPERTY_CONVERSATION,
	conversation: []
};

export const SET_SUPPLY_INVOICES_ACTION: SetSupplyInvoicesAction = {
	type: PropertyActionTypesEnum.SET_SUPPLY_INVOICES,
	supplyId: '',
	invoices: []
};

export const ADD_SUPPLY_INVOICE_ACTION: AddSupplyInvoiceAction = {
	type: PropertyActionTypesEnum.ADD_SUPPLY_INVOICE,
	supplyId: '',
	invoice: new DocumentImp()
};
