import React, {FunctionComponent, ReactElement} from 'react';
import './checkbox.component.scss';
import {FormattedMessage} from 'react-intl';

interface CheckboxComponentProps {
	fieldName: string;
	label: string;
	register: Function;
}

const CheckboxComponent: FunctionComponent<CheckboxComponentProps> = ({ fieldName, label, register }): ReactElement => {
	return <div className="checkbox">
		<label>
			<input type="checkbox" name={fieldName} readOnly={true} ref={register()}/>
			<span className="checkmark"/>
			<label><FormattedMessage id={label}/></label>
		</label>
	</div>;
};

export default CheckboxComponent;
