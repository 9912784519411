import React from 'react'
import SelectOption from 'model/select-option';
import Supply from 'model/supply';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select'
import { COPY_SUPPLY_CONSTANTS } from './config/copy-supply-information-config';
import { useDispatch, useSelector } from 'react-redux';
import MainState from 'store/model/main.state';
import { loadSupplyDetail } from 'components/properties/components/property-detail/services/property-detail.service';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import { SET_CURRENT_PROPERTY_ACTION } from 'store/property/actions';
import axios from 'axios';
import { replaceAgencyPropertyEndpoint } from 'services/utils.service';
import { APP_CONSTANTS } from 'config/app.config';

export interface SelectSupplyOptionForm {
	suppliesSelect: SelectOption;
}

interface CopySupplyInformationModalComponentProps {
    supply: Supply;
	save: Function;
}

export const CopySupplyInformationModalComponent = ({ supply, save }: CopySupplyInformationModalComponentProps) => {
    const { handleSubmit, errors, control } = useForm<SelectSupplyOptionForm>();
	const { currentProperty } = useSelector((state: MainState) => state.property);
    const { supplies } = useSelector((state: MainState) => state.property.currentProperty);
    const supplyOptions = supplies.filter(s => s.type !== supply.type);
	const dispatch = useDispatch();

    const copySupplyData = (form: SelectSupplyOptionForm): void => {
		dispatch(SHOW_LOADING_ACTION);

        const rootCopySupply = supplyOptions.filter(s => s.type === form.suppliesSelect.value);

		loadSupplyDetail(currentProperty, rootCopySupply[0].id)
		.then((supplyDetail: Supply) => {

			const data = {
				owner_phone: supplyDetail.data.owner_phone,
				owner_identification: supplyDetail.data.owner_identification,
				owner_email: supplyDetail.data.owner_email,
				owner_iban: supplyDetail.data.owner_iban,
				contract_number: supply.data.contract_number,
				company_name: supply.data.company_name,
				company_id: supply.company.id
			}

			const index = currentProperty.supplies.findIndex(s => s.id === supply.id);
			const body = { data: { type: 'supply', attributes: { owner: supplyDetail.owner, sale_state: supply.saleState, atr_status: supply.atrStatus, atr_date: supply.atrDate, data }}};

			axios.put(`${replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_INFO, currentProperty.agencyId, currentProperty.id)}/${supply.id}`, body);

			/* istanbul ignore else */
			if (index > -1) {
				currentProperty.supplies[index].owner = supplyDetail.owner;
				currentProperty.supplies[index].data = data;

				const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
				setCurrentPropertyAction.property = currentProperty;
				dispatch(setCurrentPropertyAction);
			}

			showGrowlMessage(new GrowlMessageImp('supply_data_copied', true), dispatch);
			dispatch(HIDE_MODAL_ACTION);

			save();
		})
		.catch(() => showGrowlMessage(new GrowlMessageImp('property_supply_read_error'), dispatch))
		.finally(() => dispatch(HIDE_LOADING_ACTION));
    }

	const activeSuppliesOptions = (supplyOptions: Supply[]) => {
		let filterOptions: SelectOption[] = [];

		supplyOptions.forEach(supply => {
			let auxArray = COPY_SUPPLY_CONSTANTS.SUPPLIES.filter(s => s.value === supply.type)

			filterOptions.push(auxArray[0]);
		})

		return filterOptions;
	}

    return <div>
        <form onSubmit={handleSubmit(copySupplyData)} data-testid="form">
			<Controller
				control={control}
				name="suppliesSelect"
				rules={{required: 'field_required'}}
				render={({ onChange, value }) => (
					<div className={`form-group ${errors.suppliesSelect ? 'form-group--has-danger' : ''}`}>
						<label className="form-group__label"><FormattedMessage id="supplies"/></label>
						<Select
                            options={activeSuppliesOptions(supplyOptions)}
							classNamePrefix="select"
							isSearchable={false}
							placeholder={<FormattedMessage id="select"/>}
							value={value}
							onChange={e => onChange(e)}
							isMulti={false}
						/>
						{ errors.suppliesSelect && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={(errors.suppliesSelect as FieldError).message}/></small> }
					</div>
				)}
			/>
			<button className="btn btn--block btn--lg btn--secondary" type="submit">
				<FormattedMessage id="update_supply"/>
			</button>
		</form>
    </div>
}
