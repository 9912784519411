/* istanbul ignore file */
import React from 'react';
import SelectOption from 'model/select-option';
import SelectOptionImp from 'model/select-option.imp';
import {FormattedMessage} from 'react-intl';
import {SupplyTypeEnum} from 'model/supply-type.enum';
import {HolderTypeEnum} from 'model/holder-type.enum';

interface OpenRequestConfig {
	SUPPLIES: SelectOption[];
	GAS_RATES: SelectOption[];
	FIBER_SPEED: SelectOption[];
	HOLDER_TYPES: SelectOption[];
}

export const OPEN_REQUEST_CONSTANTS: OpenRequestConfig = {
	SUPPLIES: [
		new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.POWER} />, SupplyTypeEnum.POWER),
		new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.GAS} />, SupplyTypeEnum.GAS),
		new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.WATER} />, SupplyTypeEnum.WATER),
		new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.TELECOM} />, SupplyTypeEnum.TELECOM),
		new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.ALARMS} />, SupplyTypeEnum.ALARMS)
	],
	GAS_RATES: [
		new SelectOptionImp('RL.1', 'RL.1'),
		new SelectOptionImp('RL.2', 'RL.2'),
		new SelectOptionImp('RL.3', 'RL.3'),
		new SelectOptionImp('RL.4', 'RL.4'),
	],
	FIBER_SPEED: [
		new SelectOptionImp('100 Mb', '100 Mb'),
		new SelectOptionImp('300 Mb', '300 Mb'),
		new SelectOptionImp('600 Mb', '600 Mb')
	],
	HOLDER_TYPES: [
		new SelectOptionImp(<FormattedMessage id="tenant" />, HolderTypeEnum.TENANT),
		new SelectOptionImp(<FormattedMessage id="agency" />, HolderTypeEnum.AGENCY)
	]
};
