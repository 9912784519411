import {PROPERTY_FILTER_CONSTANTS} from '../config/property-filter.config';
import {FilterFieldType} from '../model/filter-field-type.enum';

export const parseFiltersToQuery = (filters: Record<string, any>): string => {
	let filtersQuery = '';

	PROPERTY_FILTER_CONSTANTS.FILTER_FIELDS.forEach(filterField => {
		switch (filterField.type) {
			case FilterFieldType.STRING:
				if (filters[filterField.field]?.length) {
					filtersQuery += `&filter[${filterField.field}]=${filters[filterField.field]}`;
				}
				break;
			case FilterFieldType.BOOLEAN:
				if (filters[filterField.field]) {
					filtersQuery += `&filter[${filterField.field}]=true`;
				}
				break;
			case FilterFieldType.SELECT:
				if (filters[filterField.field]?.value) {
					filtersQuery += `&filter[${filterField.field}]=${filters[filterField.field].value}`;
				}
				break;
		}
	});

	return filtersQuery;
};
