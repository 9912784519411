import React, {FunctionComponent, ReactElement, useState} from 'react';
import './property-supply-info.component.scss';
import Supply from 'model/supply';
import icons from 'sprite/sprite.svg';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import OpenIncidenceModalComponent from 'components/modal/open-incidence/open-incidence-modal.component';
import OpenRequestModalComponent from 'components/modal/open-request/open-request-modal.component';
import PropertySupplyEditComponent from '../property-supply-edit/property-supply-edit.component';
import MainState from 'store/model/main.state';
import {UserTypeEnum} from 'model/user-type.enum';
import {BorganActionTypeEnum} from 'model/borgan-action-type.enum';
import BorganAction from 'model/borgan-action';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getFieldIcon, resolveAction} from '../../services/property-detail.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {openModal} from 'services/utils.service';
import Tooltip from 'rc-tooltip';
import {PROPERTY_DETAIL_CONSTANTS} from '../../config/property-detail.config';
import {PROPERTY_SUPPLY_INFO_CONSTANTS} from './config/property-supply-info.config';
import PropertySupplyInvoicesComponent from '../property-supply-invoices/property-supply-invoices.component';

interface PropertySupplyInfoComponentProps {
	supply: Supply;
}

const PropertySupplyInfoComponent: FunctionComponent<PropertySupplyInfoComponentProps> = ({ supply }): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	/* istanbul ignore next */
	const { formatDate } = useSelector((state: MainState) => state.language.language);
	const dispatch = useDispatch();
	const [ showEdit, setShowEdit ] = useState<boolean>(false);

	const getInfoItem = (icon: string, text: string, label: string, key?: number): ReactElement => {
		return <div className="col-md-6 info-item" key={key}>
			<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id={label}/></span>}>
				<svg className="icon icon--16">
					<use xlinkHref={`${icons}#${icon}`}/>
				</svg>
			</Tooltip>
			<FormattedMessage id={text ? text : '-'} />
		</div>;
	};

	const closeAction = (index: number): void => {
		dispatch(SHOW_LOADING_ACTION);

		resolveAction(currentProperty, supply.id, supply.actions[index].id)
			.then(() => {
				supply.incidences--;
				supply.actions.splice(index, 1);

				const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
				setCurrentPropertyAction.property = currentProperty;
				dispatch(setCurrentPropertyAction);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('incidence_delete_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="property-supply-info-component container">
		<div className="row relative">
			{ showEdit ? <PropertySupplyEditComponent save={() => setShowEdit(false)} supply={supply}/> : <>
				{ getInfoItem('icon-circle-user', supply.owner, 'owner') }
				{ getInfoItem('icon-calendar', supply.atrDate ? supply.atrDate.format(formatDate) : '-', 'atr_date') }
				{ getInfoItem('icon-arrows-bottom-top', supply.atrStatus, 'atr_status') }
				{ PROPERTY_DETAIL_CONSTANTS.OWNER_EDIT_FIELDS.map((field: [string, string], index: number) => {
					return getInfoItem(getFieldIcon(field[0]), supply.data[field[0]], field[1], index);
				}) }
				{ getInfoItem('icon-briefcase', supply.company.name, 'company') }
				{ getInfoItem('icon-arrows-bottom-top', supply.saleState, 'sale_state') }
			</> }
			<div className="col-md-12">
				<div className="incidences">
                    <PropertySupplyInvoicesComponent supplyId={supply.id} />
                    <h5 className="title">
						<FormattedMessage id="incidences"/>
						<button className="btn btn--sm btn--secondary" onClick={() => openModal(<OpenIncidenceModalComponent supply={supply}/>, dispatch)} data-testid="open-incidence-button">
							<FormattedMessage id="open_incidence" />
						</button>
					</h5>
					{ supply.actions.length ? <div className="open-incidences">
						<FormattedMessage id="open_incidences" values={{
							incidences: supply.actions.filter(a => a.actionType === BorganActionTypeEnum.INCIDENCE).length,
							requests: supply.actions.filter(a => a.actionType === BorganActionTypeEnum.REQUEST).length
						}}/>
					</div> : <div className="no-open-incidences">
						<FormattedMessage id="no_open_incidences"/>
					</div> }
					<div className="actions">
						{ supply.actions.map((action: BorganAction, index: number) => {
							return <div key={index} className="action-item">
								<div>
									<p><FormattedMessage id={action.actionType}/>: { action.createdAt.format(formatDate) }</p>
									<span className="action-item-info">
										{ action.actionType === BorganActionTypeEnum.INCIDENCE ? action.description : Object.keys(action.data).map((key: string) => {
											return !PROPERTY_SUPPLY_INFO_CONSTANTS.EXCLUDED_DATA_FIELDS.includes(key) && <div key={key} className="data-field">
												<FormattedMessage id={key}/>: <FormattedMessage id={action.data[key]}/>
											</div>;
										}) }
										{ action.company.name && <div>
											<FormattedMessage id="company"/>: {action.company.name}
										</div> }
									</span>
								</div>
								{ user.permissions.includes(UserTypeEnum.SELECTRA) && <button className="btn btn--sm btn--secondary" onClick={() => closeAction(index)} data-testid="delete-action">
									<FormattedMessage id="close"/>
								</button> }
							</div>;
						}) }
					</div>
                </div>
			</div>
			<div className="col-md-12 bottom">
				{ user.permissions.includes(UserTypeEnum.SELECTRA) && <button className="btn btn--white btn--sm" onClick={() => setShowEdit(!showEdit)} data-testid="edit-button">
					<FormattedMessage id={showEdit ? 'cancel' : 'edit'} />
				</button> }
				{ !showEdit && (!user.permissions.includes(UserTypeEnum.SELECTRA) ?
					<button className="btn btn--sm btn--secondary" onClick={() => openModal(<OpenRequestModalComponent selectedSupply={supply}/>, dispatch)} data-testid="open-request-button">
						<FormattedMessage id="open_request" />
					</button> : ''
				)}
			</div>
		</div>
	</div>;
};

export default PropertySupplyInfoComponent;
