import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import { UserTypeEnum } from 'model/user-type.enum';
import { FormattedMessage } from 'react-intl';
import AgencyCompany from 'model/agency-company';
import Collapsible from 'react-collapsible';
import './agency-company.component.scss';
import icons from 'sprite/sprite.svg';
import AgencyCompanyEditComponent from '../agency-company-edit/agency-company-edit.component';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import DeleteModalComponent from 'components/modal/delete/delete-modal.component';
import { openModal } from 'services/utils.service';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import { SET_CURRENT_AGENCY_ACTION } from 'store/agency/actions';
import { deleteAgencyCompany } from '../agency-companies/services/agency-companies.service';

export interface AgencyCompanyComponentProps {
    company: AgencyCompany;
}
 
const AgencyCompanyComponent: FunctionComponent<AgencyCompanyComponentProps> = ({ company }): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    /* istanbul ignore next */
    const { currentAgency } = useSelector((state: MainState) => state.agency);
    const dispatch = useDispatch();
    const [ opened, setOpened ] = useState<boolean>(false);
    const [ showEdit, setShowEdit ] = useState<boolean>(false);

    const getTrigger = (): ReactElement => {
        return <div className="agency-company-component clickable" onClick={() => {setOpened(!opened)}} data-testid="collapsible-trigger">
            <p>{company.name}</p>
            <svg className="icon icon--16 icon--neutral icon--right">
                <use xlinkHref={`${icons}#icon-chevron-right`}/>
            </svg>
        </div>;
    };

    const deleteCompany = (): void => {
        openModal(<DeleteModalComponent
            title="delete_company"
            fieldValue={company.name}
            label="agency_company_name"
            repeatLabel="repeat_agency_company_name"
            onDelete={() => {
                dispatch(SHOW_LOADING_ACTION);

                deleteAgencyCompany(company.id, currentAgency.id)
                    .then(() => {
                        const index = currentAgency.companies.findIndex(s => s.id === company.id);
                        currentAgency.companies.splice(index, 1);

                        const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
                        setCurrentAgencyAction.agency = currentAgency;
                        dispatch(setCurrentAgencyAction);
                        dispatch(HIDE_MODAL_ACTION);
                        showGrowlMessage(new GrowlMessageImp('company_deleted', true), dispatch);
                    })
                    .catch(() => showGrowlMessage(new GrowlMessageImp('company_delete_error'), dispatch))
                    .finally(() => dispatch(HIDE_LOADING_ACTION));
            }}
        />, dispatch);
    };

    return <div className={`col-md-12 ${opened && 'opened'}`}>
        <Collapsible trigger={getTrigger()}>
            <div className="container">
                { showEdit ? <AgencyCompanyEditComponent editInfoState={setShowEdit} company={company} /> :
                    <div className="row agency-company-component__info">
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="owner"/>:</span>{company.owner || '--'}</p>
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="address"/>:</span>{company.address || '--'}</p>
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="city"/>:</span>{company.city || '--'}</p>
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="province"/>:</span>{company.province || '--'}</p>
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="postal_code"/>:</span>{company.postalCode || '--'}</p>
                        <p className="col-md-6"><span className="agency-company-component__info-item"><FormattedMessage id="bank_account"/>:</span>{company.iban || '--'}</p>
                        { (user.permissions.includes(UserTypeEnum.SELECTRA) || user.permissions.includes(UserTypeEnum.AGENCY_ADMIN)) &&
                            <div className="col-md-12 agency-company-component__btn-edit">
                                <button className="btn btn--sm btn--danger btn--outline" onClick={() => deleteCompany()} data-testid="delete-button">
                                    <FormattedMessage id="delete"/>
                                </button>
                                <button className="btn btn--sm btn--white" onClick={() => setShowEdit(true)} data-testid="edit-button">
                                    <FormattedMessage id="edit"/>
                                </button>
                            </div>
                        }
                    </div>
                }
            </div>
        </Collapsible>
    </div>;
};
 
export default AgencyCompanyComponent;
