import React, {FunctionComponent, ReactElement} from 'react';
import './property-supplies.component.scss';
import {FormattedMessage} from 'react-intl';
import PropertySupplyComponent from '../property-supply/property-supply.component';
import {useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {PROPERTY_DETAIL_CONSTANTS} from '../../config/property-detail.config';
import {SupplyTypeEnum} from 'model/supply-type.enum';
import SupplyImp from 'model/supply.imp';

const PropertySuppliesComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { supplies } = useSelector((state: MainState) => state.property.currentProperty);

	return <div className="property-supplies-component">
		<h4><FormattedMessage id="supplies"/></h4>
		{ PROPERTY_DETAIL_CONSTANTS.SUPPLIES.map((supplyType: SupplyTypeEnum, index: number) => {
			const supply = supplies.find(s => s.type === supplyType);

			return <PropertySupplyComponent key={index} supply={supply ? supply : new SupplyImp('', supplyType)} />;
		}) }
	</div>;
};

export default PropertySuppliesComponent;
