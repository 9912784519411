import React, {FunctionComponent, ReactElement} from 'react';
import PropertiesListComponent from './components/properties-list/properties-list.component';
import PropertyDetailComponent from './components/property-detail/property-detail.component';

const PropertiesComponent: FunctionComponent = (): ReactElement => {
	return <div className="header-padding container">
		<div className="row">
			<div className="col-md-3 no-padding-right scroll-list properties">
				<PropertiesListComponent/>
			</div>
			<div className="col-md-9 no-padding-left scroll-list properties">
				<PropertyDetailComponent/>
			</div>
		</div>
	</div>;
};

export default PropertiesComponent;
