import {SupplyTypeEnum} from 'model/supply-type.enum';
import {SupplyStatusEnum} from 'model/supply-status.enum';

export const getSupplyTypeIcon = (type: SupplyTypeEnum): string => {
	switch (type) {
		case SupplyTypeEnum.POWER:
			return 'icon-light-bulb';
		case SupplyTypeEnum.GAS:
			return 'icon-fire';
		case SupplyTypeEnum.WATER:
			return 'icon-drop';
		case SupplyTypeEnum.TELECOM:
			return 'icon-radio-tower';
		case SupplyTypeEnum.ALARMS:
			return 'icon-ringing-bell';
	}
};

export const getSupplyStatusIcon = (status: SupplyStatusEnum): string => {
	switch (status) {
		case SupplyStatusEnum.PROCESSING_HOLDER_CHANGE:
		case SupplyStatusEnum.PROCESSING_SUBSCRIPTION:
			return 'icon-chevron-right';
		case SupplyStatusEnum.UNSUBSCRIBED:
		case SupplyStatusEnum.PROCESSING_UNSUBSCRIPTION:
			return 'icon-chevron-left';
		case SupplyStatusEnum.SUBSCRIBED:
			return 'icon-check-mark';
		case SupplyStatusEnum.INCIDENCE:
			return 'icon-triangle-warning';
		default:
			return 'icon-cross';
	}
};
