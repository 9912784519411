/* istanbul ignore file */
import FilterField from '../model/filter-field';
import FilterFieldImp from '../model/filter-field.imp';
import {FilterFieldType} from '../model/filter-field-type.enum';

interface PropertyFilterConfig {
	REGULAR_INPUTS: string[];
	SUPPLY_STATUS_FIELDS: [string, string, string][];
	FILTER_FIELDS: FilterField[];
}

export const PROPERTY_FILTER_CONSTANTS: PropertyFilterConfig = {
	REGULAR_INPUTS: [
		'address',
		'owner',
		'phone',
		'email'
	],
	SUPPLY_STATUS_FIELDS: [
		['energy', 'energy_state', 'Energy'],
		['gas', 'gas_state', 'Gas'],
		['water', 'water_state', 'Water'],
		['alarm', 'alarm_state', 'alarms'],
		['fiber', 'fiber_state', 'telecom'],
	],
	FILTER_FIELDS: [
		new FilterFieldImp('address'),
		new FilterFieldImp('owner'),
		new FilterFieldImp('phone'),
		new FilterFieldImp('email'),
		new FilterFieldImp('codes'),
		new FilterFieldImp('agency', FilterFieldType.SELECT),
		new FilterFieldImp('energy_state', FilterFieldType.SELECT),
		new FilterFieldImp('gas_state', FilterFieldType.SELECT),
		new FilterFieldImp('water_state', FilterFieldType.SELECT),
		new FilterFieldImp('fiber_state', FilterFieldType.SELECT),
		new FilterFieldImp('alarm_state', FilterFieldType.SELECT),
		new FilterFieldImp('energy', FilterFieldType.BOOLEAN),
		new FilterFieldImp('gas', FilterFieldType.BOOLEAN),
		new FilterFieldImp('water', FilterFieldType.BOOLEAN),
		new FilterFieldImp('fiber', FilterFieldType.BOOLEAN),
		new FilterFieldImp('alarm', FilterFieldType.BOOLEAN),
		new FilterFieldImp('incidence', FilterFieldType.BOOLEAN),
		new FilterFieldImp('request', FilterFieldType.BOOLEAN)
	]
};
