import {JsonConvert} from 'json2typescript';
import User from 'model/user';
import UserImp from 'model/user.imp';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import {UserTypeEnum} from 'model/user-type.enum';
import {AddSelectraUserForm} from '../components/modal/add-selectra-user/add-selectra-user-modal.component';


const jsonConvert: JsonConvert = new JsonConvert();

export const getUserInfo = (email: string): Promise<User> => {
	return axios.get(APP_CONSTANTS.ENDPOINTS.USER_INFO + email)
		.then(res => {
			const user = jsonConvert.deserializeObject(res.data, UserImp);

			if (user.permissions.includes(UserTypeEnum.SELECTRA) || user.permissions.includes(UserTypeEnum.AGENCY) || user.permissions.includes(UserTypeEnum.AGENCY_ADMIN)) {
				return user;
			}

			throw new Error();
		});
};

export const createSelectraUser = (data: AddSelectraUserForm): Promise<void> => {
	return axios.post(APP_CONSTANTS.CLOUD_FUNCTIONS_ENDPOINTS.CREATE_USER, { email: data.email, password: data.password });
};
