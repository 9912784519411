import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import './agency-company-edit.component.scss';
import { useForm } from 'react-hook-form';
import AgencyCompany from 'model/agency-company';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { validateBankAccount, validateZipCode } from 'services/validation.service';
import MainState from 'store/model/main.state';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import { SET_CURRENT_AGENCY_ACTION } from 'store/agency/actions';
import { updateAgencyCompany } from '../agency-companies/services/agency-companies.service';

interface AgencyCompanyEditComponentProps {
    editInfoState: Function;
    company: AgencyCompany;
}

export interface AgencyCompanyEditFormData {
    name: string;
    owner: string;
    address: string;
    city: string;
    province: string;
    postal_code: string;
    iban: string;
}

const AgencyCompanyEditComponent: FunctionComponent<AgencyCompanyEditComponentProps> = ({ editInfoState, company }): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    /* istanbul ignore next */
    const { currentAgency } = useSelector((state: MainState) => state.agency);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm<AgencyCompanyEditFormData>({
        defaultValues: {
            name: company.name,
            owner: company.owner,
            address: company.address,
            city: company.city,
            province: company.province,
            postal_code: company.postalCode,
            iban: company.iban
        }
    });

    const submit = (form: AgencyCompanyEditFormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        updateAgencyCompany(form, company.id, currentAgency.id)
            .then((company: AgencyCompany) => {
                const index = currentAgency.companies.findIndex(s => s.id === company.id);

                /* istanbul ignore else */
                if (index > -1) {
                    currentAgency.companies[index] = company;
                    const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
                    setCurrentAgencyAction.agency = currentAgency;
                    dispatch(setCurrentAgencyAction);
                }
                
                editInfoState(false);
                showGrowlMessage(new GrowlMessageImp('company_updated', true), dispatch);
            })			
            .catch((err) => {
                if (err.response.data.name && err.response.data.name[0] === 'duplicated_company_name') {
                    showGrowlMessage(new GrowlMessageImp('company_name_duplicated_error'), dispatch);
                }
                else {
                    showGrowlMessage(new GrowlMessageImp('company_update_error'), dispatch)
                }
            })
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

    return <form className="row agency-company-edit" onSubmit={handleSubmit(submit)} data-testid="form">
        <div className="col-md-12 form-group">
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="agency_company_name"/>:</label>
                <input type="text" name="name" className="form-group__item" ref={register({ required: 'field_required' })} data-testid="name-input" />
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
        </div>
        <div className="col-md-6 form-group">
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="owner"/>:</label>
                <input type="text" name="owner" className="form-group__item" ref={register({ required: 'field_required' })} data-testid="owner-input" />
                { errors.owner && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner.message}/></small> }
            </div>
        </div>
        <div className="col-md-6 form-group">
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="address"/>:</label>
                <input type="text" name="address" className="form-group__item" ref={register()} data-testid="address-input" />
            </div>
        </div>
        <div className="col-md-6 form-group">
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="city"/>:</label>
                <input type="text" name="city" className="form-group__item" ref={register()} data-testid="city-input" />
            </div>
        </div>
        <div className="col-md-6 form-group">
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="province"/>:</label>
                <input type="text" name="province" className="form-group__item" ref={register()} data-testid="province-input" />
            </div>
        </div>
        <div className={`col-md-6 form-group ${errors.postal_code ? 'form-group--has-danger' : ''}`}>
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="postal_code"/>:</label>
                <input type="text" name="postal_code" className="form-group__item" data-testid="postal-code-input" ref={register(
                    {validate: postal_code => validateZipCode(user.country, postal_code)}
                )} />
            </div>
            { errors.postal_code && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.postal_code.message}/></small> }
        </div>
        <div className={`col-md-6 form-group ${errors.iban ? 'form-group--has-danger' : ''}`}>
            <div className="agency-company-edit__item">
                <label><FormattedMessage id="bank_account"/>:</label>
                <input type="text" name="iban" className="form-group__item" data-testid="iban-input" ref={register(
                    {validate: iban => validateBankAccount(user.country, iban)}
                )} />
            </div>
            { errors.iban && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.iban.message}/></small> }
        </div>
        <div className="agency-company-edit__grp-btns">
            <button className="btn btn--sm btn--secondary btn--outline" type="button" onClick={() => editInfoState(false)} data-testid="cancel-button">
                <FormattedMessage id="cancel"/>
            </button>
            <button className="btn btn--sm btn--success" type="submit">
                <FormattedMessage id="save_changes"/>
            </button>
        </div>
    </form>;
};
 
export default AgencyCompanyEditComponent;
