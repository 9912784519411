import React, { FunctionComponent, ReactElement, useState } from 'react';
import './forgot-password.component.scss';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { useDispatch } from 'react-redux';
import { FORM_CONSTANTS } from 'config/form.config';
import icon from 'sprite/sprite.svg';
import { Link } from 'react-router-dom';
import {APP_CONSTANTS} from 'config/app.config';

interface FormData {
    email: string;
}

const ForgotPasswordComponent: FunctionComponent = (): ReactElement => {
    const { handleSubmit, errors, register } = useForm<FormData>();
    const dispatch = useDispatch();
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const [ successEmail, setSuccessEmail ] = useState<boolean>(false);

    const forgotPassword = (form: FormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        app.auth().sendPasswordResetEmail(form.email)
            .then(() => {
                setSuccessEmail(true);
                setErrorMessage('');
            })
            .catch(err => setErrorMessage(err.code))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

    return <div className="forgot-password">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 img-responsive forgot-password__logo">
                    <img src={process.env.PUBLIC_URL + '/selectra.svg'} alt="selectra" />
                </div>
            </div>
            <div className="row row--center">
                <div className="col-lg-4 card forgot-password__card">
                    <h1 className="card__title u-text--secondary">
                        <FormattedMessage id="recover_password" />
                    </h1>
                    <form onSubmit={handleSubmit(forgotPassword)} data-testid="form">
                        <div className={`form-group ${errors.email || errorMessage ? 'form-group--has-danger' : ''}`} >
                            <label className="form-group__label">
                                <FormattedMessage id="email" />
                            </label>
                            <input
                                name="email"
                                className="form-group__item"
                                ref={register({
                                    required: 'field_required',
                                    pattern: {
                                        value: FORM_CONSTANTS.REGEX.EMAIL,
                                        message: 'invalid_email'
                                    }
                                })}
                                disabled={successEmail}
                                data-testid="email-input"
                            />
                            { errors.email && <small className="form-group__msg form-group__msg--danger">
                                <FormattedMessage id={errors.email.message} />
                            </small> }
                        </div>
                        { !successEmail && <button type="submit" className="btn btn--lg btn--secondary btn--block forgot-password__card--btn">
                            <FormattedMessage id="receive_email" />
                        </button> }
                        <Link className="log-in-link" to={APP_CONSTANTS.ROUTES.LOGIN}>
                            <FormattedMessage id="go_back"/>
                        </Link>
                    </form>
                </div>
            </div>
            { errorMessage && <div className="row row--center">
                <div className="col-lg-4 card alerts alerts__danger forgot-password__alert">
                    <svg className="icon icon--24 icon--danger icon--left">
                        <use xlinkHref={`${icon}#icon-circle-cross-mark-filled`}/>
                    </svg>
                    <FormattedMessage id={errorMessage} />
                </div>
            </div> }
            { successEmail && <div className="row row--center">
                <div className="col-lg-4 card alerts alerts__success forgot-password__alert">
                    <svg className="icon icon--24 icon--success icon--left">
                        <use xlinkHref={`${icon}#icon-circle-check-mark-filled`}/>
                    </svg>
                    <FormattedMessage id="send_email_confirmation" />
                </div>
            </div> }
        </div>
    </div>;
};

export default ForgotPasswordComponent;
