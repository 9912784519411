import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './properties-list.component.scss';
import Property from 'model/property';
import {useDispatch, useSelector} from 'react-redux';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {
	readProperties,
	setPropertiesList,
	getExportDocument,
	loadProperty
} from './services/properties-list.service';
import MainState from 'store/model/main.state';
import {FormattedMessage} from 'react-intl';
import icons from 'sprite/sprite.svg';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import ListResults from 'model/list-results';
import PropertyFilterComponent from '../property-filter/property-filter.component';
import Tooltip from 'rc-tooltip';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {useForm} from 'react-hook-form';

interface GeneralSearchFormData {
	search: string;
}

const PropertiesListComponent: FunctionComponent = (): ReactElement => {
	 /* istanbul ignore next */
	const { currentProperty, properties } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();
	const [ page, setPage ] = useState<number>(1);
	const [ totalPages, setTotalPages ] = useState<number>(1);
	const [ totalProperties, setTotalProperties ] = useState<number | undefined>(0);
	const [ selectAll, setSelectAll ] = useState<boolean>(false);
	const [ showFilters, setShowFilters ] = useState<boolean>(false);
	const [ filters, setFilters ] = useState<string>('');
	const { register, handleSubmit } = useForm<GeneralSearchFormData>();
	const checkedAmount = properties.filter(p => p.checked).length;

	useEffect(() => {
		dispatch(SHOW_LOADING_ACTION);

		readProperties(1, '')
			.then((listResults: ListResults<Property>) => {
				setTotalPages(listResults.totalPages);
				setTotalProperties(listResults.totalProperties);
				setPropertiesList(dispatch, listResults.results);

				if (listResults.results.length > 0 && listResults.results[0].id) {
					loadProperty(dispatch, listResults.results[0]);
				}
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('properties_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	}, [dispatch]);

	const loadProperties = (): void => {
		dispatch(SHOW_LOADING_ACTION);
		const currentPage = page + 1;

		readProperties(currentPage, filters)
			.then((listResults: ListResults<Property>) => {
				/* istanbul ignore else */
				if (selectAll) {
					listResults.results.forEach(p => {
						p.checked = true;
					});
				}

				setPage(currentPage);
				setTotalPages(listResults.totalPages);
				setTotalProperties(listResults.totalProperties);
				setPropertiesList(dispatch, properties.concat(listResults.results));
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('properties_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	const checkProperty = (property: Property): void => {
		const index = properties.indexOf(property);
		const propertiesCopy = [...properties];
		propertiesCopy[index].checked = !propertiesCopy[index].checked;
		setSelectAll(false);
		setPropertiesList(dispatch, propertiesCopy);
	};

	const toggleSelectAll = (): void => {
		const propertiesCopy = [...properties];

		propertiesCopy.forEach(p => {
			p.checked = !selectAll;
		});

		if (!selectAll) {
			readProperties(page, filters)
			.then((listResults: ListResults<Property>) => {
				setTotalProperties(listResults.totalProperties);
			});
		}

		setPropertiesList(dispatch, propertiesCopy);
		setSelectAll(!selectAll);
	};

	const exportSupplies = (): void => {
		dispatch(SHOW_LOADING_ACTION);

		getExportDocument(selectAll ? filters : properties.filter(p => p.checked).map(p => p.id).toString(), selectAll)
			.then((TSVPropertiesDocument: string) => {
				const downloadLink = document.createElement('a');
				downloadLink.download = 'Borgan properties.tsv';
				downloadLink.href = encodeURI(`data:text/tsv;charset=utf-8,${TSVPropertiesDocument}\n`).replace(/[#]/g, 'Nº');
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('properties_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	const applyFilters = (filters: string): void => {
		dispatch(SHOW_LOADING_ACTION);

		if (selectAll) {
			setSelectAll(false);
		}
		readProperties(1, filters)
			.then((listResults: ListResults<Property>) => {
				dispatch(SET_CURRENT_PROPERTY_ACTION);
				setPage(1);
				setFilters(filters);
				setTotalPages(listResults.totalPages);
				setTotalProperties(listResults.totalProperties);
				setPropertiesList(dispatch, listResults.results);

				if (listResults.results.length > 0 && listResults.results[0].id) {
					loadProperty(dispatch, listResults.results[0]);
				}
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('properties_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="properties-list-component">
		<div className="properties-list-component__sticky">
			<div className="search-bar">
				<form onSubmit={handleSubmit((data: GeneralSearchFormData) => applyFilters(`&filter[full_text]=${data.search}`))} data-testid="form">
					<button type="submit">
						<svg className="icon icon--24">
							<use xlinkHref={`${icons}#icon-magnifier`}/>
						</svg>
					</button>
					<input className="form-group__item" type="text" name="search" data-testid="full-text-input" ref={register()}/>
				</form>
				<Tooltip placement="bottom" overlay={<PropertyFilterComponent applyFilters={applyFilters}/>} trigger={['click']} onVisibleChange={visible => setShowFilters(visible)}>
					<svg className={`icon icon--12 clickable ${!showFilters && 'filters-hidden'}`} onClick={() => setShowFilters(!showFilters)} data-testid="tooltip-filter">
						<use xlinkHref={`${icons}#icon-chevron-right`}/>
					</svg>
				</Tooltip>
			</div>
			<div className="export-container">
				<span className={`circle clickable ${selectAll && 'active'}`} onClick={toggleSelectAll} data-testid="select-all" />
				<div className={`${checkedAmount || selectAll ? 'clickable' : 'disabled'}`} onClick={() => (checkedAmount || selectAll) && exportSupplies()} data-testid="export-button">
					<FormattedMessage id="export"/>
					<svg className="icon icon--24">
						<use xlinkHref={`${icons}#icon-arrow-curved-right`}/>
					</svg>
				</div>
			</div>
			<div className="selected-properties">
				<FormattedMessage id={'properties_selected'} values={{selected: selectAll ? totalProperties : checkedAmount}}/>
			</div>
		</div>
		{ properties.map((property, index) => {
			return <div key={index} className={`property-item ${property.id === currentProperty.id && 'active'}`}>
				<span className={`circle clickable ${property.checked && 'active'}`} onClick={() => checkProperty(property)} data-testid="property-item"/>
				<div className="property-info" onClick={() => loadProperty(dispatch, property)} data-testid="property-item-info">
					<span>{ property.address }</span>
					{ property.supplies.map((currentPropertySupply) => {
						if (currentPropertySupply.owner) {
							return <p><FormattedMessage id={currentPropertySupply.type}/> : {currentPropertySupply.owner}</p>
						}
						else {
							return <p><FormattedMessage id={currentPropertySupply.type}/> : <FormattedMessage id="not_owner"/></p>
						}
					}) }
				</div>
			</div>;
		}) }
		{ page < totalPages && <div className="see-more" onClick={() => loadProperties()} data-testid="load-more-properties">
			<FormattedMessage id="see_more"/>
		</div> }
	</div>;
};

export default PropertiesListComponent;
