/* istanbul ignore file */
import PropertyState from './property.state';
import Property from 'model/property';
import PropertyImp from 'model/property.imp';

export default class PropertyStateImp implements PropertyState {
	properties: Property[];
	currentProperty: Property;

	constructor(properties: Property[] = [], currentProperty: Property = new PropertyImp()) {
		this.properties = properties;
		this.currentProperty = currentProperty;
	}
}
