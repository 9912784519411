/* istanbul ignore file */
import Agency from './agency';
import User from './user';
import {JsonObject, JsonProperty} from 'json2typescript';
import BorganFile from './borgan-file';
import BorganFileImp from './borgan-file.imp';
import AgencyCompanies from './agency-company';
import AgencyAgents from './agency-agent';

@JsonObject('AgencyImp')
export default class AgencyImp implements Agency {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('logo', BorganFileImp, true)
	logo: BorganFile;

	users: User[];
	companies: AgencyCompanies[];
	agents: AgencyAgents[];

	constructor(id: string = '', name: string = '', logo: BorganFile = new BorganFileImp(), users: User[] = [], companies: AgencyCompanies[] = [],
		agents: AgencyAgents[] = []) {
		this.id = id;
		this.name = name;
		this.logo = logo;
		this.users = users;
		this.companies = companies;
		this.agents = agents;
	}
}
