
import React, { FunctionComponent, ReactElement } from 'react';
import ImportLogDetailComponent from './components/import-log-detail/import-log-detail.component';

const ImportLogsComponent: FunctionComponent = (): ReactElement => {

    return <div className="header-padding container">
        <div className="row">
            <div className="col-md-12 no-padding-left scroll-list import-logs">
                <ImportLogDetailComponent />
            </div>
        </div>
    </div>;
}

export default ImportLogsComponent;
