import React, {FunctionComponent, ReactElement} from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { updatePropertyStreet } from 'components/properties/components/property-detail/services/property-detail.service';
import PropertyImp from 'model/property.imp';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import MainState from 'store/model/main.state';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';

interface FormData {
    address: string
}

const EditTitlePropertyModalComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm<FormData>({
        defaultValues: {
            address: currentProperty.address
        }
    });

    const editPropertyAddress = (form: FormData) => {
        dispatch(SHOW_LOADING_ACTION);
        const propertyAddress = new PropertyImp(currentProperty.id, currentProperty.agencyId, form.address);

        updatePropertyStreet(propertyAddress)
            .then(() => {
                currentProperty.address = form.address;
                const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
                setCurrentPropertyAction.property = currentProperty;
                dispatch(setCurrentPropertyAction);
                dispatch(HIDE_MODAL_ACTION);
                showGrowlMessage(new GrowlMessageImp('property_updated', true), dispatch);
            })
            .catch((err) => {
                if (err.response.data.address && err.response.data.address[0] === 'duplicated_property_name') {
                    showGrowlMessage(new GrowlMessageImp('property_address_name_duplicated_error'), dispatch);
                } else {
                    showGrowlMessage(new GrowlMessageImp('property_update_error'), dispatch);
                }
            })
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

	return <div>
        <h3 className="title u-text--secondary"><FormattedMessage id="edit_address"/></h3>
        <form onSubmit={handleSubmit(editPropertyAddress)} data-testid="form">
            <div className={`form-group ${errors.address ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="address"/></label>
                <input type="text" name="address" className="form-group__item" ref={register({required: 'field_required'})} data-testid="address"/>
                { errors.address && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.address.message}/></small> }
            </div>
            <button className="btn btn--block btn--lg btn--secondary" type="submit"><FormattedMessage id="update"/></button>
        </form>
    </div>;
};

export default EditTitlePropertyModalComponent;
