import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './property-information.component.scss';
import { useSelector } from 'react-redux';
import MainState from 'store/model/main.state';
import PropertyInformationEditComponent from '../property-information-edit/property-information-edit.component';
import { UserTypeEnum } from 'model/user-type.enum';

const PropertyInformationComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    const [ editInfoProperty, setEditInfoProperty ] = useState<boolean>(false);
    /* const { formatDate } = useSelector((state: MainState) => state.language.language); */
    const monthNames = ['january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december'
    ];

    useEffect(() => {
        setEditInfoProperty(false);
    }, [currentProperty]);

    const getManagementDateMonthYear = (month: any) => {
        if (month) {
             const dateMonth = monthNames[month.month()];
             return <>
                <FormattedMessage id={dateMonth} /> {month.year()}
             </>
        }
    }

    return <div className="property-information">
        <h4><FormattedMessage id="property_information"/></h4>
        { !editInfoProperty ?
            <div className="card property-information__card">
                { user.permissions.includes(UserTypeEnum.SELECTRA) && 
                <p><span className="u-text--secondary"><FormattedMessage id="agency_name"/></span> : {currentProperty.agencyName ? currentProperty.agencyName : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p> }
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <p><span className="u-text--secondary"><FormattedMessage id="referency_code"/></span> : {currentProperty.code ? currentProperty.code : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="energy_cups"/></span> : {currentProperty.energyCups ? currentProperty.energyCups : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="gas_cups"/></span> : {currentProperty.gasCups ? currentProperty.gasCups : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="cadastral_reference"/></span> : {currentProperty.cadastralReference ? currentProperty.cadastralReference : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>                        
                        </div>
                        <div className="col-xs-6">
                            <p><span className="u-text--secondary"><FormattedMessage id="management_date"/></span> : {currentProperty.managementDate ? getManagementDateMonthYear(currentProperty.managementDate) : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="province"/></span> : {currentProperty.province ? currentProperty.province : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="population"/></span> : {currentProperty.city ? currentProperty.city : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>
                            <p><span className="u-text--secondary"><FormattedMessage id="postal_code"/></span> : {currentProperty.postalCode ? currentProperty.postalCode : <span className="unspecified"><FormattedMessage id="unspecified"/></span>}</p>                  
                        </div>
                    </div>
                </div>
                { user.permissions.includes(UserTypeEnum.SELECTRA) &&
                    <div className="property-information__edit">
                        <button className="btn btn--white btn--sm" onClick={() => setEditInfoProperty(true)} data-testid="edit-property">
                            <FormattedMessage id="edit"/>
                        </button>
                    </div>
                }
            </div>
        : <PropertyInformationEditComponent editInfoState={setEditInfoProperty} /> }
    </div>;
};
 
export default PropertyInformationComponent;
