/* istanbul ignore file */
import BorganAction from './borgan-action';
import {Any, JsonObject, JsonProperty} from 'json2typescript';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';
import {BorganActionTypeEnum} from './borgan-action-type.enum';
import moment, {Moment} from 'moment';
import {MomentDeserializer} from './deserializers/moment.deserializer';
import AgencyCompany from './agency-company';
import AgencyCompanyImp from './agency-company.imp';

@JsonObject('BorganActionImp')
export default class BorganActionImp implements BorganAction {

	@JsonProperty('id', NumberToStringDeserializer, true)
	id: string;

	@JsonProperty('desc', String, true)
	description: string;

	@JsonProperty('data', Any, true)
	data: Record<string, any>;

	@JsonProperty('action_type', String, true)
	actionType: BorganActionTypeEnum;

	@JsonProperty('created_at', MomentDeserializer, true)
	createdAt: Moment;

	@JsonProperty('supply_id', NumberToStringDeserializer, true)
	supplyId: string;

	@JsonProperty('agency_company', AgencyCompanyImp, true)
	company: AgencyCompany;

	constructor(id: string = '', description: string = '', data: Record<string, any> = {}, actionType: BorganActionTypeEnum = BorganActionTypeEnum.INCIDENCE,
				createdAt: Moment = moment(), supplyId: string = '', company: AgencyCompany = new AgencyCompanyImp()) {
		this.id = id;
		this.description = description;
		this.data = data;
		this.actionType = actionType;
		this.createdAt = createdAt;
		this.supplyId = supplyId;
		this.company = company;
	}
}
