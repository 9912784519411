import React, {FunctionComponent, ReactElement} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import MainState from 'store/model/main.state';
import { ADD_SUPPLY_INVOICE_ACTION } from 'store/property/actions';
import Document from 'model/document';
import DragAndDropComponent from 'components/shared/drag-drop/drag-drop.component';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {createSupplyInvoice} from 'components/properties/components/property-detail/components/property-supply-invoices/services/property-supply-invoices.service';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';

interface UploadInvoiceModalComponentProps {
    supplyId: string;
}

export interface UploadInvoiceFormData {
    name: string;
    file: any;
}

const UploadInvoiceModalComponent: FunctionComponent<UploadInvoiceModalComponentProps> = ({ supplyId }): ReactElement => {
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    const dispatch = useDispatch();
    const { register, handleSubmit, control, errors } = useForm<UploadInvoiceFormData>();

    const uploadNewInvoice = (data: UploadInvoiceFormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        createSupplyInvoice(data, currentProperty.agencyId, currentProperty.id, supplyId)
            .then((invoice: Document) => {
                const addSupplyInvoiceAction = {...ADD_SUPPLY_INVOICE_ACTION};
                addSupplyInvoiceAction.supplyId = supplyId;
                addSupplyInvoiceAction.invoice = invoice;
                dispatch(addSupplyInvoiceAction);
                dispatch(HIDE_MODAL_ACTION);
                showGrowlMessage(new GrowlMessageImp('invoice_created', true), dispatch);
            })
            .catch(() => showGrowlMessage(new GrowlMessageImp('invoice_upload_error'), dispatch))
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };
    
	return <div className="upload-invoice-modal">
        <h3 className="title u-text--secondary"><FormattedMessage id="upload_invoice"/></h3>
        <form onSubmit={handleSubmit(uploadNewInvoice)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="document_name"/></label>
                <input type="text" name="name" className="form-group__item" ref={register({required: 'field_required'})} data-testid="invoice-name-input"/>
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
            <DragAndDropComponent label="attached" fileType="image/*, .pdf" control={control} errors={errors} />
            <button className="btn btn--block btn--lg btn--secondary" type="submit"><FormattedMessage id="upload"/></button>
        </form>
    </div>;
};

export default UploadInvoiceModalComponent;
