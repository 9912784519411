import {JsonConvert} from 'json2typescript';
import Agency from 'model/agency';
import AgencyImp from 'model/agency.imp';
import {SET_CURRENT_AGENCY_ACTION, SET_AGENCIES_ACTION} from 'store/agency/actions';
import axios from 'axios';
import {APP_CONSTANTS} from 'config/app.config';
import {toBase64} from 'services/utils.service';
import {AddAgencyFormData} from '../../modal/add-agency/add-agency-modal.component';
import {EditAgencyFormData} from '../../modal/edit-agency/edit-agency-modal.component';
import ListResults from 'model/list-results';
import ListResultsImp from 'model/list-results.imp';

const jsonConvert: JsonConvert = new JsonConvert();

export const readAllAgencies = (): Promise<Agency[]> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.AGENCIES}?list_all=true`)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((agency: any) => ({ ...agency, ...agency.attributes })), AgencyImp));
};

export const paginateAgencies = (page: number, query: string): Promise<ListResults<Agency>> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.AGENCIES}?page=${page}&filter[name]=${query}`)
		.then(res => new ListResultsImp(res.data.meta.total_pages, undefined, jsonConvert.deserializeArray(res.data.data.map((agency: any) => ({ ...agency, ...agency.attributes })), AgencyImp)));
};

export const getAgencyDetail = (agencyId: string): Promise<Agency> => {
	return axios.get(`${APP_CONSTANTS.ENDPOINTS.AGENCY_BY_COMPANY_ID}/${agencyId}`)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyImp));
};

export const setAgenciesList = (dispatch: Function, agencies: Agency[]): void => {
	const setAgenciesAction = {...SET_AGENCIES_ACTION};
	setAgenciesAction.agencies = agencies;
	dispatch(setAgenciesAction);
};

export const selectCurrentAgency = (dispatch: Function, agency: Agency): void => {
	const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
	setCurrentAgencyAction.agency = agency;
	dispatch(setCurrentAgencyAction);
};

export const addAgency = async(data: AddAgencyFormData): Promise<Agency> => {
	const body = { data: { type: 'Agency', attributes: { name: data.name, logo: await toBase64(data.file) }}};

	return axios.post(APP_CONSTANTS.ENDPOINTS.AGENCIES, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyImp));
};

export const updateAgencyInformation = async(data: EditAgencyFormData, agencyId: string): Promise<Agency> => {
	const body = { data: { type: 'Agency', attributes: { name: data.name, logo: await toBase64(data.file) }}};

	return axios.put(`${APP_CONSTANTS.ENDPOINTS.AGENCIES}/${agencyId}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, AgencyImp));
};

export const deleteAgency = (agencyId: string): Promise<void> => {
	return axios.delete(`${APP_CONSTANTS.ENDPOINTS.AGENCIES}/${agencyId}`);
};
