/* istanbul ignore file */
import {combineReducers} from 'redux';
import agencyReducer from './agency/reducer';
import authReducer from './auth/reducer';
import growlReducer from './growl/reducer';
import importLogReducer from './import-log/reducer';
import languageReducer from './language/reducer';
import loadingReducer from './loading/reducer';
import modalReducer from './modal/reducer';
import propertyReducer from './property/reducer';

export const mainReducer = combineReducers({
	agency: agencyReducer,
	auth: authReducer,
	growl: growlReducer,
	language: languageReducer,
	loading: loadingReducer,
	modal: modalReducer,
	property: propertyReducer,
	importLog: importLogReducer
});
