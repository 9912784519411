import Document from 'model/document';
import axios from 'axios';
import {JsonConvert} from 'json2typescript';
import {replaceAgencyPropertyEndpoint, toBase64} from 'services/utils.service';
import {APP_CONSTANTS} from 'config/app.config';
import DocumentImp from 'model/document.imp';
import { UploadInvoiceFormData } from 'components/modal/upload-invoice/upload-invoice-modal.component';

const jsonConvert: JsonConvert = new JsonConvert();

export const getInvoices = (agencyId: string, propertyId: string, supplyId: string): Promise<Document[]> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.INVOICES, agencyId, propertyId, supplyId);

	return axios.get(endpoint)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((document: any) => ({ ...document, ...document.attributes })), DocumentImp));
};

export const createSupplyInvoice = async(data: UploadInvoiceFormData, agencyId: string, propertyId: string, supplyId: string): Promise<Document> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.INVOICES, agencyId, propertyId, supplyId);
	const body = {
		data: {
			type: 'Document',
			attributes: { name: data.name, file: await toBase64(data.file) }
		}
	};

	return axios.post(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, DocumentImp));
};

export const deleteSupplyInvoice = (agencyId: string, propertyId: string, supplyId: string, invoiceId: string): Promise<void> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.INVOICES, agencyId, propertyId, supplyId);

	return axios.delete(`${endpoint}/${invoiceId}`);
};
