/* istanbul ignore file */
export enum UserTypeEnum {
	SELECTRA = 'borgan_admin',
	SELECTRA_AGENT = 'borgan_agent',
	AGENCY = 'borgan_user',
	AGENCY_ADMIN = 'borgan_user_admin',
	CREATE_SELECTRA_USER = 'borgan_register_selectra_user',
	CREATE_SELECTRA_AGENT = 'borgan_create_selectra_agent',
	DELETE_SELECTRA_AGENT = 'borgan_destroy_selectra_agent',
	DELETE_PROPERTY = 'borgan_destroy_property',
	DELETE_AGENCY = 'borgan_destroy_agency'
}
