/* istanbul ignore file */
import AuthState from './auth.state';
import User from 'model/user';
import UserImp from 'model/user.imp';

export default class AuthStateImp implements AuthState {
	user: User;
	isLogged: boolean;

	constructor(user: User = new UserImp(), isLogged: boolean = false) {
		this.user = user;
		this.isLogged = isLogged;
	}
}
