import React, {FunctionComponent, MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import './property-conversation.component.scss';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {FormattedMessage, useIntl} from 'react-intl';
import icons from 'sprite/sprite.svg';
import {
	getMessageTypeIcon,
	loadConversation,
	parseDescriptionData,
	sendMessage
} from './services/property-conversation.service';
import Message from 'model/message';
import {useForm} from 'react-hook-form';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {SET_CURRENT_PROPERTY_ACTION, SET_CURRENT_PROPERTY_CONVERSATION_ACTION} from 'store/property/actions';
import moment from 'moment';
import {MessageTypeEnum} from 'model/message-type.enum';
import {getSupplyTypeIcon} from '../property-supply/services/property-supply.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import ListResults from 'model/list-results';

interface FormData {
	message: string;
}

const PropertyConversationComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	/* istanbul ignore next */
	const { formatDate } = useSelector((state: MainState) => state.language.language);
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { register, handleSubmit, reset } = useForm<FormData>();
	const conversationRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const [ page, setPage ] = useState<number>(1);
	const [ totalPages, setTotalPages ] = useState<number>(1);
	const [ loading, setLoading ] = useState<boolean>(false);

	useEffect(() => {
		/* istanbul ignore next */
		if (page === 1 && conversationRef?.current?.scrollTo) {
			conversationRef.current.scrollTo({ top: 0 });
		}
	}, [currentProperty.conversation.length, page]);

	useEffect(() => {
		/* istanbul ignore else */
		if (currentProperty.id && currentProperty.address) {
			setLoading(true);

			loadConversation(currentProperty.id, currentProperty.agencyId, 1)
				.then((listResults: ListResults<Message>) => {
					const setCurrentPropertyConversationAction = {...SET_CURRENT_PROPERTY_CONVERSATION_ACTION};
					setCurrentPropertyConversationAction.conversation = listResults.results;
					dispatch(setCurrentPropertyConversationAction);
					setTotalPages(listResults.totalPages);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('messages_read_error'), dispatch))
				.finally(() => setLoading(false));
		}
	}, [currentProperty.agencyId, currentProperty.id, currentProperty.address, dispatch]);

	const renderDescriptionData = (description: string): ReactElement => {
		const data = parseDescriptionData(description, formatDate);

		return <>
			{ Object.keys(data).map((key: string, index: number) => {
				return <div key={index}>
					<FormattedMessage id={key}/>:
					<span className="field-change"><FormattedMessage id={data[key][0] || '-'}/></span>
					<svg className="icon icon--8">
						<use xlinkHref={`${icons}#icon-chevron-right`}/>
					</svg>
					<span className="field-change"><FormattedMessage id={data[key][1] || '-'}/></span>
				</div>;
			}) }
		</>;
	};

	const getMessages = (): ReactElement => {
		let day = '', renderDay = false;

		return <div>
			{ currentProperty.conversation.map((message: Message, index: number) => {
				const isComment = message.messageType === MessageTypeEnum.COMMENT;
				const isSender = user.id === message.senderId;

				if (day !== message.sent.format(formatDate)) {
					day = message.sent.format(formatDate);
					renderDay = true;
				} else {
					renderDay = false;
				}

				return <div className="message-container" key={index}>
					{ renderDay && <div className="day">
						{ day === moment().format(formatDate) ?
							<FormattedMessage id="today"/> :
							<FormattedMessage id="days_ago" values={{days: moment().diff(message.sent.startOf('day'), 'days')}}/>
						}
						<span> ({ day })</span>
					</div>}
					<div className={`message ${isComment ? 'comment' : 'action'} ${isSender && 'sender'}`}>
						<div className="icon-container">
							<svg className="icon icon--24 supply-type">
								<use xlinkHref={`${icons}#${isComment ? 'icon-home-filled' : getSupplyTypeIcon(message.supplyType)}`}/>
							</svg>
							<span className="message-type">
								<svg className="icon icon--12 icon--white">
									<use xlinkHref={`${icons}#${getMessageTypeIcon(message.messageType)}`}/>
								</svg>
							</span>
						</div>
						<div className="message-info">
							<p className="action-title">
								{ isComment ?
									<FormattedMessage id={isSender ? 'you_sent_message' : 'somebody_sent_message'} values={{ sender: message.senderName }}/> :
									<FormattedMessage id={`${message.action}_${message.messageType}_message`} values={{ sender: message.senderName }}/>
								}
							</p>
							<div className="action-subtitle">
								{ isComment ? message.description : (message.description ? renderDescriptionData(message.description) : '') }
							</div>
						</div>
					</div>
				</div>;
			}) }
			{ /* istanbul ignore next */ loading && <div className="message-container">
				<div className="message loading">
					<FormattedMessage id="loading_comments" />
				</div>
			</div> }
		</div>;
	};

	const handleInfiniteScroll = (event: any): void => {
		const element = event.target;

		if (page < totalPages && element.scrollHeight - element.scrollTop === element.clientHeight && !loading) {
			setLoading(true);
			const currentPage = page + 1;

			loadConversation(currentProperty.id, currentProperty.agencyId, currentPage)
				.then((listResults: ListResults<Message>) => {
					setPage(currentPage);
					setTotalPages(listResults.totalPages);
					const setCurrentPropertyConversationAction = {...SET_CURRENT_PROPERTY_CONVERSATION_ACTION};
					setCurrentPropertyConversationAction.conversation = currentProperty.conversation.concat(listResults.results);
					dispatch(setCurrentPropertyConversationAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('messages_read_error'), dispatch))
				.finally(() => setLoading(false));
		}
	};

	const submitMessage = (form: FormData): void => {
		dispatch(SHOW_LOADING_ACTION);

		sendMessage(form.message, currentProperty)
			.then((message: Message) => {
				currentProperty.conversation.unshift(message);
				const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
				setCurrentPropertyAction.property = currentProperty;
				dispatch(setCurrentPropertyAction);
				reset();
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('message_sent_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="property-conversation-component">
		<h4><FormattedMessage id="conversation"/></h4>
		<div className="conversation-container">
			<div className="messages-container" ref={conversationRef} onScroll={handleInfiniteScroll} data-testid="scroll-div">
				{ getMessages() }
			</div>
			<form className="add-comment form-group__item-addon" onSubmit={handleSubmit(submitMessage)} data-testid="form">
				<input
					className="form-group__item"
					placeholder={formatMessage({id: 'new_comment'})}
					type="text"
					name="message"
					ref={register({ required: true})}
					data-testid="message-input"
				/>
				<button className="btn btn--secondary form-group__addon-btn" type="submit">
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#icon-paper-plane`}/>
					</svg>
				</button>
			</form>
		</div>
	</div>;
};

export default PropertyConversationComponent;
