import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './agencies-list.component.scss';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {FormattedMessage, useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import Agency from 'model/agency';
import AgencyImp from 'model/agency.imp';
import {paginateAgencies, selectCurrentAgency, setAgenciesList} from '../../services/agencies.service';
import icons from 'sprite/sprite.svg';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import ListResults from 'model/list-results';
import {SET_CURRENT_AGENCY_ACTION} from 'store/agency/actions';

interface FormData {
	search: string,
}

const AgenciesListComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { currentAgency, agencies } = useSelector((state: MainState) => state.agency);
	const agenciesSort = [...agencies].sort((a, b) => a.name.localeCompare(b.name));
	const intl = useIntl();
	const dispatch = useDispatch();
	const { register, handleSubmit } = useForm<FormData>();
	const [ page, setPage ] = useState<number>(1);
	const [ totalPages, setTotalPages ] = useState<number>(1);
	const [ query, setQuery ] = useState<string>('');

	useEffect(() => {
		dispatch(SHOW_LOADING_ACTION);

		paginateAgencies(1, '')
			.then((listResults: ListResults<Agency>) => {
				setTotalPages(listResults.totalPages);
				setAgenciesList(dispatch, listResults.results);
				selectCurrentAgency(dispatch, listResults.results[0] || new AgencyImp());
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('agencies_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	}, [dispatch]);

	const loadAgencies = (): void => {
		dispatch(SHOW_LOADING_ACTION);
		const currentPage = page + 1;

		paginateAgencies(currentPage, query)
			.then((listResults: ListResults<Agency>) => {
				setPage(currentPage);
				setTotalPages(listResults.totalPages);
				setAgenciesList(dispatch, agencies.concat(listResults.results))
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('agencies_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	const searchAgencies = (form: FormData): void => {
		dispatch(SHOW_LOADING_ACTION);

		paginateAgencies(1, form.search)
			.then((listResults: ListResults<Agency>) => {
				dispatch(SET_CURRENT_AGENCY_ACTION);
				setQuery(form.search);
				setPage(1);
				setTotalPages(listResults.totalPages);
				setAgenciesList(dispatch, listResults.results);
				selectCurrentAgency(dispatch, listResults.results[0] || new AgencyImp());
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('agencies_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="agencies-list-component">
		<form className="form-group__item-addon" onSubmit={handleSubmit(searchAgencies)} data-testid="form">
			<input className="form-group__item" placeholder={intl.formatMessage({id: 'search'})} type="text" name="search" ref={register()}/>
			<button className="btn btn--secondary form-group__addon-btn" type="submit">
				<svg className="icon icon--24">
					<use xlinkHref={`${icons}#icon-magnifier`}/>
				</svg>
			</button>
		</form>
		{ agenciesSort.map((agency, index) => {
			return <div key={index} className={`property-item ${agency.id === currentAgency.id && 'active'}`} onClick={() => selectCurrentAgency(dispatch, agency)} data-testid="agency-item">
				<div className="property-info">
					<span>{ agency.name }</span>
				</div>
			</div>;
		}) }
		{ page < totalPages && <div className="see-more" onClick={loadAgencies} data-testid="load-more-button">
			<FormattedMessage id="see_more"/>
		</div> }
	</div>;
};

export default AgenciesListComponent;
