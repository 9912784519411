import React from 'react';
import SelectOption from 'model/select-option';
import SelectOptionImp from 'model/select-option.imp';
import { SupplyTypeEnum } from 'model/supply-type.enum';
import { FormattedMessage } from 'react-intl';

interface CopySupplyInformationConfig {
    SUPPLIES: SelectOption[];
}

export const COPY_SUPPLY_CONSTANTS: CopySupplyInformationConfig = {
    SUPPLIES: [
        new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.POWER} />, SupplyTypeEnum.POWER),
        new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.GAS} />, SupplyTypeEnum.GAS),
        new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.TELECOM} />, SupplyTypeEnum.TELECOM),
        new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.ALARMS} />, SupplyTypeEnum.ALARMS),
        new SelectOptionImp(<FormattedMessage id={SupplyTypeEnum.WATER} />, SupplyTypeEnum.WATER)
    ]
}
