import React from 'react';
import SelectOption from 'model/select-option';
import SelectOptionImp from 'model/select-option.imp';
import {FormattedMessage} from 'react-intl';
import {SupplyStatusChangeEnum} from 'model/supply-status-change.enum';
import {SupplyTypeEnum} from 'model/supply-type.enum';
import {HolderTypeEnum} from 'model/holder-type.enum';
import Property from 'model/property';
import {replaceAgencyPropertyEndpoint} from 'services/utils.service';
import {APP_CONSTANTS} from 'config/app.config';
import axios from 'axios';
import BorganAction from 'model/borgan-action';
import BorganActionImp from 'model/borgan-action.imp';
import {JsonConvert} from 'json2typescript';
import AgencyCompany from 'model/agency-company';
import AgencyCompanyImp from 'model/agency-company.imp';

const jsonConvert: JsonConvert = new JsonConvert();

export const getAllAgencyCompanies = (agencyId: string): Promise<AgencyCompany[]> => {
	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.AGENCY_COMPANIES, agencyId);

	return axios.get(`${endpoint}?list_all=true`)
		.then(res => jsonConvert.deserializeArray(res.data.data.map((company: any) => ({ ...company, ...company.attributes })), AgencyCompanyImp));
};

export const sendRequest = (formData: Record<string, any>, property: Property, supplyId: string): Promise<BorganAction> => {
	const data: any = {};
	Object.keys(formData).forEach(key => {
		const formField = formData[key];
		data[key] = formField?.value ? formField.value : formField;
	});

	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_ACTIONS, property.agencyId, property.id, supplyId);
	const body = {
		data: {
			type: 'action',
			attributes: { data }
		}
	};

	return axios.post(endpoint, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, BorganActionImp));
};

export const newSupplyRequest = (formData: any, property: Property): Promise<BorganAction> => {
	const data: any = {};
	Object.keys(formData).forEach(key => {
		const formField = formData[key];
		data[key] = formField.value ? formField.value : formField;
	});

	const endpoint = replaceAgencyPropertyEndpoint(APP_CONSTANTS.ENDPOINTS.SUPPLY_ACTIONS_CREATE, property.agencyId, property.id);

	const body = {
		data: {
			type: 'action',
			attributes: { data }
		}
	};

	return axios.post(`${endpoint}?supply_type=${data.supply}`, body)
		.then(res => jsonConvert.deserializeObject({ ...res.data.data, ...res.data.data.attributes }, BorganActionImp));
};

export const getStatusChangeFromSupply = (country: string, holderType: HolderTypeEnum, supplyType: SupplyTypeEnum): SelectOption[] => {
	switch (country) {
		case 'ES':
			return getSpanishStatusChangeFromSupply(holderType, supplyType);
		default:
			return getSpanishStatusChangeFromSupply(holderType, supplyType);
	}
};

export const getFieldsFromSupplyStatus = (country: string, supplyType: SupplyTypeEnum, status: SupplyStatusChangeEnum): [string[], string[]] => {
	const fields: string[] = [], requiredFields: string[] = [];

	switch (country) {
		case 'ES':
			if (status === SupplyStatusChangeEnum.SUBSCRIBE || status === SupplyStatusChangeEnum.HOLDER_TO_TENANT) {
				fields.push('newHolder', 'newHolderPhone', 'newHolderNationalId', 'newHolderEmail', 'newHolderBankAccount');
				requiredFields.push('newHolder', 'newHolderPhone', 'newHolderNationalId', 'newHolderEmail', 'newHolderBankAccount');
			} else if (status === SupplyStatusChangeEnum.HOLDER_TO_PROPERTY) {
				fields.push('company_id');
				requiredFields.push('company_id');
			}

			/* istanbul ignore else */
			if (supplyType === SupplyTypeEnum.POWER) {
				/* istanbul ignore else */
				if ([SupplyStatusChangeEnum.SUBSCRIBE, SupplyStatusChangeEnum.HOLDER_TO_TENANT, SupplyStatusChangeEnum.POWER_INCREASE, SupplyStatusChangeEnum.POWER_DECREASE].includes(status)) {
					fields.push('hiredPower');
					requiredFields.push('hiredPower');
				} else if (status === SupplyStatusChangeEnum.HOLDER_TO_PROPERTY) {
					fields.push('hiredPower');
				}
			} else if (supplyType === SupplyTypeEnum.GAS) {
				/* istanbul ignore else */
				if ([SupplyStatusChangeEnum.SUBSCRIBE, SupplyStatusChangeEnum.HOLDER_TO_TENANT, SupplyStatusChangeEnum.HOLDER_TO_PROPERTY].includes(status)) {
					fields.push('hiredGas');
					requiredFields.push('hiredGas');
				}
			} else if (supplyType === SupplyTypeEnum.TELECOM) {
				/* istanbul ignore else */
				if ([SupplyStatusChangeEnum.SUBSCRIBE, SupplyStatusChangeEnum.HOLDER_TO_TENANT].includes(status)) {
					fields.push('fiberSpeed', 'mobileDesiredData');
				}
			}

			return [fields, requiredFields];
		default:
			return [fields, requiredFields];
	}
};

export const getPotencyOptions = (): SelectOption[] => {
	const options = [];

	for (let i = 0.1; i < 10; i += 0.1) {
		options.push(new SelectOptionImp(i.toFixed(1) + ' kW', i.toFixed(1) + ' kW'));
	}

	return options;
};

const getSpanishStatusChangeFromSupply = (holderType: HolderTypeEnum, supplyType: SupplyTypeEnum): SelectOption[] => {
	const holderOrTenantOption = holderType === HolderTypeEnum.AGENCY ?
		new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.HOLDER_TO_PROPERTY}/>, SupplyStatusChangeEnum.HOLDER_TO_PROPERTY) :
		new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.HOLDER_TO_TENANT}/>, SupplyStatusChangeEnum.HOLDER_TO_TENANT);

	switch (supplyType) {
		case SupplyTypeEnum.POWER:
			return [
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.SUBSCRIBE}/>, SupplyStatusChangeEnum.SUBSCRIBE),
				holderOrTenantOption,
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.POWER_INCREASE}/>, SupplyStatusChangeEnum.POWER_INCREASE),
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.POWER_DECREASE}/>, SupplyStatusChangeEnum.POWER_DECREASE),
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE)
			];
		case SupplyTypeEnum.GAS:
			return [
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.SUBSCRIBE}/>, SupplyStatusChangeEnum.SUBSCRIBE),
				holderOrTenantOption,
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE)
			];
		case SupplyTypeEnum.WATER:
			return [
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.SUBSCRIBE}/>, SupplyStatusChangeEnum.SUBSCRIBE),
				holderOrTenantOption,
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE)
			];
		case SupplyTypeEnum.TELECOM:
			return [
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.SUBSCRIBE}/>, SupplyStatusChangeEnum.SUBSCRIBE),
				holderOrTenantOption,
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.TEMPORARY_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.TEMPORARY_UNSUBSCRIBE),
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE)
			];
		case SupplyTypeEnum.ALARMS:
			return [
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.SUBSCRIBE}/>, SupplyStatusChangeEnum.SUBSCRIBE),
				holderOrTenantOption,
				new SelectOptionImp(<FormattedMessage id={SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE}/>, SupplyStatusChangeEnum.DEFINITIVE_UNSUBSCRIBE)
			];
	}
};
