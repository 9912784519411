import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class NumberToStringDeserializer implements JsonCustomConvert<string> {
	deserialize = (id?: number): string => {
		return String(id);
	};

	serialize = (id: string): number => {
		return Number(id);
	};
}
