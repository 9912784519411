/* istanbul ignore file */
import GrowlState from './growl.state';
import GrowlMessage from 'model/growl-message';

export default class GrowlStateImp implements GrowlState {
	messages: GrowlMessage[];

	constructor(messages: GrowlMessage[] = []) {
		this.messages = messages;
	}
}
