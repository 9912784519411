import React, {FunctionComponent, ReactElement} from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { sendPropertyIncidence } from 'components/properties/components/property-detail/services/property-detail.service';
import { SET_CURRENT_PROPERTY_ACTION } from 'store/property/actions';
import MainState from 'store/model/main.state';
import Supply from 'model/supply';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import BorganAction from 'model/borgan-action';

interface OpenIncidenceModalComponentProps {
	supply: Supply;
}

interface FormData {
	incidence: string;
}

const OpenIncidenceModalComponent: FunctionComponent<OpenIncidenceModalComponentProps> = ({supply}): ReactElement => {
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm<FormData>();

	const openIncidence = (data: FormData):void => {
		dispatch(SHOW_LOADING_ACTION);

		sendPropertyIncidence(data.incidence, currentProperty, supply.id)
			.then((action: BorganAction) => {
				const index = currentProperty.supplies.findIndex(s => s.id === supply.id);

				currentProperty.supplies[index].incidences++;
				currentProperty.supplies[index].actions.push(action);
				const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
				setCurrentPropertyAction.property = currentProperty;
				dispatch(setCurrentPropertyAction);
				
				dispatch(HIDE_MODAL_ACTION);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('incidence_create_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div>
		<h3 className="title u-text--secondary"><FormattedMessage id="open_incidence"/></h3>
		<form onSubmit={handleSubmit(openIncidence)} data-testid="form">
			<div className="form-group">
				<label className="form-group__label"><FormattedMessage id="new_incidence"/></label>
				<textarea
					className="form-group__item"
					name="incidence"
					ref={register({required: 'field_required'})}
					data-testid="textarea"
				/>
				{ errors.incidence && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.incidence.message}/></small> }
			</div>
			<button type="submit" className="btn btn--lg btn--block btn--secondary"><FormattedMessage id="create"/></button>
		</form>
	</div>;
};

export default OpenIncidenceModalComponent;
