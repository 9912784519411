import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import './property-filter.component.scss';
import {FormattedMessage} from 'react-intl';
import {Controller, useForm} from 'react-hook-form';
import SelectOption from 'model/select-option';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {readAllAgencies} from 'components/agencies/services/agencies.service';
import Agency from 'model/agency';
import SelectOptionImp from 'model/select-option.imp';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import Select from 'react-select';
import {SET_ALL_AGENCIES_ACTION} from 'store/agency/actions';
import {APP_CONSTANTS} from 'config/app.config';
import {PROPERTY_FILTER_CONSTANTS} from './config/property-filter.config';
import {parseFiltersToQuery} from './services/property-filter.service';
import TagsInput from 'react-tagsinput';
import CheckboxComponent from 'components/shared/checkbox/checkbox.component';
import {UserTypeEnum} from 'model/user-type.enum';

export interface PropertyFilterComponentProps {
	applyFilters: Function;
}

interface PropertyFilterFormData {
	address: string;
	owner: string;
	phone: string;
	email: string;
	codes: string[];
	agency: SelectOption;
	energy: boolean;
	energy_state: SelectOption;
	gas: boolean;
	gas_state: SelectOption;
	water: boolean;
	water_state: SelectOption;
	fiber: boolean;
	fiber_state: SelectOption;
	alarm: boolean;
	alarm_state: SelectOption;
	incidence: boolean;
	request: boolean;
}

const PropertyFilterComponent: FunctionComponent<PropertyFilterComponentProps> = ({ applyFilters }): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { allAgencies } = useSelector((state: MainState) => state.agency);
	const allAgenciesSort = [...allAgencies].sort((a, b) => a.name.localeCompare(b.name));
	const dispatch = useDispatch();
	const { control, register, reset, handleSubmit } = useForm<PropertyFilterFormData>();

	useEffect(() => {
		if (user.permissions.includes(UserTypeEnum.SELECTRA)) {
			dispatch(SHOW_LOADING_ACTION);

			readAllAgencies()
				.then((agencies: Agency[]) => {
					const setAllAgenciesAction = {...SET_ALL_AGENCIES_ACTION};
					setAllAgenciesAction.agencies = agencies;
					dispatch(setAllAgenciesAction)
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('agencies_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	}, [dispatch, user.permissions]);

	const resetFilters = (): void => {
		reset();
		applyFilters('');
	};

	return <div className="property-filter-component">
		<form onSubmit={handleSubmit(form => applyFilters(parseFiltersToQuery(form)))} data-testid="form">
			{ PROPERTY_FILTER_CONSTANTS.REGULAR_INPUTS.map((field: string, index: number) => {
				return <div className="filter-item row" key={index}>
					<div className="col-md-4">
					<span>
						<FormattedMessage id={field} />
					</span>
					</div>
					<div className="form-group col-md-8">
						<input className="form-group__item" type="text" name={field} ref={register()}/>
					</div>
				</div>;
			}) }
			<Controller
				defaultValue={[]}
				control={control}
				name="codes"
				render={({onChange, value}) => (
					<div className="filter-item row">
						<div className="col-md-4">
							<span><FormattedMessage id="reference" /></span>
						</div>
						<div className="form-group col-md-8">
							<TagsInput value={value} onChange={onChange} inputProps={{ placeholder: '' }} onlyUnique={true} />
						</div>
					</div>
				)}
			/>
			{ user.permissions.includes(UserTypeEnum.SELECTRA) && <Controller
				defaultValue={new SelectOptionImp(<FormattedMessage id="select"/>)}
				control={control}
				name="agency"
				render={({onChange, value}) => (
                    <div className="filter-item row">
                        <div className="col-md-4">
							<span>
								<FormattedMessage id="agency" />
							</span>
                        </div>
                        <div className="form-group col-md-8">
                            <Select
                                options={[new SelectOptionImp(<FormattedMessage id="select"/>)].concat(allAgenciesSort.map(agency => new SelectOptionImp(agency.name, agency.id)))}
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder={<FormattedMessage id="select"/>}
                                value={value}
                                onChange={e => onChange(e)}
                                isMulti={false}
                            />
                        </div>
                    </div>
				)}
			/> }
			{ PROPERTY_FILTER_CONSTANTS.SUPPLY_STATUS_FIELDS.map((statusConfig: [string, string, string], index: number) => {
				return <div className="filter-item row" key={index}>
					<div className="col-md-4">
						<CheckboxComponent fieldName={statusConfig[0]} label={statusConfig[2]} register={register}/>
					</div>
					<Controller
						defaultValue={new SelectOptionImp(<FormattedMessage id="select"/>)}
						control={control}
						name={statusConfig[1]}
						render={({onChange, value}) => (
							<>
								<div className="col-md-2">
									<span><FormattedMessage id="status" /></span>
								</div>
								<div className="form-group col-md-6">
									<Select
										options={[new SelectOptionImp(<FormattedMessage id="select"/>)].concat(APP_CONSTANTS.SUPPLY_STATUSES)}
										classNamePrefix="select"
										isSearchable={false}
										placeholder={<FormattedMessage id="select"/>}
										value={value}
										onChange={e => onChange(e)}
										isMulti={false}
									/>
								</div>
							</>
						)}
					/>
				</div>;
			}) }
			<div className="filter-item row">
				<div className="col-md-6">
					<CheckboxComponent fieldName="incidence" label="with_incidences" register={register}/>
				</div>
				<div className="col-md-6">
					<CheckboxComponent fieldName="request" label="with_open_request" register={register}/>
				</div>
			</div>
			<div className="buttons col-md-12">
				<button type="button" className="btn btn--white btn--sm" data-testid="resetFilterButton" onClick={() => resetFilters()}>
					<FormattedMessage id="remove_filters" />
				</button>
				<button type="submit" className="btn btn--sm btn--secondary">
					<FormattedMessage id="search" />
				</button>
			</div>
		</form>
	</div>;
};

export default PropertyFilterComponent;
