import React, {FunctionComponent, ReactElement} from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { validateBankAccount, validateZipCode } from 'services/validation.service';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import GrowlMessageImp from 'model/growl-message.imp';
import AgencyCompany from 'model/agency-company';
import { SET_CURRENT_AGENCY_ACTION } from 'store/agency/actions';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import { addAgencyCompany } from 'components/agencies/components/agency-companies/services/agency-companies.service';
import './add-company-modal.component.scss';

export interface AddCompanyFormData {
    name: string;
    owner: string;
    address: string;
    city: string;
    province: string;
    postal_code: string;
    iban: string;
}
 
const AddCompanyModalComponent: FunctionComponent = (): ReactElement => {
    /* istanbul ignore next */
    const { user } = useSelector((state: MainState) => state.auth);
    /* istanbul ignore next */
    const { currentAgency } = useSelector((state: MainState) => state.agency);
    const { register, handleSubmit, errors } = useForm<AddCompanyFormData>();
	const dispatch = useDispatch();

    const addCompany = (data: AddCompanyFormData): void => {
        dispatch(SHOW_LOADING_ACTION);

        addAgencyCompany(data, currentAgency.id)
            .then((newCompany: AgencyCompany) => {
                currentAgency.companies.push(newCompany);
                const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
                setCurrentAgencyAction.agency = currentAgency;
                dispatch(setCurrentAgencyAction);
                dispatch(HIDE_MODAL_ACTION);
                showGrowlMessage(new GrowlMessageImp('company_created', true), dispatch);
            })
            .catch((err) => {
                if (err.response.data.name && err.response.data.name[0] === 'duplicated_company_name') {
                    showGrowlMessage(new GrowlMessageImp('company_name_duplicated_error'), dispatch);
                }
                else {
                    showGrowlMessage(new GrowlMessageImp('company_create_error'), dispatch)
                }
            })
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

    return <div className="add-company-modal-component">
        <h3 className="title u-text--secondary"><FormattedMessage id="add_company"/></h3>
        <form onSubmit={handleSubmit(addCompany)} data-testid="form">
            <div className={`form-group ${errors.name ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="agency_company_name"/></label>
                <input type="text" name="name" className="form-group__item" ref={register({ required: 'field_required' })} data-testid="company-name-input" />
                { errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
            </div>
            <div className={`form-group ${errors.owner ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="owner"/>:</label>
                <input type="text" name="owner" className="form-group__item" ref={register({ required: 'field_required' })} data-testid="owner-input" />
                { errors.owner && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner.message}/></small> }
            </div>
            <div className="form-group">
                <label className="form-group__label"><FormattedMessage id="address"/>:</label>
                <input type="text" name="address" className="form-group__item" ref={register()} />
            </div>
            <div className="form-group">
                <label className="form-group__label"><FormattedMessage id="city"/>:</label>
                <input type="text" name="city" className="form-group__item" ref={register()} />
            </div>
            <div className="form-group">
                <label className="form-group__label"><FormattedMessage id="province"/>:</label>
                <input type="text" name="province" className="form-group__item" ref={register()} />
            </div>
            <div className={`form-group ${errors.postal_code ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="postal_code"/>:</label>
                <input type="text" name="postal_code" className="form-group__item" ref={register({
                    validate: postal_code => validateZipCode(user.country, postal_code)
                })} />
                { errors.postal_code && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.postal_code.message}/></small> }
            </div>
            <div className={`form-group ${errors.iban ? 'form-group--has-danger' : ''}`}>
                <label className="form-group__label"><FormattedMessage id="bank_account"/>:</label>
                <input type="text" name="iban" className="form-group__item" ref={register({
                    validate: iban => validateBankAccount(user.country, iban)
                })} />
                { errors.iban && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.iban.message}/></small> }
            </div>
            <button className="btn btn--lg btn--block btn--success" type="submit"><FormattedMessage id="add"/></button>
        </form>
    </div>;
};
 
export default AddCompanyModalComponent;
