/* istanbul ignore file */
import ListResults from './list-results';

export default class ListResultsImp<T> implements ListResults<T> {
	totalPages: number;
	results: T[];
	totalProperties?: number;

	constructor(totalPages: number = 1, totalProperties?: number, results: T[] = []) {
		this.totalPages = totalPages;
		this.results = results;
		this.totalProperties = totalProperties;
	}
}
