import { JsonObject, JsonProperty } from 'json2typescript';
import ImportLogResult from './import-logs-result';

@JsonObject ('ImportLogResultImp')
export default class ImportLogResultImp implements ImportLogResult {

    @JsonProperty ('created', [], true)
    created: [];

    @JsonProperty ('not_created', [], true)
    not_created: [];

    constructor (created: [] = [], not_created: [] = []) {
        this.created = created;
        this.not_created = not_created;
    }
}
