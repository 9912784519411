import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import {Controller, useForm} from 'react-hook-form';
import { validateCups } from 'services/validation.service';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { useDispatch, useSelector } from 'react-redux';
import MainState from 'store/model/main.state';
import PropertyImp from 'model/property.imp';
import { updatePropertyInformation } from '../../services/property-detail.service';
import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import { SET_CURRENT_PROPERTY_ACTION } from 'store/property/actions';
import DatePicker from 'react-datepicker';
import moment from 'moment';

interface PropertyInformationEditComponentProps {
    editInfoState: Function;
}

interface PropertyInformationEditComponentForm {
    code: string;
    energyCups: string;
    gasCups: string;
    cadastralReference: string;
    province: string;
    city: string;
    postalCode: string;
    managementDate?: any;
}

const PropertyInformationEditComponent: FunctionComponent<PropertyInformationEditComponentProps> = ({ editInfoState }): ReactElement => {
    /* istanbul ignore next */
    const { country } = useSelector((state: MainState) => state.auth.user);
    /* istanbul ignore next */
    const { currentProperty } = useSelector((state: MainState) => state.property);
    /* istanbul ignore next */
	const { language } = useSelector((state: MainState) => state.language);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, control } = useForm<PropertyInformationEditComponentForm>({
		defaultValues: {
			code: currentProperty.code,
			energyCups: currentProperty.energyCups,
			gasCups: currentProperty.gasCups,
			cadastralReference: currentProperty.cadastralReference,
            province: currentProperty.province,
            city: currentProperty.city,
            postalCode: currentProperty.postalCode,
            managementDate: currentProperty.managementDate
		}
    });
    
    const submit = (form: PropertyInformationEditComponentForm): void => {
        dispatch(SHOW_LOADING_ACTION);
        const propertyInformation = new PropertyImp(currentProperty.id, currentProperty.agencyId, currentProperty.address, form.code, 
            form.cadastralReference, form.energyCups, form.gasCups, currentProperty.supplies, currentProperty.documents, currentProperty.conversation,
            currentProperty.agencyName, form.province, form.city, form.postalCode, form.managementDate);

        updatePropertyInformation(propertyInformation)
            .then(() => {
                currentProperty.code = form.code;
                currentProperty.cadastralReference = form.cadastralReference;
                currentProperty.energyCups = form.energyCups;
                currentProperty.gasCups = form.gasCups;
                currentProperty.province = form.province;
                currentProperty.city = form.city;
                currentProperty.postalCode = form.postalCode;
                currentProperty.managementDate = form.managementDate;

                const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
                setCurrentPropertyAction.property = currentProperty;
                dispatch(setCurrentPropertyAction);
                editInfoState(false);
                showGrowlMessage(new GrowlMessageImp('property_updated', true), dispatch);
            })
            .catch((err) => {
                if (err.response.data.length !== 0) {
                    if (err.response.data.code && err.response.data.code[0] === 'duplicated_property_code') {
                        showGrowlMessage(new GrowlMessageImp('property_code_duplicated_error'), dispatch);
                    }
                    if (err.response.data.cadastral_reference && err.response.data.cadastral_reference[0] === 'duplicated_property_cadastral_reference') {
                        showGrowlMessage(new GrowlMessageImp('property_cadastral_reference_duplicated_error'), dispatch);
                    }
                }
                else {
                    showGrowlMessage(new GrowlMessageImp('property_update_error'), dispatch);
                }
            })
            .finally(() => dispatch(HIDE_LOADING_ACTION));
    };

    return <form className="card property-information__card" onSubmit={handleSubmit(submit)} data-testid="form">
        <div className={`form-group ${errors.code ? 'form-group--has-danger' : ''}`}>
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="referency_code"/></label>
                <input className="form-group__item" type="text" name="code" ref={register({required: 'field_required'})} data-testid="code"/>
            </div>
            { errors.code && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.code.message}/></small> }
        </div>
        <div className={`form-group ${errors.energyCups ? 'form-group--has-danger' : ''}`}>
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="energy_cups"/></label>
                <input className="form-group__item" type="text" name="energyCups" ref={register({
                    validate: cups => validateCups(country, cups)
                })}/>
            </div>
            { errors.energyCups && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.energyCups.message}/></small> }
        </div>
        <div className={`form-group ${errors.gasCups ? 'form-group--has-danger' : ''}`}>
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="gas_cups"/></label>
                <input className="form-group__item" type="text" name="gasCups" ref={register({
                    validate: cups => validateCups(country, cups)
                })}/>
            </div>
            { errors.gasCups && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.gasCups.message}/></small> }
        </div>
        <div className="form-group">
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="cadastral_reference"/></label>
                <input className="form-group__item" type="text" name="cadastralReference" ref={register()}/>
            </div>
        </div>
        <div className="form-group">
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="province"/></label>
                <input className="form-group__item" type="text" name="province" ref={register()}/>
            </div>
        </div>
        <div className="form-group">
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="population"/></label>
                <input className="form-group__item" type="text" name="city" ref={register()}/>
            </div>
        </div>
        <div className="form-group">
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="postal_code"/></label>
                <input className="form-group__item" type="text" name="postalCode" ref={register()}/>
            </div>
        </div>
        <div className="form-group">
            <div className="property-information__form">
                <label className="u-text--secondary"><FormattedMessage id="management_date"/></label>
                <Controller
					control={control}
					name="managementDate"
					render={({onChange, value}) => (
						<div className="form-group__item-management-date">
							<DatePicker
								placeholderText="DD/MM/YYYY"
								selected={value?.toDate()}
								onChange={/* istanbul ignore next */ (d: Date) => onChange(moment(d?.toISOString()))}
								dateFormat={language.calendarFormatDate}
								locale={language.value}
							/>
						</div>
					)}
				/>
            </div>
        </div>
        <div className="property-information__edit double">
            <button className="btn btn--secondary btn--sm btn--outline" type="button" onClick={() => editInfoState(false)} data-testid="cancel-button">
                <FormattedMessage id="cancel"/>
            </button>
            <button className="btn btn--success btn--sm" type="submit"><FormattedMessage id="save_changes"/></button>
        </div>
    </form>;
};
 
export default PropertyInformationEditComponent;
