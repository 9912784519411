import { JsonObject, JsonProperty } from 'json2typescript';
import AgencyAgent from './agency-agent';
import { NumberToStringDeserializer } from './deserializers/number-to-string.deserializer';

@JsonObject('AgencyAgentImp')
export default class AgencyAgentImp implements AgencyAgent {
    
    @JsonProperty('id', NumberToStringDeserializer, true)
    id: string;

    @JsonProperty('name', String, true)
    name: string;

    @JsonProperty('email', String, true)
    email: string;

    @JsonProperty('country', String, true)
    country: string;

	constructor(id: string = '', email: string = '', name: string = '', country: string = '') {
		this.id = id;
		this.email = email;
		this.name = name;
		this.country = country;
	} 
}
