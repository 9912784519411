import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {UserTypeEnum} from 'model/user-type.enum';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getAgencyDetail} from '../../services/agencies.service';
import Agency from 'model/agency';
import {SET_CURRENT_AGENCY_ACTION} from 'store/agency/actions';
// import icons from 'sprite/sprite.svg';
import './agency-detail.component.scss';
import EditAgencyModalComponent from 'components/modal/edit-agency/edit-agency-modal.component';
import AgencyUsersComponent from '../agency-users/agency-users.component';
import {openModal} from 'services/utils.service';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import {FormattedMessage} from 'react-intl';
import AgencyCompaniesComponent from '../agency-companies/agency-companies.component';
import AgencyAgentsComponent from '../agency-agents/agency-agents.component';

const AgencyDetailComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { currentAgency } = useSelector((state: MainState) => state.agency);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user.permissions.includes(UserTypeEnum.SELECTRA)) {
			dispatch(SHOW_LOADING_ACTION);

			getAgencyDetail(user.companyId)
				.then((agency: Agency) => {
					const setCurrentAgencyAction = {...SET_CURRENT_AGENCY_ACTION};
					setCurrentAgencyAction.agency = agency;
					dispatch(setCurrentAgencyAction);
				})
				.catch(() => showGrowlMessage(new GrowlMessageImp('agency_read_error'), dispatch))
				.finally(() => dispatch(HIDE_LOADING_ACTION));
		}
	}, [dispatch, user.companyId, user.permissions]);

	return <div className="agency-detail">
		{ currentAgency.id ? <>
			<h3 className="agency-detail__title">{ currentAgency.name }</h3>
			<div className="agency-detail__edit">
				<img className="agency-detail__edit-img" src={currentAgency.logo.url} alt={ currentAgency.name }/>
				{ user.permissions.includes(UserTypeEnum.SELECTRA) && <p className="btn btn--white btn--md" onClick={() => openModal(<EditAgencyModalComponent/>, dispatch)} data-testid="edit-button">
					<FormattedMessage id="edit_agency" />
				</p> }
			</div>
			<AgencyUsersComponent/>
			<AgencyAgentsComponent />
			<AgencyCompaniesComponent />
		</> : <h3 className="no-agencies">
			<FormattedMessage id="no_agency_selected"/>
		</h3> }
	</div>;
};

export default AgencyDetailComponent;
