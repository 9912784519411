import { showGrowlMessage } from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import ImportLog from 'model/import-logs';
import { UserTypeEnum } from 'model/user-type.enum';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_IMPORT_LOGS_ACTION } from 'store/import-log/actions';
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from 'store/loading/actions';
import MainState from 'store/model/main.state';
import { readImportLogs } from '../../services/import-logs.service';
import icons from 'sprite/sprite.svg';
import './import-log-detail.component.scss';
import Collapsible from 'react-collapsible';

const ImportLogDetailComponent: FunctionComponent = (): ReactElement => {

    const { user } = useSelector((state: MainState) => state.auth);
    const { importLogs } = useSelector((state: MainState) => state.importLog);
    const { formatDateTime } = useSelector((state: MainState) => state.language.language);
    const dispatch = useDispatch();

    useEffect(() => {

        if (user.permissions.includes(UserTypeEnum.SELECTRA)) {
            dispatch(SHOW_LOADING_ACTION);

            readImportLogs()
                .then((importLogs: ImportLog[]) => {
                    const setImportLogsAction = {...SET_IMPORT_LOGS_ACTION};
                    setImportLogsAction.importLogs = importLogs;
                    dispatch(setImportLogsAction);
                })
                .catch(() => {
                    showGrowlMessage(new GrowlMessageImp('import_logs_read_error'), dispatch);
                })
                .finally(() => dispatch(HIDE_LOADING_ACTION));
        }
    }, [dispatch, user.permissions]);

    const getTrigger = (importLog: any): ReactElement => {
        return <div>
            <div className="card-title">
                <div>
                    <p><strong><FormattedMessage id="import_id"/></strong>: {importLog.id}</p>
                </div>
                { importLog.result.not_created.length > 0 &&
                    <div>
                        <p><button className="btn btn--md btn--secondary"><FormattedMessage id="see_details"/></button></p>
                    </div>                    
                }
                <div>
                    <p><strong><FormattedMessage id="import_date"/></strong>: {importLog.createdAt.format(formatDateTime)}</p>
                </div>
            </div>
            <div className="container">
                { importLog.result.not_created.length > 0 ? <>
                        <div className="import-result-status">
                            <svg className="icon icon--24 icon--danger">
                                <use xlinkHref={`${icons}#icon-cross`} />
                            </svg>
                            <FormattedMessage id="import_result_status_error" />
                        </div>
                    </>
                : <div className="import-result-status">
                        <svg className="icon icon--24 icon--success">
                            <use xlinkHref={`${icons}#icon-check-mark`} />
                        </svg>
                        <FormattedMessage id="import_result_status_success" />
                    </div> }
            </div>
        </div>
    }

    const getImportLogDetails = (importLog: any) => {
        const logs = importLog.importLogsDetails.map((importLogDetail: any, index: any) => {
            return importLogDetail.error && <p key={index}>
                    <span className={`${!importLogDetail.success && 'import-log-error'}`}><strong>{getImportLogError(importLogDetail.error)}</strong>: </span> 
                    <span>{getImportLogParams(importLogDetail.objectType, importLogDetail.params)}</span>
                </p>
            })
        return logs;
    }

    const getImportLogParams = (type: any, params: any) => {
        if (params.length > 0) {
            params = JSON.parse(params);
            if (type === 'Property' && params.agency_id) {
                const address = params.address ? params.address : '';
                const code = params.code ? params.code : '';

                return <>
                    <FormattedMessage id="property_name" />: {address} / <FormattedMessage id="referency_code" />: {code} / <FormattedMessage id="agency_id" />: {params.agency_id}
                </>;
            }
        }
    };

    const getImportLogError = (error: any) => {
        error = JSON.parse(error);

        if (error.code && error.code[0] === 'duplicated_property_code') {
            return <FormattedMessage id="duplicated_property_code"/>;
        } 
        else if (error.address && error.address[0] === 'duplicated_property_name') {
            return <FormattedMessage id="duplicated_property_name"/>;
        }
        else if (error.address && error.address[0] === 'can\'t be blank') {
            return <FormattedMessage id="address_blank_error"/>;
        }
        else return <FormattedMessage id="default_error_import_message"/>;
    };

    return <div className="import-log-detail-component">
        { user.permissions.includes(UserTypeEnum.SELECTRA) ? <>
            <h3 className="import-log-detail__title"><FormattedMessage id="import_details"/></h3>

            { importLogs.length > 0 ? importLogs.map((importLog, index) => {

                if (importLog.result.created.length > 0 || importLog.result.not_created.length > 0) {
                    return <div className="card" key={index}>
                        <Collapsible trigger={getTrigger(importLog)}>
                            { getImportLogDetails(importLog) }
                        </Collapsible>
                    </div>
                } else return null;
                })
            : <h3 className="no-imports">
                <FormattedMessage id="no_import_logs"/>
            </h3> }
        </> : <h3 className="no-imports">
			<FormattedMessage id="forbidden"/>
		</h3> }
    </div>;
};

export default ImportLogDetailComponent;
