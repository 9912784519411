import React, {FunctionComponent, ReactElement} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import {mainReducer} from './store/main-reducer';
import {createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import MainComponent from 'components/main/main.component';
import app from 'firebase/compat/app';
import {FIREBASE_CONFIG} from 'config/firebase.config';
import {setAxiosHeaders} from './services/utils.service';

const App: FunctionComponent = (): ReactElement => {
  app.initializeApp(FIREBASE_CONFIG);
  setAxiosHeaders();

  return <Provider store={createStore(mainReducer, process.env.NODE_ENV === 'development' ? composeWithDevTools() : undefined)}>
    <MainComponent/>
  </Provider>;
};

export default App;
