import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './header.component.scss';
import icons from 'sprite/sprite.svg';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import {APP_CONSTANTS} from 'config/app.config';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {UserTypeEnum} from 'model/user-type.enum';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import AddPropertyModalComponent from '../modal/add-properties/add-property-modal.component';
import AddAgencyModalComponent from '../modal/add-agency/add-agency-modal.component';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {getNotifications, markNotificationRead} from './services/header.service';
import Notification from 'model/notification';
import NotificationsComponent from './components/notifications/notifications.component';
import OverlayComponent from 'components/shared/overlay/overlay.component';
import {showGrowlMessage} from '../shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import moment from 'moment';
import {openModal} from 'services/utils.service';
// import AddSelectraUserModalComponent from '../modal/add-selectra-user/add-selectra-user-modal.component';
import { FIREBASE_CONFIG_ADMIN } from 'config/firebase.config';

const HeaderComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const [ notifications, setNotifications ] = useState<Notification[]>([]);
	const [ showNotifications, setShowNotifications ] = useState<boolean>(false);
	const notReadNotifications = notifications.filter(n => !n.readAt).length;
	const appAdmin = app.initializeApp(FIREBASE_CONFIG_ADMIN, 'admin');

	useEffect(() => {
		getNotifications()
			.then(notifications => setNotifications(notifications.reverse()))
			.catch(() => showGrowlMessage(new GrowlMessageImp('notifications_load_error'), dispatch));
	}, [dispatch]);

	const showAddModal = (): void => {
		openModal((pathname === APP_CONSTANTS.ROUTES.PROPERTIES || pathname === APP_CONSTANTS.ROUTES.IMPORT_LOGS) ? <AddPropertyModalComponent/> : <AddAgencyModalComponent/>, dispatch);
	};

	const readNotification = (notification: Notification): void => {
		dispatch(SHOW_LOADING_ACTION);

		markNotificationRead(notification.id)
			.then(() => {
				const index = notifications.indexOf(notification);
				notification.readAt = moment();
				const newNotifications = [...notifications];
				newNotifications[index] = notification;
				setNotifications(newNotifications);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('notifications_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <div className="header-component">
		<div className="top-header">
			<div className="container">
				<img alt="selectra" src={`${process.env.PUBLIC_URL}/selectra.svg`}/>
				<div className="icons-container">
					<p className="icons-container__name-user">{user.name}</p>
					{ /*
					{ user.permissions.includes(UserTypeEnum.CREATE_SELECTRA_USER) && <div className="add-user" data-testid="show-create-user-modal-button" onClick={() => openModal(<AddSelectraUserModalComponent/>, dispatch)}>
						<svg className="icon icon--24 icon--white clickable">
							<use xlinkHref={`${icons}#icon-circle-user`}/>
						</svg>
						<svg className="icon clickable plus-icon">
							<use xlinkHref={`${icons}#icon-plus`}/>
						</svg>
					</div> }
					*/ }
					<div className="notifications">
						{ notReadNotifications > 0 && <span className="not-read-amount">{ notReadNotifications }</span> }
						<svg className="icon icon--24 icon--white clickable" onClick={() => setShowNotifications(!showNotifications)} data-testid="show-notifications-button">
							<use xlinkHref={`${icons}#icon-ringing-bell`}/>
						</svg>
						{ showNotifications && <OverlayComponent onHide={() => setShowNotifications(false)} style={{right: -50, top: 40}} data-testid="notifications-overlay">
                            <NotificationsComponent notifications={notifications} readNotification={readNotification}/>
						</OverlayComponent>}
					</div>
					{ user.permissions.includes(UserTypeEnum.SELECTRA) ? <>
						<div className="icons-container__country-user">
							<img src={process.env.PUBLIC_URL + '/country-flags/' + user.country.toLowerCase() + '.png'} alt={`${user.country}`} />
							<p className="icons-container__country-user-name"><FormattedMessage id={`${user.country}`}/></p>
						</div>
					</> : <></>
					}
					<svg className="icon icon--24 icon--white clickable" onClick={() => appAdmin.auth().signOut()} data-testid="log-out-button">
						<use xlinkHref={`${icons}#icon-log-out`}/>
					</svg>
				</div>
			</div>
		</div>
		<div className="router-header">
			<div className="container">
				<div className="links-container">
					<Link to={APP_CONSTANTS.ROUTES.PROPERTIES} className={`btn btn--md ${pathname === APP_CONSTANTS.ROUTES.PROPERTIES && 'active'}`}>
						<svg className="icon icon--24 icon--secondary">
							<use xlinkHref={`${icons}#icon-home-filled`}/>
						</svg>
						<FormattedMessage id="properties"/>
					</Link>
					<Link to={APP_CONSTANTS.ROUTES.AGENCIES} className={`btn btn--md ${pathname === APP_CONSTANTS.ROUTES.AGENCIES && 'active'}`}>
						{ user.permissions.includes(UserTypeEnum.SELECTRA) ? <>
							<svg className="icon icon--24 icon--secondary">
								<use xlinkHref={`${icons}#icon-people`}/>
							</svg>
							<FormattedMessage id="agencies"/>
						</> : <>
							<svg className="icon icon--24 icon--secondary">
								<use xlinkHref={`${icons}#icon-circle-user`}/>
							</svg>
							<FormattedMessage id="my_agency"/>
						</> }
					</Link>
					{	user.permissions.includes(UserTypeEnum.SELECTRA) &&
						<Link to={APP_CONSTANTS.ROUTES.IMPORT_LOGS} className={`btn btn--md ${pathname === APP_CONSTANTS.ROUTES.IMPORT_LOGS && 'active'}`}>
							<svg className="icon icon--24 icon--secondary">
								<use xlinkHref={`${icons}#icon-arrows-bottom-top`}/>
							</svg>
							<FormattedMessage id="import_logs"/><small className="beta">beta</small>
						</Link>						
					}
				</div>
				{ (user.permissions.includes(UserTypeEnum.SELECTRA)) && <button className="btn btn--md" onClick={showAddModal} data-testid="show-add-modal-button">
                    { pathname === APP_CONSTANTS.ROUTES.AGENCIES ? <FormattedMessage id="add_agency"/> : <FormattedMessage id="add_properties"/> }
                </button> }
			</div>
		</div>
	</div>;
};

export default HeaderComponent;
