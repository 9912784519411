/* istanbul ignore file */
import React from 'react';
import SelectOption from 'model/select-option';
import SelectOptionImp from 'model/select-option.imp';
import {FormattedMessage} from 'react-intl';
import {SupplyStatusEnum} from 'model/supply-status.enum';
import {UserTypeEnum} from 'model/user-type.enum';
import { SaleStateStatusEnum } from 'model/sale-state-status.enum';

interface AppConfig {
    ROUTES: {
        LOGIN: string;
        FORGOT_PASSWORD: string;
        RECOVER_PASSWORD: string;
        PROPERTIES: string;
        AGENCIES: string;
        IMPORT_LOGS: string;
    };
    ENDPOINTS: {
        USER_INFO: string;
        AGENCIES: string;
        AGENCY_BY_COMPANY_ID: string;
        AGENCY_USERS: string;
        AGENCY_IMPORT_PROPERTIES: string;
        AGENCY_EXPORT_PROPERTIES: string;
        AGENCY_COMPANIES: string;
        AGENCY_AGENTS: string;
        PROPERTIES: string;
        PROPERTY: string;
        DOCUMENTS: string;
        CONVERSATION: string;
        SUPPLY_INFO: string;
        SUPPLY_ACTIONS: string;
        SUPPLY_ACTIONS_CREATE: string;
        NOTIFICATIONS: string;
        INVOICES: string;
        IMPORT_LOGS: string;
    };
    CLOUD_FUNCTIONS_ENDPOINTS: {
        CREATE_USER: string;
        DELETE_USER: string;
        CHANGE_PASSWORD: string;
        CHANGE_EMAIL: string;
    };
    PERMISSIONS: {
        CREATE: string;
        DESTROY: string;
        READ: string;
        UPDATE: string;
    },
    SUPPLY_STATUSES: SelectOption[];
    USER_ROLES: SelectOption[];
    SALE_STATE: SelectOption[];
}

export const APP_CONSTANTS: AppConfig = {
    ROUTES: {
        LOGIN: '/login',
        FORGOT_PASSWORD: '/forgot-password',
        RECOVER_PASSWORD: '/recover-password',
        PROPERTIES: '/properties',
        AGENCIES: '/agencies',
        IMPORT_LOGS: '/import-logs'
    },
    ENDPOINTS: {
        USER_INFO: `${process.env.REACT_APP_API}/v1/users/by_email?email=`,
        AGENCIES: `${process.env.REACT_APP_API}/v1/agencies`,
        AGENCY_BY_COMPANY_ID:  `${process.env.REACT_APP_API}/v1/agencies/by_admin_company`,
        AGENCY_USERS: `${process.env.REACT_APP_API}/v1/agencies/agency_id/users`,
        AGENCY_IMPORT_PROPERTIES: `${process.env.REACT_APP_API}/v1/agencies/agency_id/imports`,
        AGENCY_EXPORT_PROPERTIES: `${process.env.REACT_APP_API}/v1/agencies/export`,
        AGENCY_COMPANIES: `${process.env.REACT_APP_API}/v1/agencies/agency_id/companies`,
        AGENCY_AGENTS: `${process.env.REACT_APP_API}/v1/agencies/agency_id/agents`,
        PROPERTIES: `${process.env.REACT_APP_API}/v1/agencies/properties`,
        PROPERTY: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties`,
        DOCUMENTS: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/documents`,
        CONVERSATION: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/comments`,
        SUPPLY_INFO: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/supplies`,
        SUPPLY_ACTIONS: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/supplies/supply_id/actions`,
        SUPPLY_ACTIONS_CREATE: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/supplies/actions/create_supply`,
        NOTIFICATIONS: `${process.env.REACT_APP_API}/v1/notifications`,
        INVOICES: `${process.env.REACT_APP_API}/v1/agencies/agency_id/properties/property_id/supplies/supply_id/invoices`,
        IMPORT_LOGS: `${process.env.REACT_APP_API}/v1/imports`
    },
    CLOUD_FUNCTIONS_ENDPOINTS: {
        CREATE_USER: `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}/create-user`,
        DELETE_USER: `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}/delete-user`,
        CHANGE_PASSWORD: `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}/change-password`,
        CHANGE_EMAIL: `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}/change-email`
    },
    PERMISSIONS: {
        CREATE: 'borgan_create_',
        DESTROY: 'borgan_destroy_',
        READ: 'borgan_read_',
        UPDATE: 'borgan_update_'
    },
    SUPPLY_STATUSES: [
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.NOT_APPLY} />, SupplyStatusEnum.NOT_APPLY),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.UNSUBSCRIBED} />, SupplyStatusEnum.UNSUBSCRIBED),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.PROCESSING_UNSUBSCRIPTION} />, SupplyStatusEnum.PROCESSING_UNSUBSCRIPTION),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.PROCESSING_SUBSCRIPTION} />, SupplyStatusEnum.PROCESSING_SUBSCRIPTION),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.PROCESSING_HOLDER_CHANGE} />, SupplyStatusEnum.PROCESSING_HOLDER_CHANGE),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.SUBSCRIBED} />, SupplyStatusEnum.SUBSCRIBED),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.CANCELLED} />, SupplyStatusEnum.CANCELLED),
        new SelectOptionImp(<FormattedMessage id={SupplyStatusEnum.INCIDENCE} />, SupplyStatusEnum.INCIDENCE)
    ],
    USER_ROLES: [
        new SelectOptionImp(<FormattedMessage id={UserTypeEnum.AGENCY} />, UserTypeEnum.AGENCY),
        new SelectOptionImp(<FormattedMessage id={UserTypeEnum.AGENCY_ADMIN} />, UserTypeEnum.AGENCY_ADMIN)
    ],
    SALE_STATE: [
        new SelectOptionImp(<FormattedMessage id={SaleStateStatusEnum.NO_SALE} />, SaleStateStatusEnum.NO_SALE),
        new SelectOptionImp(<FormattedMessage id={SaleStateStatusEnum.PENDING_SALE} />, SaleStateStatusEnum.PENDING_SALE),
        new SelectOptionImp(<FormattedMessage id={SaleStateStatusEnum.SOLD} />, SaleStateStatusEnum.SOLD)
    ]
};
