/* istanbul ignore file */
import {JsonObject, JsonProperty} from 'json2typescript';
import AgencyCompany from './agency-company';
import {NumberToStringDeserializer} from './deserializers/number-to-string.deserializer';

@JsonObject('AgencyCompanyImp')
export default class AgencyCompanyImp implements AgencyCompany {

	@JsonProperty('id', NumberToStringDeserializer, true)
	id: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('owner', String, true)
	owner: string;

	@JsonProperty('address', String, true)
	address: string;

	@JsonProperty('city', String, true)
	city: string;

	@JsonProperty('province', String, true)
	province: string;

	@JsonProperty('postal_code', String, true)
	postalCode: string;

	@JsonProperty('iban', String, true)
	iban: string;

	constructor(id: string = '', name: string = '', owner: string = '', address: string = '', city: string = '', province: string = '',
				postalCode: string = '', iban: string = '') {
		this.id = id;
		this.name = name;
		this.owner = owner;
		this.address = address;
		this.city = city;
		this.province = province;
		this.postalCode = postalCode;
		this.iban = iban;
	}
}
