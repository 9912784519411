import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import Property from 'model/property';
import PropertyImp from 'model/property.imp';

@JsonConverter
export class NotificationPropertyDeserializer implements JsonCustomConvert<Property> {
	deserialize = (attrs: string): Property => {
		try {
			const obj = JSON.parse(JSON.parse(attrs).object);
			return new PropertyImp(String(obj.property_id), String(obj.agency_id), obj.property_address);
		} catch (e) {
			return new PropertyImp();
		}
	};

	serialize = (property: Property): Property => property;
}
