import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './property-supply-edit.component.scss';
import Supply from 'model/supply';
import icons from 'sprite/sprite.svg';
import {useForm, Controller} from 'react-hook-form';
import {FORM_CONSTANTS} from 'config/form.config';
import {FormattedMessage, useIntl} from 'react-intl';
import {validateBankAccount, validateNationalId, validatePhone} from 'services/validation.service';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {getFieldIcon, updateSupplyHolder} from '../../services/property-detail.service';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {SET_CURRENT_PROPERTY_ACTION} from 'store/property/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import Select from 'react-select';
import { APP_CONSTANTS } from 'config/app.config';
import SelectOption from 'model/select-option';
import SelectOptionImp from 'model/select-option.imp';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import {getAllAgencyCompanies} from 'components/modal/open-request/services/open-request.service';
import AgencyCompany from 'model/agency-company';
import {SET_ALL_COMPANIES_ACTION} from 'store/agency/actions';
import AgencyCompanyImp from 'model/agency-company.imp';
import { openModal } from 'services/utils.service';
import { CopySupplyInformationModalComponent } from 'components/modal/copy-supply/copy-supply-information-modal.component';

export interface PropertySupplyEditComponentProps {
	save: Function;
	supply: Supply;
}

export interface PropertySupplyEditComponentFormData {
	owner: string;
	owner_phone: string;
	owner_identification: string;
	owner_email: string;
	owner_iban: string;
	company_name: string;
	company: SelectOption;
	contract_number: string;
	sale_state: SelectOption;
	atr_status: string;
	atr_date?: any;
}

const PropertySupplyEditComponent: FunctionComponent<PropertySupplyEditComponentProps> = ({ save, supply }): ReactElement => {
	const dispatch = useDispatch();
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	/* istanbul ignore next */
	const { language } = useSelector((state: MainState) => state.language);
	/* istanbul ignore next */
	const { currentProperty } = useSelector((state: MainState) => state.property);
	/* istanbul ignore next */
	const { allAgencyCompanies } = useSelector((state: MainState) => state.agency);
	const { formatMessage } = useIntl();
	const { register, handleSubmit, control, errors } = useForm<PropertySupplyEditComponentFormData>({
		defaultValues: {
			owner: supply.owner,
			owner_phone: supply.data.owner_phone,
			owner_identification: supply.data.owner_identification,
			owner_email: supply.data.owner_email,
			owner_iban: supply.data.owner_iban,
			company_name: supply.data.company_name,
			company: new SelectOptionImp(supply.company.name, supply.company.id),
			contract_number: supply.data.contract_number,
			sale_state: new SelectOptionImp(<FormattedMessage id={supply.saleState}/>, supply.saleState),
			atr_status: supply.atrStatus,
			atr_date: supply.atrDate
		}
	});
	const [ foreign, setForeign ] = useState<boolean>(false);

	useEffect(() => {
		dispatch(SHOW_LOADING_ACTION);

		getAllAgencyCompanies(currentProperty.agencyId)
			.then((companies: AgencyCompany[]) => {
				const setAllCompaniesAction = {...SET_ALL_COMPANIES_ACTION};
				setAllCompaniesAction.companies = companies;
				dispatch(setAllCompaniesAction);
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('companies_read_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	}, [dispatch, currentProperty.agencyId]);

	const submit = (form: PropertySupplyEditComponentFormData): void => {
		dispatch(SHOW_LOADING_ACTION);

		updateSupplyHolder(form, currentProperty, supply.id)
			.then(() => {
				const index = currentProperty.supplies.findIndex(s => s.id === supply.id);

				/* istanbul ignore else */
				if (index > -1) {
					currentProperty.supplies[index].owner = form.owner;
					currentProperty.supplies[index].saleState = form.sale_state.value;
					currentProperty.supplies[index].atrStatus = form.atr_status;
					currentProperty.supplies[index].atrDate = form.atr_date;
					currentProperty.supplies[index].company = new AgencyCompanyImp(form.company.value, form.company.label);
					currentProperty.supplies[index].data = form;

					const setCurrentPropertyAction = {...SET_CURRENT_PROPERTY_ACTION};
					setCurrentPropertyAction.property = currentProperty;
					dispatch(setCurrentPropertyAction);
				}

				save();
			})
			.catch(() => showGrowlMessage(new GrowlMessageImp('property_supply_edit_error'), dispatch))
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};

	return <form className="property-supply-edit-component container" onSubmit={handleSubmit(submit)} data-testid="form">
		<div className="row">
			<div className={`info-item ${currentProperty.supplies.length > 1 ? 'col-xs-6' : 'col-xs-12'}`}>
				<div className="foreign-holder" onClick={() => {setForeign(!foreign)}}>
					<strong><FormattedMessage id="foreign_holder"/></strong>
					<span className={`circle clickable ${foreign === true && 'active'}`}/>
				</div>
			</div>
			{ currentProperty.supplies.length > 1 &&
				<div className="info-item col-xs-6">
					<div className="copy-supply-information">
						<div className="btn btn--sm btn--secondary" onClick={() => openModal(<CopySupplyInformationModalComponent supply={supply} save={save}/>, dispatch)} data-testid="open-copy-supply-button">
							<FormattedMessage id="copy_supply_information" />
						</div>
					</div>
				</div>
			}
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="owner"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#icon-circle-user`}/>
					</svg>
				</Tooltip>
				<div className="form-group">
					<input className="form-group__item" type="text" name="owner" ref={register()} placeholder={formatMessage({ id: 'owner' })} />
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="atr_date"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('atr_date')}`}/>
					</svg>
				</Tooltip>
				<Controller
					control={control}
					name="atr_date"
					render={({onChange, value}) => (
						<div className="form-group">
							<DatePicker
								placeholderText="ATR"
								selected={value?.toDate()}
								onChange={/* istanbul ignore next */ (d: Date) => onChange(moment(d?.toISOString()))}
								dateFormat={language.calendarFormatDate}
								locale={language.value}
							/>
						</div>
					)}
				/>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="atr_status"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#icon-arrows-bottom-top`}/>
					</svg>
				</Tooltip>
				<div className="form-group">
					<input className="form-group__item" type="text" name="atr_status" ref={register()} placeholder={formatMessage({ id: 'atr_status' })} />
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="marketer"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#icon-briefcase`}/>
					</svg>
				</Tooltip>
				<div className="form-group">
					<input className="form-group__item" type="text" name="company_name" ref={register()} placeholder={formatMessage({ id: 'marketer' })} />
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="phone"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('owner_phone')}`}/>
					</svg>
				</Tooltip>
				<div className={`form-group ${errors.owner_phone ? 'form-group--has-danger' : ''}`}>
					<input className="form-group__item" type="text" name="owner_phone" placeholder={formatMessage({ id: 'phone' })} 
					ref={foreign ? register({validate: () => true}) : register({validate: phone => validatePhone(user.country, phone)})}
					/>
					{ errors.owner_phone && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner_phone.message}/></small> }
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="newHolderNationalId"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('owner_identification')}`}/>
					</svg>
				</Tooltip>
				<div className={`form-group ${errors.owner_identification ? 'form-group--has-danger' : ''}`}>
					<input className="form-group__item" type="text" name="owner_identification" placeholder={formatMessage({ id: 'national_id' })}
					ref={foreign ? register({validate: () => true}) : register({validate: nationalId => validateNationalId(user.country, nationalId)})}
					/>
					{ errors.owner_identification && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner_identification.message}/></small> }
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="email"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('owner_email')}`}/>
					</svg>
				</Tooltip>
				<div className={`form-group ${errors.owner_email ? 'form-group--has-danger' : ''}`}>
					<input className="form-group__item" type="text" name="owner_email" placeholder={formatMessage({ id: 'email' })} data-testid="email-input" ref={register({
						pattern: {
							value: FORM_CONSTANTS.REGEX.EMAIL,
							message: 'invalid_email'
						}})}
					/>
					{ errors.owner_email && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner_email.message}/></small> }
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="bank_account"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('owner_iban')}`}/>
					</svg>
				</Tooltip>
				<div className={`form-group ${errors.owner_iban ? 'form-group--has-danger' : ''}`}>
					<input className="form-group__item" type="text" name="owner_iban" placeholder={formatMessage({ id: 'bank_account' })}
					ref={foreign ? register({validate: () => true}) : register({validate: bankAccount => validateBankAccount(user.country, bankAccount)})}
					/>
					{ errors.owner_iban && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.owner_iban.message}/></small> }
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="contract_number"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('contract_number')}`}/>
					</svg>
				</Tooltip>
				<div className="form-group">
					<input className="form-group__item" type="text" name="contract_number" placeholder={formatMessage({ id: 'contract_number' })} ref={register()} />
				</div>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="company"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('company')}`}/>
					</svg>
				</Tooltip>
				<Controller
					control={control}
					name="company"
					render={({onChange, value}) => (
						<div className="form-group">
							<Select
								options={allAgencyCompanies.map(c => new SelectOptionImp(c.name, c.id))}
								classNamePrefix="select"
								isSearchable={false}
								placeholder={<FormattedMessage id="select"/>}
								value={value}
								onChange={e => onChange(e)}
								isMulti={false}
							/>
						</div>
					)}
				/>
			</div>
			<div className="info-item col-md-6">
				<Tooltip placement="bottom" trigger={['hover']} overlay={<span className="label-info"><FormattedMessage id="sale_state"/></span>}>
					<svg className="icon icon--16">
						<use xlinkHref={`${icons}#${getFieldIcon('sale_state')}`}/>
					</svg>
				</Tooltip>
				<Controller
					control={control}
					name="sale_state"
					render={({onChange, value}) => (
						<div className="form-group">
							<Select
								options={APP_CONSTANTS.SALE_STATE}
								classNamePrefix="select"
								isSearchable={false}
								placeholder={<FormattedMessage id="select"/>}
								value={value}
								onChange={e => onChange(e)}
								isMulti={false}
							/>
						</div>
					)}
				/>
			</div>
		</div>
		<button className="save btn btn--success btn--sm" type="submit">
			<FormattedMessage id="save_changes" />
		</button>
	</form>;
};

export default PropertySupplyEditComponent;
