import React, {FunctionComponent, ReactElement} from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import DragAndDropComponent from 'components/shared/drag-drop/drag-drop.component';
import { useSelector, useDispatch } from 'react-redux';
import MainState from 'store/model/main.state';
import { addAgency, selectCurrentAgency, setAgenciesList } from 'components/agencies/services/agencies.service';
import Agency from 'model/agency';
import { SHOW_LOADING_ACTION, HIDE_LOADING_ACTION } from 'store/loading/actions';
import { HIDE_MODAL_ACTION } from 'store/modal/actions';
import {showGrowlMessage} from 'components/shared/growl/services/growl.service';
import GrowlMessageImp from 'model/growl-message.imp';
import { createAgencyAddAgent } from 'components/agencies/components/agency-agents/services/agency-agents.service';

export interface AddAgencyFormData {
	name: string;
	file: any;
}

const AddAgencyModalComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { user } = useSelector((state: MainState) => state.auth);
	const { agencies } = useSelector((state: MainState) => state.agency);
	const { handleSubmit, errors, register, control } = useForm<AddAgencyFormData>();
	const dispatch = useDispatch();

	const addNewAgency = (data: AddAgencyFormData) => {
		dispatch(SHOW_LOADING_ACTION);

		addAgency(data)
			.then((newAgency: Agency) => {
				createAgencyAddAgent(newAgency.id, user.name, user.email)
					.then((res) => {
						newAgency.agents.push(res);
						agencies.unshift(newAgency);
						setAgenciesList(dispatch, agencies);
						selectCurrentAgency(dispatch, newAgency);
						showGrowlMessage(new GrowlMessageImp('agency_created', true), dispatch);
						dispatch(HIDE_MODAL_ACTION);					
					});
			})
			.catch((err) => {
				if (err.response.data.name && err.response.data.name[0] === 'duplicated_agency_name') {
					showGrowlMessage(new GrowlMessageImp('agency_name_duplicated_error'), dispatch);
				} else {
					showGrowlMessage(new GrowlMessageImp('agency_create_error'), dispatch);
				}
			})
			.finally(() => dispatch(HIDE_LOADING_ACTION));
	};
	
	return <div>
		<h3 className="title u-text--secondary"><FormattedMessage id="add_agency"/></h3>
		<form onSubmit={handleSubmit(addNewAgency)} data-testid="form">
			<div className="form-group">
				<label className="form-group__label"><FormattedMessage id="agency_name"/></label>
				<input type="text" name="name" className="form-group__item" ref={register({required: 'field_required'})} data-testid="agency-name-input"/>
				{ errors.name && <small className="form-group__msg form-group__msg--danger"><FormattedMessage id={errors.name.message}/></small> }
			</div>
			<DragAndDropComponent label="logo" fileType="image/*" control={control} errors={errors} />
			<button className="btn btn--block btn--lg btn--secondary" type="submit"><FormattedMessage id="add"/></button>
		</form>
	</div>;
};

export default AddAgencyModalComponent;
